import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Link,
  Route
} from 'react-router-dom'
import { getModeString } from '../../Utils'

export default class TrainingPlanTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      plans: [],
      isLoading: true,
      isAdmin: props.isAdmin,
      className: props.isAdmin ? 'AdminTrainingPlan' : 'TrainingPlan',
      urlBase: props.isAdmin ? '/adminplans' : '/trainingplans'
    }

    this.loadTrainingPlans = this.loadTrainingPlans.bind(this)

    this.loadTrainingPlans()
  }

  loadTrainingPlans = () => {
    console.log('loadTrainingPlans')

    const plan = new Parse.Object(this.state.className)
    const query = new Parse.Query(plan)
    query.limit(100)
    query.descending('createdAt')
    console.log(Parse.User.current().get('sessionToken'))
    query.find({ sessionToken: Parse.User.current().get('sessionToken') }).then((results) => {
      const plans = results
      const totalCount = plans.length
      if (!plans) {
        alert('No training plans found.')
        return
      }

      console.log('got results, count: ' + totalCount)

      const sanitizedPlans = plans.map(plan => {
        if (!plan) {
          return {
            title: '⚠️ ERROR: MISSING PLAN ⚠️'
          }
        }
        return {
          objectId: plan.id,
          title: plan.get('name'),
          duration: plan.get('trainingPlanWeeks') ? plan.get('trainingPlanWeeks').length : 0,
          planId: plan.id,
          mode: plan.get('mode')
        }
      })

      this.setState({
        plans: sanitizedPlans,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting plans: ' + error.message)
    })
  }

  handleAddPlanClicked = (event) => {
    event.preventDefault()
    const trainingPlan = new Parse.Object(this.state.className)
    trainingPlan.save().then(() => {
      this.props.history.push(this.state.urlBase + '/' + trainingPlan.id)
    })
  }

  renderTable = () => {
    return this.state.plans.map((plan, index) => {
      const { title, duration, planId, mode } = plan
      const planPath = this.state.urlBase + '/' + planId
      return (
        <tr key={index} className="data">
          <td><Link className="btn-link" to={planPath}>{title}</Link></td>
          <td>{duration}</td>
          <td>{getModeString(mode)}</td>
        </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
      <tr key={'0-header'} className="data">
        <th className="bg-primary" key={'0h'}>Name</th>
        <th className="bg-primary" key={'1h'}>Weeks</th>
        <th className="bg-primary" key={'2h'}>Mode</th>
      </tr>
    )
  }

  render = () => {
    console.log('render training plan table component')
    return (
        <div>
            <Route path={this.state.urlBase}>
                <div className="container">
                    <br/>
                    <div className="row">
                      <div className="col-md-8">
                        <h1 id="title">{ this.state.isAdmin ? 'Admin ' : ''}Training Plans</h1>
                      </div>
                      <div className="col-md-2">
                        <button type="button" className="btn btn-outline-secondary" onClick={this.handleAddPlanClicked}>Add Plan</button>
                      </div>
                      <div className="col-md-2">
                        <a href="/#/trainingplans/new">
                          <button type="button" className="btn btn-primary">🪄 Generate Plan</button>
                        </a>
                      </div>
                    </div>
                    <table id="trainingplans" className="data table-display">
                      <tbody>
                        {this.renderTableHeader()}
                        {this.renderTable()}
                      </tbody>
                    </table>
                    { this.state.isLoading &&
                      <div>Loading...</div>
                    }
                </div>
            </Route>
            <br/>
        </div>)
  }
}
