import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

const rcProjectId = '64664645'
const rcAPIKey = 'sk_yfPxfchWFakhudWdAhQLVRGKXocVD'

export default class WebTools extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      offerings: []
    }
    this.loadPackages()
  }

  loadPackages = async () => {
    let offeringsUrl = `https://api.revenuecat.com/v2/projects/${rcProjectId}/offerings?expand=items.package.product`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + rcAPIKey
    }
    let offerings = []
    do {
      const nextOfferingsResult = await fetch(offeringsUrl, { headers })
      const nextOfferingsJson = await nextOfferingsResult.json()
      offerings = offerings.concat(nextOfferingsJson.items)
      offeringsUrl = nextOfferingsJson.next_page
    } while (offeringsUrl)
    console.log(offerings)
    offerings.sort((a, b) => a.is_current ? -1 : b.is_current ? 1 : 0)
    this.setState({ offerings })
  }

  packageStoreString = (rcPackage) => {
    if (rcPackage.store_identifier.toLowerCase().includes('google') || rcPackage.store_identifier.toLowerCase().includes('android')) {
      return <a title={rcPackage.store_identifier} href={`https://play.google.com/console/u/0/developers/7348805877507839355/app/4975792486738508853/subscriptions/s/${rcPackage.store_identifier}`}>
        <span className="badge text-bg-success">Google</span>
      </a>
    }
    if (rcPackage.store_identifier.toLowerCase().includes('prod_')) {
      return <a title={rcPackage.store_identifier} href={`https://dashboard.stripe.com/products/${rcPackage.store_identifier}`}>
        <span className="badge text-bg-info">Stripe</span>
      </a>
    }
    if (rcPackage.store_identifier.toLowerCase().includes('apple')) {
      return <a title={rcPackage.store_identifier} href="https://appstoreconnect.apple.com/apps/994386450/distribution/subscriptions">
        <span className="badge text-bg-primary">Apple</span>
      </a>
    }
    return <span className="badge text-bg-secondary">{rcPackage.store_identifier}</span>
  }

  createPurchaseLink = (stripeProductId) => {
    console.log('Creating purchase link for', stripeProductId)
    this.setState({ stripeProductId }, () => {
      window.scrollTo(0, 0)
    })
  }

  copyToClipboard = async () => {
    try {
      const element = document.querySelector('.user-select-all')
      await navigator.clipboard.writeText(element.textContent)
      // Optional: Provide feedback or perform additional actions upon successful copy
    } catch (error) {
      console.error('Failed to copy to clipboard:', error)
      // Optional: Handle and display the error to the user
    }
  }

  buildUrl = () => {
    if (!this.state.stripeProductId) {
      return <span className="text-body-secondary">
        Find the Price ID on the Product Page in Stripe.
        It is recommended to always use products that have been configured in RevenueCat.
        You can find links to Products configured in RevenueCat below by clicking on <span className="badge text-bg-info">Stripe</span>.
      </span>
    }
    const url = new URL('https://app.myswimpro.com/')
    url.searchParams.append('result', 'checkout')
    url.searchParams.append('planId', this.state.stripeProductId)
    if (this.state.coupon) url.searchParams.append('coupon', this.state.coupon)
    if (this.state.utm_source) url.searchParams.append('utm_source', this.state.utm_source)
    if (this.state.utm_medium) url.searchParams.append('utm_medium', this.state.utm_medium)
    if (this.state.utm_campaign) url.searchParams.append('utm_campaign', this.state.utm_campaign)
    if (this.state.utm_content) url.searchParams.append('utm_content', this.state.utm_content)
    return <div>
      <div className="card mb-2">
        <div className="card-header">
          Purchase Link
        </div>
        <div className="card-body d-flex justify-content-between align-middle">
          <code className="user-select-all">{url.href}</code>
          <button className="btn btn-sm btn-outline-secondary" onClick={this.copyToClipboard}>Copy to Clipboard</button>
        </div>
      </div>
      <b>Expected Behavior:</b>
      <ul>
        <li>
          <b>User logged out:</b> Prompts for login or account creation, then takes to Stripe Checkout for the given Price ID.
        </li>
        <li>
          <b>User logged in:</b> Immediately takes to Stripe Checkout for the given Price ID.
        </li>
        <li>
          <b>User already subscribed:</b> Shows an error about already being subscribed.
        </li>
      </ul>
    </div>
  }

  render = () => {
    return (
      <div className="container">
        <h2 className="mt-5">
          Purchase Link Builder
        </h2>
        <div className="card">
          <div className="card-body">
            <form>
              <div className="row mb-3">
                <div className="col-sm-6">
                  <label htmlFor="exampleInputEmail1" className="form-label fw-bold">Stripe Price ID</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={'Starts with "price_"'} value={this.state.stripeProductId} onChange={e => this.setState({ stripeProductId: e.target.value })} />
                  <div id="emailHelp" className="form-text">This is the &quot;API ID&quot; that appears next to the Price on the Stripe Product Page.</div>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="exampleInputEmail1" className="form-label fw-bold">Coupon Code</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={this.state.coupon} onChange={e => this.setState({ coupon: e.target.value })} />
                  <div id="emailHelp" className="form-text">Optional</div>
                </div>
              </div>
              <div className="card text-bg-light">
                <div className="card-header">
                  Optional Tracking
                </div>
                <div className="row card-body">
                  <div className="col-sm-3">
                    <label htmlFor="utm_source" className="form-label fw-bold">utm_source</label>
                    <input type="text" className="form-control" id="utm_source" aria-describedby="emailHelp" value={this.state.utm_source} onChange={e => this.setState({ utm_source: e.target.value })} />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="utm_medium" className="form-label fw-bold">utm_medium</label>
                    <input type="text" className="form-control" id="utm_medium" aria-describedby="emailHelp" value={this.state.utm_medium} onChange={e => this.setState({ utm_medium: e.target.value })} />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="utm_medium" className="form-label fw-bold">utm_campaign</label>
                    <input type="text" className="form-control" id="utm_medium" aria-describedby="emailHelp" value={this.state.utm_campaign} onChange={e => this.setState({ utm_campaign: e.target.value })} />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="exampleInputEmail2" className="form-label fw-bold">utm_content</label>
                    <input type="text" className="form-control" id="utm_content" aria-describedby="emailHelp" value={this.state.utm_content} onChange={e => this.setState({ utm_content: e.target.value })} />
                  </div>
                </div>
              </div>
            </form>
            <div className="card text-bg-light mt-4">
              <div className="card-body">
                {this.buildUrl()}
              </div>
            </div>
          </div>
        </div>
        <h2 className="mt-5">
          Available Offerings
          <small className="text-body-secondary ms-1"><a href="https://app.revenuecat.com/projects/64664645/offerings/">↗</a></small>
        </h2>
        <div className="row">
          {this.state.offerings.map(offering => (
            this.renderOffering(offering)
          ))}
        </div>
      </div>
    )
  }

  lookupKeyTranslated = (lookupKey) => {
    if (lookupKey === '$rc_monthly') return 'Monthly'
    if (lookupKey === '$rc_annual') return 'Annual'
    if (lookupKey === '$rc_lifetime') return 'Lifetime'
    if (lookupKey === '$rc_three_month') return '3-monthly'
    return lookupKey
  }

  renderOffering = (offering) => {
    return <div key={offering.id} className="col-md-4">
      <div className="card my-3">
        <div className="card-header d-flex justify-content-between">
          <span><b>{offering.lookup_key}</b> {offering.is_current ? <span className="badge text-bg-secondary">Current</span> : <span />}</span>
          <a href={`https://app.revenuecat.com/projects/64664645/offerings/${offering.id}`}>↗</a>
        </div>
        <div className="card-body">
          <p className="card-text text-body-secondary">{offering.display_name}</p>
        </div>
        <ul className="list-group list-group-flush">
          {offering?.packages?.items?.map(rcPackage => (
            <li key={rcPackage.id} className="list-group-item">
              {this.renderPackage(rcPackage)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  }

  renderPackage = (rcPackage) => {
    console.log(rcPackage.products.items)
    rcPackage.products.items.sort((a, b) => a.product.store_identifier.localeCompare(b.product.store_identifier))
    console.log(rcPackage.products.items)

    return <div key={rcPackage.id} className="d-flex justify-content-between">
      <div>
        <span title={rcPackage.lookup_key}>{this.lookupKeyTranslated(rcPackage.lookup_key)}</span>
        <a href={`https://app.revenuecat.com/projects/64664645/offerings/${rcPackage.id}`} className="ms-1">↗</a>
      </div>
      <ul className="list-inline">
        {rcPackage.products.items?.map(item => (
          <li key={item.id} className="list-inline-item">
            {this.packageStoreString(item.product)}
          </li>
        ))}
      </ul>
    </div>
  }
}
