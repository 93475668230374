import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route,
  Link
} from 'react-router-dom'

export default class FeaturedVideoTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      featuredVideos: [],
      isLoading: true,
      isAdding: false
    }

    this.loadFeaturedVideos = this.loadFeaturedVideos.bind(this)
    this.loadFeaturedVideos()
  }

  loadFeaturedVideos = () => {
    console.log('loadFeaturedVideos')

    const featuredVideo = new Parse.Object('FeaturedVideo_v3')
    const query = new Parse.Query(featuredVideo)
    query.include(['video'])
    query.find().then((results) => {
      const featuredVideo = results
      console.log('got result for featured video')
      const totalCount = featuredVideo.count
      if (!featuredVideo) {
        alert('No featured videos found.')
        return
      }

      const sanitizedFeaturedVideo = featuredVideo.map(featuredVideoElement => {
        return {
          objectId: featuredVideoElement.id,
          video: featuredVideoElement.get('video'),
          updatedAt: featuredVideoElement.get('updatedAt')
        }
      })

      this.setState({
        featuredVideos: sanitizedFeaturedVideo,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting featured videos: ' + error.message)
    })
  }

  handleAddFeaturedVideoClicked = (featuredVideoId, index) => {
    const video = this.state.featuredVideos[index]
    console.log(video.video)

    this.setState({
      isAdding: true,
      addingToId: featuredVideoId,
      tempVideoURL: video.video.get('url')
    })
  }

  renderTable = () => {
    return this.state.featuredVideos.map((featuredVideo, index) => {
      const { objectId, video, updatedAt } = featuredVideo
      console.log(video)
      const videoURL = video.get('url')
      return (
               <tr key={index} className="data">
                    <td>{index + 1}</td>
                    <td><b>{video.get('title')}</b></td>
                    <td><a href={videoURL} target="_blank" rel="noreferrer noopener">{videoURL}</a></td>
                    <td><b>{video.get('tags')}</b></td>
                    <td>{updatedAt.toLocaleDateString('en-US')}</td>
                    <td><button className="btn btn-primary" onClick={() => this.handleAddFeaturedVideoClicked(objectId, index)}>Change</button></td>
               </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>{''}</th>
                <th className="bg-primary" key={'1h'}>{'Title'}</th>
                <th className="bg-primary" key={'3h'}>{'URL'}</th>
                <th className="bg-primary" key={'4h'}>{'Tags'}</th>
                <th className="bg-primary" key={'5h'}>{'Updated At'}</th>
                <th className="bg-primary" key={'6h'}>{'Change'}</th>
            </tr>
    )
  }

  handleVideoURLChange = (event) => {
    this.setState({
      tempVideoURL: event.target.value
    })
  }

  handleSaveButtonClick = (event) => {
    event.preventDefault()
    const videoURL = this.state.tempVideoURL.trim()
    if (!videoURL) {
      alert("Couldn't save, missing video URL")
      return
    }

    const featuredVideoId = this.state.addingToId
    if (!featuredVideoId) {
      alert('Error occurred, need featured video ID to save this new video to')
      return
    }

    this.saveVideoV3(videoURL, featuredVideoId)
  }

  saveVideoV3 = (videoURL, featuredVideoId) => {
    console.log('saveVideo called with url: ' + videoURL + ' featuredVideoID: ' + featuredVideoId)

    let videoToSave
    const videoQuery = new Parse.Query(new Parse.Object('Video_v3'))
    videoQuery.equalTo('url', videoURL)
    videoQuery.first().then((video_v3) => {
      videoToSave = video_v3
      console.log('successfully found video_v3 object for given URL, video_v3 ID: ' + video_v3.id)
      const query = new Parse.Query(new Parse.Object('FeaturedVideo_v3'))
      console.log('getting featured video with id: ' + featuredVideoId)
      return query.get(featuredVideoId)
    }).then((featuredVideo) => {
      if (!featuredVideo) {
        console.log('error occurred, fetch by ID should return a featured video')
        return
      }

      console.log('got featured video, about to set: ' + featuredVideo)
      featuredVideo.set('video', videoToSave)
      return featuredVideo.save()
    }).then(() => {
      console.log('saved featured video, reloading...')
      this.setState({
        featuredVideos: [],
        isAdding: false,
        isLoading: true
      }, this.loadFeaturedVideos)
    })
  }

  handleCancelButtonClick = (event) => {
    this.setState({
      isAdding: false,
      featuredVideoId: undefined
    })
  }

  renderAddVideoView = () => {
    return (
            <form id="addvideo-form" className="form">
            <div className="form-row">
                <Link className="btn btn-link" to="/videos" target="_blank">Find URL in Video Library</Link>
            </div>
            <div className="form-row">
                <div className="col">
                    <div className="form-group">
                        <input type="text" style={{ width: '300px' }} className="form-control" id="content_url" placeholder="url" value={this.state.tempVideoURL} onChange={this.handleVideoURLChange}/>
                    </div>
                </div>
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={this.handleSaveButtonClick}>Save</button>
                    {' '}
                    <button type="button" className="btn btn-secondary" onClick={this.handleCancelButtonClick}>Cancel</button>
                </div>
            </div>
            </form>
    )
  }

  render = () => {
    console.log('render featured video table component')
    return (
        <div>
            <Route path="/featuredvideos">
                <div className="container">
                    <br/>
                    <div>
                        <h1 id="title">Featured Videos</h1>
                    </div>
                    {
                        this.state.isAdding &&
                        this.renderAddVideoView()
                    }
                    <table id="featuredvideos" className="data">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>
                    { this.state.isLoading &&
                        <div>Loading...</div>
                    }
                </div>
            </Route>
        </div>)
  }
}
