import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import Workout from '../Workout/WorkoutComponent'
import WODHeader from './WODHeaderComponent'
import { copyWorkout } from '../../Utils'

export default class WOD extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      id: this.props.match.params.id,
      editingInfo: false,
      setGroupClassName: props.isAdmin ? 'AdminSetGroup' : 'SetGroup_v3',
      className: props.isAdmin ? 'AdminWorkout' : 'Workout_v3'
    }

    this.loadWOD = this.loadWOD.bind(this)

    this.loadWOD()
  }

  loadWOD = () => {
    const wodObjectType = new Parse.Object('WorkoutOfTheDay')
    const query = new Parse.Query(wodObjectType)
    query.include(['workout.setGroups.sets', 'image', 'adminWorkout.setGroups.sets'])
    console.log('load wod with id: ' + this.state.id)
    query.get(this.state.id).then((wod) => {
      this.setState({
        wod: wod,
        isLoading: false,
        totalDistance: this.recalculateTotalDistance(),
        totalTime: this.recalculateTotalTime()
      })
    }, (error) => {
      console.log(error)
      window.alert('Error fetching workout')
    })
  }

  updatePoolCourse = (event) => {
    this.state.workout.set('poolCourse', parseInt(event.target.value))
    this.setState({
      workout: this.state.workout
    })
  }

  updateSkillLevel = (event) => {
    this.state.workout.set('skillLevel', parseInt(event.target.value))
    this.setState({
      workout: this.state.workout
    })
  }

  updateWorkoutType = (event) => {
    this.state.workout.set('workoutType', parseInt(event.target.value))
    this.setState({
      workout: this.state.workout
    })
  }

  deleteWorkout = (event) => {
    alert('Functionality coming soon!')
  }

  saveWorkout = (event) => {
    console.log('save workout')
    event.preventDefault()
    const wodToSave = this.state.newWod ? this.state.newWod : this.state.wod
    const workoutToSave = wodToSave.get('adminWorkout') || wodToSave.get('workout')
    const setGroupsToSave = workoutToSave.get('setGroups')
    // first check if any have no sets, would be invalid to save
    let emptySetPresent = false
    setGroupsToSave.forEach((setGroup) => {
      if (setGroup.get('sets').length === 0) {
        emptySetPresent = true
      }
    })

    if (emptySetPresent) {
      alert('Please add sets to empty set groups or remove the set groups.')
      return
    }

    const setGroupSavePromises = setGroupsToSave.map((setGroup) => { return setGroup.save() })
    Promise.all(setGroupSavePromises).then(() => {
      console.log('set groups finished saving, saving workout...')

      workoutToSave.save().then((success, error) => {
        console.log('saved workout with success:' + success + ' and error:' + error)
        if (success) {
          const fieldName = workoutToSave.className === 'AdminWorkout' ? 'adminWorkout' : 'workout'
          this.state.wod.set(fieldName, workoutToSave)
          this.state.wod.save().then((success, error) => {
            console.log('saved workout: ' + success + ' and error: ' + error)
            this.setState({
              isEditing: false,
              newWod: null,
              totalDistance: this.recalculateTotalDistance(),
              totalTime: this.recalculateTotalTime()
            })
          })
        } else {
          alert('An error occurred, please try again')
        }
      })
    })
  }

  duplicateWorkout = (event) => {
    alert('Functionality coming soon!')
  }

  updateTotals = () => {
    console.log('updateTotals: ' + this.recalculateTotalTime())

    this.setState({
      totalDistance: this.recalculateTotalDistance(),
      totalTime: this.recalculateTotalTime()
    })
  }

  setGroupUpdate = (setGroup, index) => {
    const wod = this.state.newWod ? this.state.newWod : this.state.wod
    const wodClone = wod.clone()
    const fieldName = wodClone.get('adminWorkout') ? 'adminWorkout' : 'workout'
    const workout = wodClone.get(fieldName)
    const workoutClone = workout.clone()
    const setGroups = workoutClone.get('setGroups')
    const setGroupsClone = setGroups.map((setGroup) => { return setGroup.clone() })
    setGroupsClone[index] = setGroup
    workoutClone.set('setGroups', setGroupsClone)
    wodClone.set(fieldName, workoutClone)
    this.setState({
      newWod: wodClone
    }, this.updateTotals)
  }

  setGroupDelete = (index) => {
    console.log('setgroupdelete')
    const wod = this.state.newWod ? this.state.newWod : this.state.wod
    const wodClone = wod.clone()
    const fieldName = wodClone.get('adminWorkout') ? 'adminWorkout' : 'workout'
    const workout = wodClone.get(fieldName)
    const setGroups = workout.get('setGroups')
    setGroups.splice(index, 1)
    workout.set('setGroups', setGroups)
    wodClone.set(fieldName, workout)
    this.setState({
      newWod: wodClone,
      totalDistance: this.recalculateTotalDistance(),
      totalTime: this.recalculateTotalTime()
    })
  }

  addSetGroupClicked = (event) => {
    const wod = this.state.newWod ? this.state.newWod : this.state.wod
    const wodClone = wod.clone()
    const fieldName = wodClone.get('adminWorkout') ? 'adminWorkout' : 'workout'
    const workout = wodClone.get(fieldName)
    const workoutClone = workout.clone()
    const setGroups = workoutClone.get('setGroups')
    let setGroupsClone
    if (setGroups) {
      setGroupsClone = setGroups.map((setGroup) => { return setGroup.clone() })
    } else {
      setGroupsClone = []
    }
    const newSetGroup = new Parse.Object(this.state.setGroupClassName)
    newSetGroup.set('sets', [])
    setGroupsClone.push(newSetGroup)
    workoutClone.set('setGroups', setGroupsClone)
    wodClone.set(fieldName, workoutClone)
    this.setState({
      newWod: wodClone,
      totalDistance: this.recalculateTotalDistance(),
      totalTime: this.recalculateTotalTime()
    })
  }

  recalculateTotalTime = () => {
    let total = 0
    const wod = this.state.newWod ? this.state.newWod : this.state.wod
    if (!wod) {
      return total
    }
    const workout = wod.get('adminWorkout') || wod.get('workout')
    if (!workout) {
      return total
    }

    if (workout.get('setGroups') === undefined || workout.get('setGroups').length === 0) {
      return total
    }

    for (let i = 0; i < workout.get('setGroups').length; i++) {
      const sg = workout.get('setGroups')[i]
      let multiplier = sg.get('reps')
      if (multiplier < 1) {
        multiplier = 1
      }
      for (let j = 0; j < sg.get('sets').length; j++) {
        const set = sg.get('sets')[j]
        let setTotal = 0
        if (set.get('interval') > 0) {
          setTotal += set.get('interval') * set.get('reps')
        } else {
          setTotal += set.get('distance') * set.get('reps')
        }
        total += multiplier * setTotal
      }
      total += 60
    }
    return total
  };

  recalculateTotalDistance = () => {
    let total = 0
    const wod = this.state.newWod ? this.state.newWod : this.state.wod
    if (!wod) {
      return total
    }
    const workout = wod.get('adminWorkout') || wod.get('workout')
    if (!workout) {
      return total
    }

    if (workout.get('setGroups') === undefined || workout.get('setGroups').length === 0) {
      return total
    }

    for (let i = 0; i < workout.get('setGroups').length; i++) {
      const sg = workout.get('setGroups')[i]
      let multiplier = sg.get('reps') || 1
      if (multiplier < 1) {
        multiplier = 1
      }
      for (let j = 0; j < sg.get('sets').length; j++) {
        const set = sg.get('sets')[j]
        total += multiplier * set.get('distance') * set.get('reps')
      }
    }
    return total
  };

  onDuplicateWorkout = async () => {
    const newWOD = new Parse.Object('WorkoutOfTheDay')
    const className = 'AdminWorkout'
    const newWorkout = new Parse.Object(className)
    newWOD.set('title', this.state.wod.get('title'))
    newWOD.set('workoutDate', this.state.wod.get('workoutDate'))
    newWOD.set('image', this.state.wod.get('image'))
    const fieldName = newWorkout.className === 'AdminWorkout' ? 'adminWorkout' : 'workout'
    newWOD.set(fieldName, newWorkout)
    const promises = [newWorkout.save(), newWOD.save()]
    await Promise.all(promises)
    const sourceWorkout = this.state.wod.get('adminWorkout') || this.state.wod.get('workout')
    await copyWorkout(sourceWorkout.id, newWorkout.id, sourceWorkout.className)

    let url = window.location.href.substring(0, window.location.href.lastIndexOf('/'))
    url = url + '/' + newWOD.id
    const win = window.open(url)
    if (win) {
      win.focus()
    } else {
      alert('Workout copied. Check your popup blocker')
    }
  }

  resetWorkout = () => {
    this.setState({
      newWod: null,
      totalDistance: 0,
      totalTime: 0
    })
  }

  render = () => {
    if (this.state.wod === undefined) {
      return (<div>Loading...</div>)
    }
    const wod = this.state.newWod ? this.state.newWod : this.state.wod
    const workout = wod.get('adminWorkout') || wod.get('workout')
    console.log('wodcomponent render newWod is: ' + this.state.newWod)
    console.log(workout)
    const setGroups = workout.setGroups === undefined ? workout.get('setGroups') : workout.setGroups
    console.log(setGroups)
    console.log('totals are:')
    console.log('distance: ' + this.state.totalDistance + ' time: ' + this.state.totalTime)
    console.log(workout)
    return (
      <div className="container">
        <br/>
        <WODHeader wod={wod} isEditing={this.state.editingInfo} />
        <Workout id={workout.id} workoutOfTheDayId={wod.id} onDuplicateWorkout={this.onDuplicateWorkout} isAdmin={workout.className === 'AdminWorkout'} />
        { this.state.isLoading &&
            <div>Loading...</div>
        }
      </div>
    )
  }
}
