import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import TrainingPlanHeader from './TrainingPlanHeaderComponent'
import TrainingPlanWeek from './TrainingPlanWeekComponent'
import { Button, Modal, Form } from 'react-bootstrap'

export default class TrainingPlan extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      featuredPlan: null,
      nextPlan: null,
      id: this.props.match.params.id,
      isEditing: false,
      isAdmin: props.isAdmin,
      className: props.isAdmin ? 'AdminTrainingPlan' : 'TrainingPlan',
      weekClassName: props.isAdmin
        ? 'AdminTrainingPlanWeek'
        : 'TrainingPlanWeek',
      entryClassName: props.isAdmin
        ? 'AdminTrainingPlanEntry'
        : 'TrainingPlanEntry',
      generateAvgWorkoutLength: 1500,
      generateGoalWorkoutsPerWeek: 3
    }

    this.loadTrainingPlan = this.loadTrainingPlan.bind(this)

    if (this.state.id === 'new') {
      console.log('Creating new plan')
      this.state.isModalOpen = true
    } else {
      this.loadTrainingPlan()
    }
  }

  loadTrainingPlan = async () => {
    const planObjectType = new Parse.Object(this.state.className)
    const planQuery = new Parse.Query(planObjectType)
    planQuery.include('trainingPlanWeeks.entries.lcmWorkout')
    planQuery.include('trainingPlanWeeks.entries.scyWorkout')
    planQuery.equalTo('objectId', this.state.id)
    console.log('load training plan with id: ' + this.state.id)

    const plans = await planQuery.find()

    if (plans.length === 0) {
      // error, show alert
      window.alert('Error fetching plan, does this plan exist?')
      return
    }

    const plan = plans[0]
    let featuredPlan

    if (plan.get('featuredTrainingPlanId')) {
      console.log('Loading fplan...')
      const fplanObjectType = new Parse.Object('FeaturedTrainingPlan')
      const fplanQuery = new Parse.Query(fplanObjectType)
      try {
        featuredPlan = await fplanQuery.get(plan.get('featuredTrainingPlanId'))
      } catch (error) {
        console.warn('Error loading featured plan: ' + error.message)
      }
    }

    let fetchedNextLevel
    if (plan.get('nextPlan')) {
      const planAdminObj = new Parse.Object('AdminTrainingPlan')
      const query = new Parse.Query(planAdminObj)

      query.equalTo('objectId', plan.get('nextPlan').id)

      query.first().then(
        (result) => {
          fetchedNextLevel = result
          console.log('fetchedNextLevel', fetchedNextLevel)
          this.setState({
            nextPlan: fetchedNextLevel
          })
        },
        (error) => {
          console.log(error)
          window.alert('Error fetching effortVariation')
        }
      )
    }

    this.setState({
      plan: plan,
      featuredPlan: featuredPlan,
      isLoading: false
    })
  }

  addWeekClicked = (event) => {
    const plan = this.state.plan

    const newWeek = new Parse.Object(this.state.weekClassName)
    newWeek.set('entries', [])
    let weeks = plan.get('trainingPlanWeeks')
    if (!weeks) {
      weeks = []
    }
    weeks.push(newWeek)
    plan.set('trainingPlanWeeks', weeks)
    console.log(
      'about to save new plan with added week, new week count: ' +
        plan.get('trainingPlanWeeks').length
    )
    newWeek
      .save()
      .then(() => {
        return plan.save()
      })
      .then(() => {
        this.setState({
          plan: plan
        })
      })
  }

  removeWeekClicked = (weekIndex) => {
    const ok = confirm(
      'Are you sure you want to delete week ' + this.state.weekIndex + 1 + '?'
    )
    if (!ok) return

    const plan = this.state.plan

    const weeks = plan.get('trainingPlanWeeks')
    if (!weeks || weeks.length < weekIndex) {
      console.log('ERROR: Attempt to remove week OOB')
      return
    }
    weeks.splice(weekIndex, 1)
    plan.set('trainingPlanWeeks', weeks)
    console.log(
      'about to save new plan with removed week, new week count: ' +
        plan.get('trainingPlanWeeks').length
    )
    plan.save().then(() => {
      this.setState({
        plan: plan
      })
    })
  }

  addWorkoutClicked = async (weekIndex, entryIndex) => {
    const plan = this.state.plan

    console.log(' and weekindex: ' + weekIndex)
    const week = plan.get('trainingPlanWeeks')[weekIndex]
    const newEntry = new Parse.Object(this.state.entryClassName)
    await newEntry.save()

    const entries = week.get('entries')
    if (entryIndex < entries.length) {
      entries.splice(entryIndex, 0, newEntry)
    } else {
      entries.push(newEntry)
    }

    week.set('entries', entries)
    const weeks = plan.get('trainingPlanWeeks')
    weeks[weekIndex] = week
    plan.set('trainingPlanWeeks', weeks)
    await week.save()
    await plan.save()
    this.setState({
      plan: plan
    })
    window.location.reload()
  }

  onCommentChange = (weekIndex, entryIndex, comment) => {
    console.log(
      'onCommentChange inputs: ' +
        weekIndex +
        ' entry: ' +
        entryIndex +
        ' comment: ' +
        comment
    )
    const plan = this.state.plan

    const weeks = plan.get('trainingPlanWeeks')
    const week = weeks[weekIndex]
    const entries = week.get('entries')
    const entry = entries[entryIndex]
    entry.set('comments', comment)
    const scyWorkout = entry.get('scyWorkout')
    const lcmWorkout = entry.get('lcmWorkout')
    scyWorkout?.set('comments', comment)
    lcmWorkout?.set('comments', comment)
    entries[entryIndex] = entry
    week.set('entries', entries)
    weeks[weekIndex] = week
    plan.set('trainingPlanWeeks', weeks)

    Promise.all([scyWorkout?.save(), lcmWorkout?.save()])
      .then(() => {
        return entry.save()
      })
      .then(() => {
        return week.save()
      })
      .then(() => {
        return plan.save()
      })
      .then(() => {
        this.setState({
          plan: plan
        })
      })
  }

  removeWorkout = async (weekIndex, entryIndex) => {
    console.log('removeWorkout inputs: ' + weekIndex + ' entry: ' + entryIndex)

    const ok = confirm(
      'Are you sure you want to delete Week ' +
        (weekIndex + 1) +
        ' Entry ' +
        (entryIndex + 1) +
        '?'
    )
    if (!ok) return
    const plan = this.state.plan

    const weeks = plan.get('trainingPlanWeeks')
    const week = weeks[weekIndex]
    const entries = week.get('entries')
    entries.splice(entryIndex, 1)
    week.set('entries', entries)
    weeks[weekIndex] = week
    plan.set('trainingPlanWeeks', weeks)

    await week.save()
    await plan.save()
    console.log('Entry deleted.')
    this.setState({
      plan: plan
    })
    window.location.reload()
  }

  handleHide = () => {
    this.setState({ isModalOpen: false })
  }

  handleSubmit = async () => {
    this.setState({ isGeneratingPlan: true })
    const result = await Parse.Cloud.run('generatePlan', {
      endDate: this.state.generateGoalDate,
      goal: this.state.generateGoalText,
      workoutsPerWeek: parseInt(this.state.generateGoalWorkoutsPerWeek),
      avgWorkoutLength: parseInt(this.state.generateAvgWorkoutLength)
    })
    window.location.href = '/#/trainingplans/' + result.id
    window.location.reload()
  }

  render = () => {
    const handleDateChange = (e) => {
      this.setState({ generateGoalDate: e.target.value })
    }
    const handleTextChange = (e) => {
      this.setState({ generateGoalText: e.target.value })
    }
    const handlePerWeekChange = (e) => {
      this.setState({ generateGoalWorkoutsPerWeek: e.target.value })
    }
    const handleWorkoutLengthChange = (e) => {
      this.setState({ generateAvgWorkoutLength: e.target.value })
    }

    if (this.state.plan === undefined) {
      return <div>
        Loading...

        <Modal show={this.state.isModalOpen} onHide={this.handleHide}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="date">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" value={this.state.generateGoalDate} onChange={handleDateChange} />
              </Form.Group>
              <br/>
              <Form.Group controlId="text">
                <Form.Label>Goal/Prompt</Form.Label>
                <Form.Control type="text" value={this.state.generateGoalText} onChange={handleTextChange} />
              </Form.Group>
              <br/>
              <Form.Group controlId="numWorkouts">
                <Form.Label>Workouts per week</Form.Label>
                <Form.Control type="number" value={this.state.generateGoalWorkoutsPerWeek} onChange={handlePerWeekChange} />
              </Form.Group>
              <br/>
              <Form.Group controlId="workoutLength">
                <Form.Label>Average Workout Length</Form.Label>
                <Form.Control type="number" value={this.state.generateAvgWorkoutLength} onChange={handleWorkoutLengthChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleHide} disabled={this.state.isGeneratingPlan}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSubmit} disabled={this.state.isGeneratingPlan}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    }
    const plan = this.state.plan

    return (
      <div className="container">
        <br />
        <div className="row">
          <TrainingPlanHeader
            plan={plan}
            featuredPlan={this.state.featuredPlan}
            isEditing={this.state.isEditing}
            nextPlan={this.state.nextPlan}
            refreshData={this.loadTrainingPlan}
          />
        </div>
        {plan &&
        plan.get('trainingPlanWeeks') &&
        plan.get('trainingPlanWeeks').length > 0
          ? plan.get('trainingPlanWeeks').map((week, index) => {
            return (
                <TrainingPlanWeek
                  key={index}
                  week={plan.get('trainingPlanWeeks')[index]}
                  trainingPlan={plan}
                  weekIndex={index}
                  addWorkoutClicked={this.addWorkoutClicked}
                  onCommentChange={this.onCommentChange}
                  removeWeekClicked={this.removeWeekClicked}
                  removeWorkout={this.removeWorkout}
                  isAdmin={this.state.isAdmin}
                />
            )
          })
          : undefined}
        <div className="container">
          <br />
          <button
            className="btn btn-outline-secondary"
            style={{ float: 'center' }}
            onClick={this.addWeekClicked}
          >
            Add Week
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>

        {this.state.isLoading ? <div>Loading...</div> : undefined}
      </div>
    )
  }
}
