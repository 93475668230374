import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { set } from 'date-fns'

export default class WorkoutHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      workout: props.workout,
      editingInfo: props.editingInfo,
      handleEditInfoClicked: props.handleEditInfoClicked,
      isPremium: props.isPremium,
      newIsAdminWorkout: props.isAdminWorkout,
      newIsAllStrength: props.isAllStrength,
      newIsTestSetWorkout: props.isTestSetWorkout,
      newImageId: props.imageId,
      newComment: props.comment,
      newTitle: props.title
    }
  }

  handleEditInfoClicked = (event) => {
    event.preventDefault()
    if (this.state.editingInfo) {
      // currently editing -- need to save new things
      console.log('handleEditInfoClicked calling super with:' + this.state.newTitle + ' ' + this.state.newComment + ' ' + this.state.newImageId + ' ' + this.state.newIsAllStrength + ' ' + this.state.isPremium + ' ' + this.state.newIsAdminWorkout)
      this.state.handleEditInfoClicked(this.state.newTitle, this.state.newComment, this.state.newImageId, this.state.newIsAllStrength, this.state.isPremium, this.state.newIsAdminWorkout, this.state.newIsTestSetWorkout)
    }
    this.setState({
      editingInfo: !this.state.editingInfo
    })
  }

  handleTitleChange = (event) => {
    console.log('handleTitle: ' + event.target.value)
    // this.state.workout.set("title", event.target.value);
    this.setState({
      newTitle: event.target.value
    })
  }

  handleCommentChange = (event) => {
    console.log('handleComment: ' + event.target.value)
    this.setState({
      newComment: event.target.value
    })
  }

  handleImagIdChange = (event) => {
    console.log('handleImageId: ' + event.target.value)
    this.setState({
      newImageId: event.target.value
    })
  }

  handleIsStrengthChange = (event) => {
    console.log('onStrengthChange: ' + event.target.checked)
    this.setState({
      newIsAllStrength: event.target.checked
    })
  }

  handleIsPremiumChange = (event) => {
    console.log('onPremiumChange: ' + event.target.checked)
    this.setState({
      isPremium: event.target.checked
    })
  }

  handleIsAdminWorkoutChange = (event) => {
    console.log('onAdminChange: ' + event.target.checked)
    this.setState({
      newIsAdminWorkout: event.target.checked
    })
  }

  handleIsTestSetWorkoutChange = (event) => {
    console.log('isTestSetChange: ' + event.target.checked)
    this.setState({
      newIsTestSetWorkout: event.target.checked
    })
  }

  render = () => {
    const workout = this.state.workout
    let titleSpan
    let commentSpan
    let imageSpan

    if (!this.state.editingInfo) {
      if (this.props.title === undefined && (workout.get('title') === undefined || workout.get('title') === '')) {
        titleSpan = <p className="text-muted">none</p>
      } else {
        titleSpan = <p>{this.props.title ? this.props.title : this.state.workout.get('title')}</p>
      }

      if (this.props.comment === undefined && (workout.get('comments') === undefined || workout.get('comments') === '')) {
        commentSpan = <p className="text-muted">none</p>
      } else {
        commentSpan = <p>{this.props.comment ? this.props.comment : this.state.workout.get('comments')}</p>
      }

      if (this.props.imageId === undefined && (workout.get('imageID') === undefined || workout.get('imageID') === '')) {
        imageSpan = <p className="text-muted">none</p>
      } else {
        imageSpan = <p>{this.props.imageId ? this.props.imageId : this.state.workout.get('imageID')}</p>
      }
    } else {
      titleSpan = <textarea rows="1" cols="50" onChange={this.handleTitleChange} value={this.state.newTitle || this.state.workout.get('title')}></textarea>
      commentSpan = <textarea rows="1" cols="50" onChange={this.handleCommentChange} value={this.state.newComment || this.state.workout.get('comments')}></textarea>
      imageSpan = <textarea rows="1" cols="50" onChange={this.handleImagIdChange} value={this.state.newImageId || this.state.workout.get('imageID')}></textarea>
    }

    return (
            <div className="padding border">
                <div className="">
                    <div className="row">
                        <div className="col-md-2">
                            <b>Title</b>
                        </div>
                        <div className="col-md-8">
                            {titleSpan}
                        </div>
                        <div className="ml-auto mr-3">
                            {
                              this.state.editingInfo
                                ? <button className="btn btn-success" onClick={this.handleEditInfoClicked}>
                              Done
                          </button>
                                : <button className="btn btn-primary" onClick={this.handleEditInfoClicked}>
                               Edit Metadata
                            </button>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <b>Coach&apos;s Note</b>
                        </div>
                        <div className="col-md-10">
                            {commentSpan}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <b>Image ID</b>
                        </div>
                        <div className="col-md-10">
                            {imageSpan}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <b>AI Model</b>
                        </div>
                        <div className="col-md-10">
                          <code className="text-muted">{workout.get('aiModel') || ''}</code>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <label><input disabled={!this.state.editingInfo} type="checkbox" checked={this.state.newIsAllStrength !== undefined ? this.state.newIsAllStrength : this.state.workout.get('isAllStrength')} onChange={this.handleIsStrengthChange}/>&nbsp; <b>Strength Workout</b> <span className="text-muted">This workout contains strength sets.</span></label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label><input disabled={!this.state.editingInfo} type="checkbox" checked={this.state.isPremium !== undefined ? this.state.isPremium : this.state.workout.get('isPremium')} onChange={this.handleIsPremiumChange}/>&nbsp; <b>Premium Library</b> <span className="text-muted">This workout will appear as locked in the workout library.</span></label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label><input disabled={!this.state.editingInfo} type="checkbox" checked={this.state.newIsAdminWorkout !== undefined ? this.state.newIsAdminWorkout : this.state.workout.get('isAdminWorkout')} onChange={this.handleIsAdminWorkoutChange}/>&nbsp; <b>Free Library</b> <span className="text-muted">This workout will appear as free in the workout library.</span></label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label><input disabled={!this.state.editingInfo} type="checkbox" checked={this.state.newIsTestSetWorkout !== undefined ? this.state.newIsTestSetWorkout : this.state.workout.get('isTestSetWorkout')} onChange={this.handleIsTestSetWorkoutChange}/>&nbsp; <b className={this.state.workout.get('isTestSetWorkout') ? 'bg-warning' : ''}>Test Set Workout</b> <span className="text-muted">This workout will appear as a test set.</span></label>
                    </div>
                </div>
            </div>
    )
  }
}
