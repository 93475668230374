import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class EffortVariationList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      effortVariation: []
    }

    this.loadEffortVariation = this.loadEffortVariation.bind(this)

    this.loadEffortVariation()
  }

  loadEffortVariation = () => {
    const effortVariationType = new Parse.Object('EffortVariation')
    const query = new Parse.Query(effortVariationType)
    query.limit(1000)
    query.find().then((results) => {
      const effortVariation = results
      const totalCount = effortVariation.count
      if (!effortVariation || effortVariation.length === 0) {
        alert('No effortVariation found.')
        return
      }

      this.setState({
        effortVariation: effortVariation,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting effortVariation: ' + error.message)
    })
  }

  filteredEffortVariation = () => {
    const filter = this.state.filter
    if (filter && filter !== '') {
      const lowercaseFilter = filter.toLowerCase()
      return this.state.effortVariation.filter((effortVariation) => {
        const tagMatch = effortVariation.get('key').toLowerCase().includes(lowercaseFilter)
        const nameMatch = effortVariation.get('name').toLowerCase().includes(lowercaseFilter)
        return nameMatch || tagMatch
      })
    }
    return this.state.effortVariation
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value
    })
  }

  handleNew = async (event) => {
    const newKey = prompt('Please enter a key name for this effortVariation. Careful- you can\'t change it once it\'s set! No spaces allowed.\n\nEx: pull_buoy, fins, medicine_ball')
    if (!newKey) return
    const newName = prompt('Please enter the name of the effortVariation. Members will see this name.')
    if (!newName) return
    const newObj = new Parse.Object('EffortVariation')
    newObj.set('key', newKey)
    newObj.set('name', newName)
    await newObj.save()
    window.location.href = '/#/effortVariation/' + newObj.id
    window.location.reload()
  }

  renderTable = () => {
    return this.filteredEffortVariation().map((effortVariation, index) => {
      return (
        <tr key={index} className="data">
            <th scope="row">
              <code>{effortVariation.get('key')}</code>
            </th>
            <td>
              <img src={effortVariation.get('image').url()}></img>
            </td>
            <td>
              <b>
                <a href={'/#/effortVariation/' + effortVariation.id}>
                  {effortVariation.get('name')}
                </a>
                <br/>
                <span className="text-muted">{effortVariation.get('description')}</span>
              </b>
            </td>
        </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
      <tr key={'0-header'} className="data">
        <th className="bg-primary" key={'0h'} scope="col">Key</th>
        <th className="bg-primary" key={'1h'} scope="col"></th>
        <th className="bg-primary" key={'2h'} scope="col">Details</th>
      </tr>
    )
  }

  render = () => {
    return (
        <div>
            <Route path="/effortVariation">
              <div className="container">
                <br/>
                <h1>Effort Variation</h1>

                <div className="row">
                    <form className="col-sm-6">
                        <div className="form-group">
                            <input type="text" className="form-control" id="labelFilter" placeholder="Filter" onChange={this.handleFilterChange}/>
                        </div>
                    </form>
                    <div className="col-sm-1 offset-md-5">
                      <button className="btn btn-primary" onClick={this.handleNew}>Add</button>
                    </div>
                </div>

                <small className="text-muted"><b>Note:</b> An effortVariation can not be deleted after being created and it&apos;s meaning should not be significantly modified after being deployed.</small>
                <table id="featuredcontent" className="table table-striped table-hover">
                    <thead>
                      {this.renderTableHeader()}
                    </thead>
                    <tbody>
                      {this.renderTable()}
                    </tbody>
                </table>
              </div>
            </Route>
        </div>)
  }
}
