import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse, { User } from 'parse'
import SetGroupRow from './SetGroupRowComponent'
import WorkoutDetails from './WorkoutDetailsComponent'
import WorkoutHeader from './WorkoutHeaderComponent'
import { copyWorkout } from '../../Utils'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export default class Workout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      id: this.props.id ? this.props.id : this.props.match.params.id,
      workoutOfTheDayId: this.props.workoutOfTheDayId,
      editingInfo: false,
      hasEditsToSave: false,
      onDuplicateWorkout: props.onDuplicateWorkout,
      workoutClassName: props.isAdmin ? 'AdminWorkout' : 'Workout_v3',
      setGroupClassName: props.isAdmin ? 'AdminSetGroup' : 'SetGroup_v3',
      setClassName: props.isAdmin ? 'AdminSet' : 'AdminSet',
      isAdmin: props.isAdmin,
      basePath: props.isAdmin ? 'adminworkouts' : 'workouts',
      setGroupList: [],
      modalIsOpen: false,
      setgroupId: null,
      isGeneratingWorkout: false
    }
  }

  componentDidMount () {
    this.loadWorkout = this.loadWorkout.bind(this)
    this.generateWorkoutUserPrompt = this.generateWorkoutUserPrompt.bind(this)

    this.loadWorkout()

    const generateWorkoutUserId = new URLSearchParams(this.props.location?.search).get('generateWorkoutUserId')
    if (generateWorkoutUserId) {
      console.log('Need to generate workout for user ' + generateWorkoutUserId)
      this.generateWorkoutUserPrompt(generateWorkoutUserId)
    }
  }

  generateWorkoutUserPrompt = async (userId) => {
    const promptInput = prompt(`⚠️ EXPERIMENTAL!
This feature uses gpt-4-turbo which is very slow and not yet production-ready. Please be patient.

Generate a workout for member ${userId} based on their workout history. Enter optional prompt:`)
    if (promptInput === null) return

    this.setState({
      isGeneratingWorkout: true
    })
    try {
      const workoutJson = await Parse.Cloud.run('generateWorkout', { prompt: promptInput, userId: userId, useHistory: true, model: 'gpt-4-turbo-preview' })
      this.updateFromWorkoutJson(workoutJson)
      this.setState({ isGeneratingWorkout: false, hasEditsToSave: true })
    } catch (error) {
      alert(error)
      this.setState({ isGeneratingWorkout: false })
    }
  }

  loadWorkout = async () => {
    let workout
    if (this.state.id && this.state.id !== 'new') {
      const query = new Parse.Query(this.state.workoutClassName)
      query.include('setGroups.sets')
      workout = await query.get(this.state.id)
    } else if (this.state.workoutJson) {
      this.updateFromWorkoutJson(this.state.workoutJson)
    } else {
      console.log('new workout')
      workout = new Parse.Object(this.state.workoutClassName)
    }
    this.setState({
      workout: workout,
      isAdminWorkout: workout.get('isAdminWorkout'),
      isTestSetWorkout: workout.get('isTestSetWorkout'),
      isAllStrength: workout.get('isAllStrength'),
      isPremium: workout.get('isPremium'),
      isLoading: false
    }, () => {
      this.updateTotals()
    })
  }

  handleEditInfoClicked = (
    title,
    comment,
    imageId,
    isAllStrength,
    isPremium,
    isAdminWorkout,
    isTestSetWorkout
  ) => {
    this.setState(
      {
        editingInfo: !this.state.editingInfo,
        title: title,
        comment: comment,
        imageId: imageId,
        isAllStrength: isAllStrength,
        isPremium: isPremium,
        isAdminWorkout: isAdminWorkout,
        isTestSetWorkout: isTestSetWorkout,
        hasEditsToSave: true
      },
      this.saveWorkout
    )
  }

  deleteWorkout = async (event) => {
    const workout = this.state.workout
    if (!workout) return
    const ok = confirm(
      'Are you sure you want to delete this workout? It will be gone forever.'
    )
    if (!ok) return
    await workout.destroy()
    window.location.href = '/#' + this.state.basePath
    window.location.reload()
  }

  resetWorkout = (event) => {
    this.setState(
      {
        hasEditsToSave: false,
        workout: undefined,
        isLoading: true
      },
      this.loadWorkout
    )
  }

  saveWorkout = (event) => {
    // first check if any have no sets, would be invalid to save
    let emptySetPresent = false

    const workout = this.state.workout
    const setGroupsToSave = workout.get('setGroups')
    let setGroupSavePromises = []
    if (setGroupsToSave) {
      setGroupsToSave.forEach((setGroup) => {
        if (setGroup.get('sets').length === 0) {
          emptySetPresent = true
        }
      })

      if (emptySetPresent) {
        alert('Please add sets to empty set groups or remove the set groups.')
        return
      }

      setGroupSavePromises = setGroupsToSave.map((setGroup) => {
        return setGroup.save()
      })
    }

    const metadataSave = () => {
      console.log('set groups finished saving, saving workout...')
      if (this.state.title) workout.set('title', this.state.title)
      if (this.state.comment) workout.set('comments', this.state.comment)
      if (this.state.imageId) workout.set('imageID', this.state.imageId)

      workout.set('isAllStrength', this.state.isAllStrength || false)
      workout.set('isPremium', this.state.isPremium || false)
      workout.set('isAdminWorkout', this.state.isAdminWorkout || false)
      workout.set('isTestSetWorkout', this.state.isTestSetWorkout || false)
      workout.set('totalTime', this.recalculateTotalTime())
      workout.set('workoutOfTheDayId', this.state.workoutOfTheDayId)

      if (this.state.isAllStrength) {
        workout.set('totalDistance', 0)
      } else {
        workout.set('totalDistance', this.recalculateTotalDistance())
      }

      workout.save().then((success, error) => {
        console.log('successfully saved workout, resetting state')
        console.log(success)
        console.log(error)
        this.setState({
          workout: workout,
          hasEditsToSave: false,
          title: undefined,
          comment: undefined,
          imageId: undefined
        })
      })
    }

    if (setGroupSavePromises) {
      Promise.all(setGroupSavePromises).then(() => {
        metadataSave()
      })
    } else {
      metadataSave()
    }
  }

  duplicateWorkout = async (event) => {
    // this means we're in a WOD and we'll duplicate at that level
    if (this.state.onDuplicateWorkout !== undefined) {
      console.log('Calling super.onDuplicateWorkout')
      this.state.onDuplicateWorkout()
      return
    }

    console.log('Duplicating workout...')

    try {
      const newWorkout = new Parse.Object(this.state.workoutClassName)
      await newWorkout.save()
      await copyWorkout(
        this.state.workout.id,
        newWorkout.id,
        this.state.workoutClassName
      )

      let url = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/')
      )
      url = url + '/' + newWorkout.id
      const win = window.open(url)
      if (win) {
        win.focus()
      } else {
        alert('Workout copied. Check your popup blocker')
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  duplicateSetGroup = async (event) => {
    // this means we're in a WOD and we'll duplicate at that level
    if (this.state.onDuplicateWorkout !== undefined) {
      console.log('Calling super.onDuplicateWorkout')
      this.state.onDuplicateWorkout()
      return
    }

    console.log('Duplicating setGroup...')

    try {
      const newWorkout = new Parse.Object(this.state.workoutClassName)

      // await copyWorkout(this.state.workout.id, newWorkout.id, this.state.workoutClassName)
      // first check if any have no sets, would be invalid to save
      let emptySetPresent = false

      const workout = this.state.workout
      const setGroupsToSave = workout.get('setGroups')
      let setGroupSavePromises = []
      if (setGroupsToSave) {
        setGroupsToSave.forEach((setGroup) => {
          if (setGroup.get('sets').length === 0) {
            emptySetPresent = true
          }
        })

        if (emptySetPresent) {
          alert('Please add sets to empty set groups or remove the set groups.')
          return
        }

        setGroupSavePromises = setGroupsToSave.map((setGroup) => {
          return setGroup.clone()
        })

        newWorkout.set('setGroups', setGroupSavePromises)
      }
      await newWorkout.save()

      let url = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/')
      )
      url = url + '/' + newWorkout.id
      const win = window.open(url)
      if (win) {
        win.focus()
      } else {
        alert('Workout copied. Check your popup blocker')
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  addSetGroupClicked = (event) => {
    const workout = this.state.workout

    const setGroups = workout.get('setGroups')
    let setGroupsClone
    if (setGroups) {
      setGroupsClone = setGroups.map((setGroup) => {
        return setGroup.clone()
      })
    } else {
      setGroupsClone = []
    }
    const newSetGroup = new Parse.Object(this.state.setGroupClassName)
    newSetGroup.set('sets', [])
    newSetGroup.set('reps', 1) // default to 1 rep
    setGroupsClone.push(newSetGroup)
    workout.set('setGroups', setGroupsClone)
    console.log(
      'addsetgroupclicked count of setgroups: ' + setGroupsClone.length
    )
    this.setState({
      workout: workout,
      totalDistance: this.recalculateTotalDistance(),
      totalTime: this.recalculateTotalTime(),
      hasEditsToSave: true
    })
  }

  recalculateTotalTime = () => {
    let total = 0
    const workout = this.state.workout

    if (
      workout.get('setGroups') === undefined ||
      workout.get('setGroups').length === 0
    ) {
      return total
    }

    for (let i = 0; i < workout.get('setGroups').length; i++) {
      const sg = workout.get('setGroups')[i]
      let multiplier = sg.get('reps')
      if (multiplier < 1) {
        multiplier = 1
      }
      for (let j = 0; j < sg.get('sets').length; j++) {
        const set = sg.get('sets')[j]
        let setTotal = 0
        if (set.get('interval') > 0) {
          setTotal += set.get('interval') * set.get('reps')
        } else {
          setTotal += set.get('distance') * set.get('reps')
        }
        total += multiplier * setTotal
      }
      total += 60
    }
    return total
  }

  recalculateTotalDistance = () => {
    let total = 0
    const workout = this.state.workout

    if (
      workout.get('setGroups') === undefined ||
      workout.get('setGroups').length === 0
    ) {
      return total
    }

    for (let i = 0; i < workout.get('setGroups').length; i++) {
      const sg = workout.get('setGroups')[i]
      let multiplier = sg.get('reps') || 1
      if (multiplier < 1) {
        multiplier = 1
      }
      for (let j = 0; j < sg.get('sets').length; j++) {
        const set = sg.get('sets')[j]
        total += multiplier * set.get('distance') * set.get('reps')
      }
    }
    return total
  }

  updateTotals = () => {
    console.log('updateTotals: ' + this.recalculateTotalTime())
    this.setState({
      totalDistance: this.recalculateTotalDistance(),
      totalTime: this.recalculateTotalTime()
    })
  }

  setGroupUpdate = (setGroup, index) => {
    const workout = this.state.workout
    const setGroups = workout.get('setGroups')
    const setGroupsClone = setGroups.map((setGroup) => {
      return setGroup.clone()
    })
    setGroupsClone[index] = setGroup
    workout.set('setGroups', setGroupsClone)
    console.log('setGroupUPdate count of setgroups: ' + setGroupsClone.length)
    this.setState(
      {
        workout: workout,
        hasEditsToSave: true
      },
      this.updateTotals()
    )
  }

  setDuplicateSetGroup = async (index) => {
    const workout = this.state.workout
    const setGroups = workout.get('setGroups')
    const setGroupsClone = setGroups.map((setGroup) => {
      return setGroup.clone()
    })

    const newSetPromises = await setGroupsClone[index]
      .get('sets')
      .map(async (set) => {
        const newSet = set.clone()
        await newSet.save()
        return newSet
      })

    const newSets = await Promise.all(newSetPromises)

    const newSetGroup = new Parse.Object(this.state.setGroupClassName)
    newSetGroup.set('sets', newSets)
    newSetGroup.set('title', setGroupsClone[index].get('title'))
    newSetGroup.set('index', setGroupsClone[index].get('index'))
    newSetGroup.set('reps', setGroupsClone[index].get('reps')) // default to 1 rep
    setGroupsClone.splice(index + 1, 0, newSetGroup)
    workout.set('setGroups', setGroupsClone)
    console.log('setGroupUPdate count of setgroups: ' + setGroupsClone.length)
    this.setState(
      {
        workout: workout,
        hasEditsToSave: true
      },
      this.updateTotals
    )
  }

  setGroupDelete = (index) => {
    console.log('setgroupdelete' + index)
    const workout = this.state.workout
    const setGroups = workout.get('setGroups')
    setGroups.splice(index, 1)
    workout.set('setGroups', setGroups)
    this.setState({
      workout: workout,
      hasEditsToSave: true,
      totalDistance: this.recalculateTotalDistance(),
      totalTime: this.recalculateTotalTime()
    })
  }

  updatePoolCourse = (event) => {
    this.state.workout.set('poolCourse', parseInt(event.target.value))
    if (event.target.value === 0) {
      const pool = { isDisplayInYards: true, distanceInMeters: 22.86, isDefault: false }
      this.state.workout.set('pool', pool)
    } else if (event.target.value === 1) {
      const pool = { isDisplayInYards: false, distanceInMeters: 25, isDefault: false }
      this.state.workout.set('pool', pool)
    } else if (event.target.value === 2) {
      const pool = { isDisplayInYards: false, distanceInMeters: 50, isDefault: false }
      this.state.workout.set('pool', pool)
    }
    this.setState({
      hasEditsToSave: true
    })
  }

  updateSkillLevel = (event) => {
    this.state.workout.set('skillLevel', parseInt(event.target.value))
    this.setState({
      hasEditsToSave: true
    })
  }

  updateWorkoutType = (event) => {
    this.state.workout.set('workoutType', parseInt(event.target.value))
    this.setState({
      hasEditsToSave: true
    })
  }

  newFromClone = async (e) => {
    e.preventDefault()
    const { setgroupId } = this.state

    if (!setgroupId) {
      alert('SetGroup Id is required!')
      return
    }

    const plan = new Parse.Object(this.state.setGroupClassName)

    const query = new Parse.Query(plan)

    query.equalTo('objectId', setgroupId)

    const plans = await query.find()

    if (plans && plans.length > 0) {
      const workout = this.state.workout
      const setGroups = workout.get('setGroups')
      const setGroupsClone = setGroups.map((setGroup) => {
        return setGroup.clone()
      })

      const newSetPromises = await plans[0].get('sets').map(async (set) => {
        const newSet = set.clone()
        await newSet.save()
        return newSet
      })

      const newSets = await Promise.all(newSetPromises)

      const newSetGroup = new Parse.Object(this.state.setGroupClassName)
      newSetGroup.set('sets', newSets)
      newSetGroup.set('reps', plans[0].get('reps'))
      newSetGroup.set('title', plans[0].get('title'))
      newSetGroup.set('index', plans[0].get('index'))
      setGroupsClone.push(newSetGroup)
      workout.set('setGroups', setGroupsClone)
      console.log('setGroupUPdate count of setgroups: ' + setGroupsClone.length)
      this.setState(
        {
          workout: workout,
          hasEditsToSave: true,
          modalIsOpen: false
        },
        this.updateTotals
      )
    } else {
      alert('SetGroup Id is not valid!')
    }
  }

  onDragEnd = (result) => {
    const setGroupList = this.state.workout.get('setGroups')

    const id = result.draggableId
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.type === 'droppableParent') {
      const workout = reorder(
        setGroupList,
        result.source.index,
        result.destination.index
      )

      this.setState({
        workout: this.state.workout.set('setGroups', workout),
        hasEditsToSave: true
      })
    } else if (result.type == 'droppableSubItem') {
      const splitId = id.split('-')
      const setGroupId = splitId[1]
      const getSetGroups = this.state.workout.get('setGroups')
      const checkIndex = getSetGroups.findIndex((item) => item.id == setGroupId)
      const setGroup = getSetGroups[checkIndex]
      const sets = setGroup.get('sets')
      const dragSets = sets[result.source.index]
      sets.splice(result.source.index, 1)
      sets.splice(result.destination.index, 0, dragSets)
      setGroup.set('sets', sets)
      getSetGroups[checkIndex] = setGroup
      this.setState({
        workout: this.state.workout.set('setGroups', getSetGroups)
      })
    }
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  generateWorkoutHit = async () => {
    const input = await prompt('Optionally add a workout prompt. Try: "Focus on speed" or "No kick sets" or "Soccer themed"')
    if (input === undefined) return
    this.setState({ isGeneratingWorkout: true })
    try {
      const workoutJson = await Parse.Cloud.run('generateWorkout', { prompt: input })
      this.updateFromWorkoutJson(workoutJson)
      this.setState({ isGeneratingWorkout: false, hasEditsToSave: true })
    } catch (error) {
      alert(error)
      this.setState({ isGeneratingWorkout: false })
    }
  }

  updateFromWorkoutJson = (workoutJson) => {
    console.log(workoutJson)
    const workout = this.state.workout
    workout.set('title', workoutJson.title)
    workout.set('comments', workoutJson.coachNotes)
    workout.set('aiModel', workoutJson.model)

    const setGroups = []
    for (const setGroupJson of workoutJson.setGroups) {
      const setGroup = new Parse.Object('SetGroup_v3')
      setGroup.set('title', setGroupJson.title)
      setGroup.set('reps', parseInt(setGroupJson.reps))

      const sets = []
      for (const setJson of setGroupJson.sets) {
        const set = new Parse.Object('Set_v3')
        set.set('reps', parseInt(setJson.reps))
        set.set('distance', setJson.distance)
        set.set('stroke', setJson.stroke)
        set.set('interval', parseInt(setJson.interval))
        set.set('zone', setJson.zone)
        set.set('effortVariation', setJson.effortVariation)
        set.set('notes', setJson.notes)
        set.set('equipment', setJson.equipment)
        sets.push(set)
      }

      setGroup.set('sets', sets)
      setGroups.push(setGroup)
    }
    workout.set('setGroups', setGroups)
    this.setState({ workout, workoutJson }, () => {
      this.updateTotals()
    })
  }

  render = () => {
    if (!this.state.workout) {
      return (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ height: '90vh' }}
        >
          <h3>Loading...</h3>
        </div>
      )
    }

    const workout = this.state.workout
    const { modalIsOpen } = this.state

    return (
      <div className="padding">
        <button className="btn btn-primary" onClick={this.generateWorkoutHit} disabled={this.state.isGeneratingWorkout}>
          {this.state.isGeneratingWorkout ? 'Generating...' : '🪄 Generate unique workout'} &nbsp;
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isGeneratingWorkout}></span>
        </button>
        <br/><br/>
        <WorkoutHeader
          workout={this.state.workout}
          editingInfo={this.state.editingInfo}
          handleEditInfoClicked={this.handleEditInfoClicked}
          title={this.state.workout.get('title')}
          comment={this.state.workout.get('comments')}
          imageId={this.state.workout.get('imageID')}
          isAdminWorkout={this.state.workout.get('isAdminWorkout')}
          isTestSetWorkout={this.state.workout.get('isTestSetWorkout')}
          isPremium={this.state.workout.get('isPremium')}
          isAllStrength={this.state.workout.get('isAllStrength')}
        />
        <WorkoutDetails
          workout={this.state.workout}
          onDelete={this.deleteWorkout}
          onUpdateSkillLevel={this.updateSkillLevel}
          onUpdateWorkoutType={this.updateWorkoutType}
          onUpdatePoolCourse={this.updatePoolCourse}
          totalDistance={
            this.state.totalDistance
              ? this.state.totalDistance
              : this.state.workout.get('totalDistance')
          }
          totalTime={
            this.state.totalTime
              ? this.state.totalTime
              : this.state.workout.get('totalTime')
          }
          isAdmin={this.state.isAdmin}
        />

        {workout.get('setGroups') !== undefined
          ? (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" type="droppableParent">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {workout.get('setGroups').map((setGroup, index) => {
                    return (
                      <Draggable
                        key={setGroup.id}
                        draggableId={setGroup.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="my-4"
                          >
                            <SetGroupRow
                              isAdmin={this.state.isAdmin}
                              key={index}
                              setGroup={setGroup}
                              index={index}
                              onUpdate={this.setGroupUpdate}
                              onDelete={this.setGroupDelete}
                              onDuplicateSetGroup={this.setDuplicateSetGroup}
                              defaultSetType={
                                this.state.isAllStrength ||
                                this.state.workout.get('isAllStrength')
                                  ? 1
                                  : 0
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
            )
          : null}

        <div className="row justify-content-center mb-4">
          <button
            className="btn btn-outline-secondary mr-2"
            onClick={() => this.setState({ modalIsOpen: true })}
          >
            Add SetGroup from clone
          </button>

          <button className="btn btn-primary" onClick={this.addSetGroupClicked}>
            + Add SetGroup
          </button>
        </div>

        <hr />

        <div className="d-flex align-items-center justify-content-end my-4">
          <div className="">
            {this.state.hasEditsToSave
              ? (
              <button className="btn btn-danger" onClick={this.resetWorkout}>
                Reset
              </button>
                )
              : null}
          </div>
          <div className="">
            <button
              className="btn btn-primary ml-3"
              onClick={this.duplicateWorkout}
            >
              {this.state.onDuplicateWorkout !== undefined
                ? 'Duplicate WOD'
                : 'Duplicate workout'}
            </button>
          </div>
          <div className="">
            {this.state.hasEditsToSave
              ? (
              <button
                className="btn btn-success ml-3"
                onClick={this.saveWorkout}
              >
                Save
              </button>
                )
              : null}
          </div>
        </div>
        {this.state.isLoading && (
          <div style={{ height: '90vh' }}>
            <h3>Loading...</h3>
          </div>
        )}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form onSubmit={this.newFromClone}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Set Group Id </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(event) => {
                  this.setState({
                    setgroupId: event.target.value
                  })
                }}
              />
              <small id="emailHelp" className="form-text text-muted">
                please type your set-group object id .
              </small>
            </div>
            <button type="submit" className="btn btn-primary">
              Verify & Clone
            </button>
          </form>
        </Modal>
      </div>
    )
  }
}
