import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Link
} from 'react-router-dom'

export default class SubscriptionTableRow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      subscription: props.subscription
    }

    const userQuery = new Parse.Query(Parse.User)
    userQuery.equalTo('objectId', this.state.subscription.userId)
    userQuery.find().then((users) => {
      console.log(users)
      const subscription = this.state.subscription
      if (users.length > 0) {
        subscription.username = users[0].get('username')
      } else {
        // none loaded for this userId
        // include a space to replace "loading" (ternary will get false for empty string)
        subscription.username = ' '
      }
      this.setState({
        subscription: subscription
      })
    })
  }

  render = () => {
    const subscription = this.state.subscription
    const userPath = '/members/' + subscription.userId

    return (
            <tr key={subscription.objectId} className="data">
              <td><b>{subscription.objectId}</b></td>
              <td><Link className="btn-link" to={userPath}>{subscription.userId}</Link></td>
              <td>{subscription.planId}</td>
              <td>{subscription.creationSource}</td>
              <td>{subscription.lastUpdateSource}</td>
              <td><a href={'https://dashboard.stripe.com/customers/' + subscription.stripeCustomerId}>{subscription.stripeCustomerId}</a></td>
            </tr>
    )
  }
}
