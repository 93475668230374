const Cookie = require('js-cookie')

const Config = {}

const environmentKey = 'msp.environment'

Config.appId = process.env.APP_ID || '0KQMZF8jPfhZX7t8BA66B9epJ37B0cKEaj3z0hyI'
Config.clientKey = process.env.CLIENT_KEY || '0SiCWab7xIT0cV8NVV5qQmcea8pBjxtJJ4qAZEsA'
Config.revenueCatProjectId = process.env.REVENUE_CAT_PROJECT_ID || '64664645'

Config.setEnvironment = function (environment) {
  Cookie.set(environmentKey, environment)
}
Config.getEnvironment = function () {
  return Cookie.get(environmentKey) || '?'
}
if (Config.getEnvironment().toLowerCase() === 'dev') {
  Config.serverUrl = process.env.SERVER_URL || 'https://dev-api.myswimpro.com/parse/'
} else {
  Config.serverUrl = process.env.SERVER_URL || 'https://staging-api.myswimpro.com/parse/'
}

module.exports = Config
