import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'

export default class FeaturedContentTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: [],
      isLoading: true,
      isAdding: false
    }

    this.loadContent = this.loadContent.bind(this)
    this.fileInput = React.createRef()

    this.loadContent()
  }

  loadContent = () => {
    console.log('loadContent')

    const content = new Parse.Object('Content_v3')
    const query = new Parse.Query(content)
    query.limit(1000)
    query.descending('createdAt')
    query.descending('postDate')
    query.find().then((results) => {
      const content = results
      console.log('got result for content')
      const totalCount = content.count
      if (!content) {
        alert('No content found.')
        return
      }

      this.setState({
        content: content,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  handleAddContentClicked = (id, index) => {
    const content = this.state.content[index]
    console.log(content)

    this.setState({
      isAdding: true,
      addingTo: content,
      tempContentURL: content.get('url'),
      tempDescription: content.get('description'),
      tempImageURL: content.get('imageUrl'),
      tempTitle: content.get('title'),
      tempDate: content.get('postDate') || content.get('createdAt')
    })
  }

  handleDeleteContentClicked = (contentId, index, event) => {
    event.preventDefault()
    if (!window.confirm("Are you sure you'd like to delete this item? You cannot undo this action.")) {
      return
    }
    const content = this.state.content[index]
    content.destroy().then(() => {
      this.loadContent()
    })
  }

  renderTable = () => {
    return this.state.content.map((content, index) => {
      const imageUrl = content.get('imageUrl')
      const date = content.get('postDate') || content.get('createdAt')
      return (
               <tr key={index} className="data">
                    <td>
                        {date.toLocaleDateString('en-US')}
                        <br/>
                        <small className="text-muted">
                            {date > new Date() ? '🗓 Scheduled' : '✔️ Posted'}
                        </small>
                    </td>
                    <td>
                        <a href={imageUrl}>
                            <img height="100px" src={imageUrl} alt={imageUrl}/>
                        </a>
                    </td>
                    <td>
                        <b>{content.get('title')}</b><br/>
                        {content.get('description')}<br/>
                        <a href={content.get('url')}>Link</a>
                    </td>
                    <td className="text-center">
                        <button className="btn btn-secondary" onClick={() => this.handleAddContentClicked(content.id, index)}>
                            Edit
                        </button>
                        <br/>
                        <a href="#" className="badge badge-danger" onClick={(e) => this.handleDeleteContentClicked(content.id, index, e)}>Delete</a>
                    </td>
               </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>{'Date'}</th>
                <th className="bg-primary" key={'1h'}>{'Image'}</th>
                <th className="bg-primary" key={'2h'}>{'Content'}</th>
                <th className="bg-primary" key={'3h'}>{''}</th>
            </tr>
    )
  }

  handleContentURLChange = (event) => {
    this.setState({
      tempContentURL: event.target.value
    })
  }

  handleTitleChange = (event) => {
    this.setState({
      tempTitle: event.target.value
    })
  }

  handleDescriptionChange = (event) => {
    this.setState({
      tempDescription: event.target.value
    })
  }

  handleSaveButtonClick = (event) => {
    event.preventDefault()
    if (!this.state.tempContentURL) {
      alert("Couldn't save, missing content URL")
      return
    }
    const contentURL = this.state.tempContentURL.trim()
    if (!contentURL) {
      alert("Couldn't save, missing content URL")
      return
    }

    const title = this.state.tempTitle.trim()
    if (!title) {
      alert("Couldn't save, missing title")
      return
    }

    const description = this.state.tempDescription.trim()
    if (!description) {
      alert("Couldn't save, missing description")
      return
    }

    if (this.fileInput.current.files[0]) {
      const file = this.fileInput.current.files[0]
      console.log('file is: ' + file.name)

      if (file.name.endsWith('.png')) {
        window.alert('No PNGs! JPEG only plz :)')
        return
      }
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = () => {
        // target size is 500 x 250
        // max size is 1000 x 500
        // alert when aspect ratio is height > width
        if (img.width > 1000 || img.height > 500) {
          alert('This image is too big! Max allowed size is 1000x500')
          return
        } else if (img.height > img.width) {
          alert('Image width should be greater than image height')
          return
        }
        // checks passed, upload image
        const parseImage = new Parse.File(file.name, file)
        parseImage.save().then((fileSuccess, fileError) => {
          if (!fileSuccess) {
            console.log('Error saving file, defaulting to existing image')
            this.saveContentV3(contentURL, this.state.tempImageURL, title, description, this.state.addingTo, this.state.tempDate)
            return
          }

          console.log('successfully saved image, saving to content:' + parseImage.url)
          this.saveContentV3(contentURL, parseImage.url(), title, description, this.state.addingTo, this.state.tempDate)
        }, (error) => {
          window.alert(error.message)
        })
      }
      return
    }

    this.saveContentV3(contentURL, this.state.tempImageURL, title, description, this.state.addingTo, this.state.tempDate)
  }

  saveContentV3 = (contentURL, imageUrl, title, description, existingContent, date) => {
    console.log('saveContent called with url: ' + contentURL + ' imageURL: ' + imageUrl)
    const content_v3 = existingContent || new Parse.Object('Content_v3')
    content_v3.set('url', contentURL)
    content_v3.set('imageUrl', imageUrl)
    content_v3.set('title', title)
    content_v3.set('description', description)
    content_v3.set('postDate', date)
    console.log(date)
    content_v3.save().then(() => {
      console.log('succesfully saved content_v3, new id:' + content_v3.id)

      this.setState({
        content: [],
        isAdding: false,
        isLoading: true
      }, this.loadContent)
    })
  }

  handleCancelButtonClick = (event) => {
    this.setState({
      isAdding: false
    })
  }

  handleNewButtonClick = (event) => {
    this.setState({
      isAdding: true
    })
  }

  handleDateChange = (date) => {
    console.log(date)
    this.setState({
      tempDate: date
    })
  }

  renderAddContentView = () => {
    return (
            <div className="card">
              <div className="card-body">
                  <form id="addcontent-form">
                      <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="date"><b>Date</b></label><br/>
                                <DateTimePicker id="date" onChange={this.handleDateChange} value={this.state.tempDate} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="title"><b>Title</b></label>
                                <input type="text" className="form-control" id="title" placeholder="title" value={this.state.tempTitle} onChange={this.handleTitleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="description"><b>Subtitle</b></label>
                                <input type="text" className="form-control" id="description" placeholder="description" value={this.state.tempDescription} onChange={this.handleDescriptionChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="content_url"><b>Content URL</b></label>
                                <input type="text" className="form-control" id="content_url" placeholder="url" value={this.state.tempContentURL} onChange={this.handleContentURLChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="file_upload"><b>Upload image (jpg only)</b></label>
                                <input type="file" ref={this.fileInput} id="file_upload"/>
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={this.handleSaveButtonClick}>Save</button>
                            &nbsp;
                            <button type="button" className="btn btn-secondary" onClick={this.handleCancelButtonClick}>Cancel</button>
                      </div>
                  </form>
              </div>
            </div>
    )
  }

  render = () => {
    console.log('render content table component')
    return (
        <div>
            <Route path="/featuredcontent">
                <div className="container">
                    <br/>
                    <div>
                        <h1 id="title">Feed Content</h1>
                        <p>Content is mixed with member workouts in the Feed, sorted by date. Members see all of this content, beginning at the date they created their account, in the feed.</p>
                        <div className="row justify-content-end">
                            <div className="col-sm-2">
                                <button className="btn btn-primary" onClick={this.handleNewButtonClick}>
                                    New
                                </button>
                            </div>
                        </div>
                        <br/>
                    </div>
                    {
                        this.state.isAdding &&
                        this.renderAddContentView()
                    }
                    <br/>
                    <table id="featuredcontent" className="data">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>
                    { this.state.isLoading &&
                        <div>Loading...</div>
                    }
                </div>
            </Route>
        </div>)
  }
}
