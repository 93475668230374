import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'
const cronParser = require('cron-parser')

export default class ReleaseNotesListComponent extends React.Component {
  constructor (props) {
    super(props)
    const queryDict = {}
    const query = window.location.hash.split('?')
    if (query.length && query[1]) {
      query[1].split('&').forEach(function (item) { queryDict[item.split('=')[0]] = item.split('=')[1] })
    }
    this.state = {
      queryParams: queryDict
    }
    this.loadContent()
  }

  loadContent = async () => {
    const content = new Parse.Object('ReleaseNotes')
    const query = new Parse.Query(content)
    if (this.state.queryParams && this.state.queryParams.status) {
      switch (this.state.queryParams.status) {
        case 'ALPHA':
          query.equalTo('status', 'ALPHA')
          break
        case 'BETA':
          query.equalTo('status', 'BETA')
          break
        case 'PRODUCTION':
          query.notContainedIn('status', ['ALPHA', 'BETA', 'RELEASE_ABORTED'])
          break
        default:
          break
      }
    }
    query.descending('createdAt')
    const results = await query.find()
    this.setState({
      releaseNotes: results
    })
  }

  handleNew = () => {
    window.location.href = '/#/releasenotes/new'
    window.location.reload()
  }

  renderTableHeader = () => {
    return (
      <tr key={'0-header'} className="data">
        <th className="bg-primary" key={'0h'}>Version</th>
        <th className="bg-primary" key={'2h'}>Notes</th>
      </tr>
    )
  }

  renderStatus = (status) => {
    switch (status) {
      case 'ALPHA':
        return (<span className="badge badge-secondary">{ status }</span>)
      case 'BETA':
        return (<span className="badge badge-info">{ status }</span>)
      case 'SUBMITTED':
        return (<span className="badge badge-primary">{ status }</span>)
      case 'RELEASE_ABORTED':
      case 'ROLLOUT_HALTED':
        return (<span className="badge badge-danger">{ status }</span>)
      case 'ROLLOUT_COMPLETE':
        return (<span className="badge badge-success">{ status }</span>)
      default:
        return (<span className="badge badge-light">{ status }</span>)
    }
  }

  renderPlatform = (platform) => {
    switch (platform) {
      case 'ios':
        return (<span>iOS</span>)
      case 'android':
        return (<span >Android</span>)
      default:
        return (<span>{ platform }</span>)
    }
  }

  renderTable = () => {
    if (!this.state.releaseNotes) return (<div className="text-muted">Loading...</div>)
    if (!this.state.releaseNotes.length) return (<div className="text-muted">No results</div>)

    return this.state.releaseNotes.map((releaseNotes, index) => {
      return (
        <div key={releaseNotes.id}>
          <div className="card">
            <div className="row card-body">
              <div className="col-sm-3">
                <h2>
                  { this.renderPlatform(releaseNotes.get('platform')) } { releaseNotes.get('version') }
                </h2>
                <h4>{ this.renderStatus(releaseNotes.get('status')) }</h4>
                <a href={'/#/releasenotes/' + releaseNotes.id}>
                  Details
                </a>
              </div>
              <div className="col-sm-9">
                <span className="text-muted">
                  { !releaseNotes.get('notes') || !releaseNotes.get('notes').length ? 'No notes' : '' }
                </span>
                <ul>
                  { releaseNotes.get('notes').map((note, noteIndex) => {
                    return (<li key={releaseNotes.id + '.' + noteIndex}>{note.comment}</li>)
                  }) }
                </ul>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated { releaseNotes.get('updatedAt').toLocaleString() }</small>
            </div>
          </div>
          <br/>
        </div>
      )
    })
  }

  handleStatusUpdate = (e, status) => {
    e.preventDefault()
    if (status) {
      window.location.href = '/#/releasenotes?status=' + status
    } else {
      window.location.href = '/#/releasenotes'
    }
    window.location.reload()
  }

  render = () => {
    return (
        <div>
            <Route path="/releasenotes">
                <div className="container">
                    <br/>
                    <div className="row justify-content-between">
                      <div className="col">
                        <h1 id="title">MySwimPro App Releases</h1>
                      </div>
                      <div className="col text-right">
                        <button className="btn btn-primary" onClick={this.handleNew}>New</button>
                      </div>
                    </div>
                    <br/>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="card">
                          <h5 className="card-header">Scheduled Promotions</h5>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <b>🔨 Alpha Build</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 0 * * 2-6', { tz: 'UTC' }).next())).toLocaleString() }</span><br/>
                            </li>
                            <li className="list-group-item">
                              <b>🧪 Alpha to Beta</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 2 * * 3', { tz: 'UTC' }).next())).toLocaleString() }</span><br/>
                            </li>
                            <li className="list-group-item">
                              <b>🚀 Beta to Production</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 2 * * 3', { tz: 'UTC' }).next())).toLocaleString() }</span><br/>
                            </li>
                          </ul>
                        </div>
                        <br/>
                        <div className="card">
                          <h5 className="card-header">Scheduled Rollouts</h5>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <b>📈 Android to 100%</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 1 * * 6', { tz: 'UTC' }).next())).toLocaleString() }</span><br/>
                            </li>
                          </ul>
                        </div>
                        <br/>
                        <div className="card">
                          <h5 className="card-header">History</h5>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item list-group-item-light">
                              <b>🔨 Alpha Build</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 0 * * 2-6', { tz: 'UTC' }).prev())).toLocaleString() }</span><br/>
                            </li>
                            <li className="list-group-item list-group-item-light">
                              <b>🧪 Alpha to Beta</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 2 * * 3', { tz: 'UTC' }).prev())).toLocaleString() }</span><br/>
                            </li>
                            <li className="list-group-item list-group-item-light">
                              <b>🚀 Beta to Production</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 2 * * 3', { tz: 'UTC' }).prev())).toLocaleString() }</span><br/>
                            </li>
                            <li className="list-group-item list-group-item-light">
                              <b>📈 Android to 100%</b><br/>
                              <span className="text-muted">{ (new Date(cronParser.parseExpression('0 1 * * 6', { tz: 'UTC' }).prev())).toLocaleString() }</span><br/>
                            </li>
                          </ul>
                        </div>
                        <hr/>
                      </div>
                      <div className="col-sm-9">
                        <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                          <div className="btn-group mr-2" role="group" aria-label="First group">
                            <button type="button" className={ (!this.state.queryParams.status) ? 'btn btn-secondary' : 'btn btn-outline-secondary' } onClick={ (e) => this.handleStatusUpdate(e) }>All</button>
                          </div>
                          <div className="btn-group mr-2" role="group" aria-label="Second group">
                            <button type="button" className={ this.state.queryParams.status === 'ALPHA' ? 'btn btn-secondary' : 'btn btn-outline-secondary' } onClick={ (e) => this.handleStatusUpdate(e, 'ALPHA') }>Alpha</button>
                            <button type="button" className={ this.state.queryParams.status === 'BETA' ? 'btn btn-secondary' : 'btn btn-outline-secondary' } onClick={ (e) => this.handleStatusUpdate(e, 'BETA') }>Beta</button>
                            <button type="button" className={ this.state.queryParams.status === 'PRODUCTION' ? 'btn btn-secondary' : 'btn btn-outline-secondary' } onClick={ (e) => this.handleStatusUpdate(e, 'PRODUCTION') }>Production</button>
                          </div>
                        </div>
                        <br/>
                        {this.renderTable()}
                      </div>
                    </div>
                    <br/>
                    <br/>
                </div>
            </Route>
        </div>)
  }
}
