import React from 'react'

export default class LoginForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  readFile = (event) => {
    const file = event.target.files[0]

    if (file) {
      this.setState({
        isUploadingFile: true
      })
      const reader = new FileReader()
      reader.onloadend = (e) => {
        const json = JSON.parse(e.target.result)
        const output = this.analyzeFile(json)
        this.setState({
          output: output,
          isProcessingFile: false
        })
      }
      reader.onerror = (e) => {
        // error occurred
        console.log('[Apple Watch Session file] Error : ' + e.type)
        window.alert('Error getting file: ' + e.message)
      }
      reader.readAsText(file)
    }
  }

  analyzeFile = (json) => {
    const poolLength = json.poolLength
    const poolLengthUnit = json.poolUnit
    const events = json.events
    const samplesAndEvents = events.filter(event => event.event !== 'MSPSnapshot')
    const distanceSwimmingCount = events.filter(event => event.type === 'distanceSwimming').length
    const distanceSwimmingFilteredCount = events.filter(event => event.type === 'distanceSwimming' && event.filtered).length
    const swimmingStrokeCount = events.filter(event => event.type === 'swimmingStrokeCount').length
    const lapCount = events.filter(event => event.type === 'lap').length
    const lapFilteredCount = events.filter(event => event.type === 'lap' && event.filtered).length
    const segmentCount = events.filter(event => event.type === 'segment').length

    let output = 'Overall:\n'
    output += distanceSwimmingCount
    output += ' * '
    output += poolLength
    output += ' = '
    output += (poolLength * distanceSwimmingCount)
    output += ' '
    output += poolLengthUnit
    output += '\n\n'

    output += distanceSwimmingCount
    output += ' distance samples '
    output += distanceSwimmingFilteredCount
    output += ' filtered\n'
    output += swimmingStrokeCount
    output += ' stroke count samples\n'
    output += lapCount
    output += ' lap events '
    output += lapFilteredCount
    output += ' filtered\n'
    output += segmentCount
    output += ' segment events\n'

    output += '\n\n'
    output += 'Raw analysis by Apple segments:\n\n'
    let segmentIndex = 1
    output += 'Segment #'
    output += segmentIndex
    output += '\n'
    samplesAndEvents.forEach(element => {
      if (element.type === 'distanceSwimming') {
        output += 'Distance sample '
        output += Number(element.value).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        output += ' '
        output += poolLengthUnit
        if (element.filtered) {
          output += ' (filtered)'
        }
        output += '\n'
      } else if (element.type === 'swimmingStrokeCount') {
        output += 'Stroke count sample '
        output += element.value
        output += '\n'
      } else if (element.type === 'lap') {
        output += 'Lap event '
        output += element.strokeType
        output += ' '
        output += Number(element.duration).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        output += ' sec'
        if (element.filtered) {
          output += ' (filtered)'
        }
        output += '\n'
      } else if (element.type === 'segment') {
        segmentIndex += 1
        output += '\nSegment #'
        output += segmentIndex
        output += '\n'
      }
    })
    return output
  }

  render = () => {
    return (
        <div className="container">
            <br/>
            <p>{this.state.isProcessingFile ? 'Processing...' : 'Apple Watch session file (json):'}</p>
            <input disabled={this.state.isProcessingFile} type="file" onChange={(event) => { this.readFile(event) }} id="file_upload"/>
            <br/><br/>
            <div className="bg-light">
                <pre><code>{this.state.output}</code></pre>
            </div>
        </div>
    )
  }
}
