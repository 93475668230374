import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'

export default class Challenge extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        challenge: null,
        leaderboard: [],
        isEditing: false
      }
      this.loadContent()
    } else {
      // When `id` is "new", we are intending to make a new Challenge object.
      // This makes a new one and sets the mode to editing right away
      this.state = {
        challenge: null,
        leaderboard: [],
        isEditing: true
      }
    }

    this.loadContent = this.loadContent.bind(this)
    this.iconInput = React.createRef()
    this.imageInput = React.createRef()
  }

  loadContent = () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      alert('Error: No challenge id. URL format: https://admin.myswimpro.com/#/challenges/id')
      return
    }

    const challengeType = new Parse.Object('Challenge')
    const query = new Parse.Query(challengeType)
    query.get(id).then((results) => {
      const content = results
      if (!content) {
        alert('No challenge found for id ' + id)
        return
      }
      const start = content.get('startDate')
      const end = content.get('endDate')
      this.setState({
        challenge: content,
        tempName: content.get('name'),
        tempDescription: content.get('description'),
        tempStartDate: start,
        tempEndDate: end,
        tempGoal: content.get('goal'),
        tempMetricType: content.get('metricType'),
        tempMetricUnit: content.get('metricUnit'),
        tempModes: content.get('mode') || []
      })
      this.loadLoaderboard()
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  loadLoaderboard = () => {
    if (!this.state.challenge || !this.state.challenge.id) {
      return
    }
    this.getChallengeLeaderboard().then((results) => {
      this.setState({
        leaderboard: results
      })
    })
  }

  getChallengeLeaderboard = async () => {
    const allParticipantsToSort = await this.getLeaderboard()

    // Sort users by their progress in the given challenge
    allParticipantsToSort.sort((a, b) => {
      return a[0] < b[0] ? 1 : b[0] < a[0] ? -1 : 0
    })

    const sortedParticipants = []
    for (let i = 0; i < allParticipantsToSort.length; i++) {
      const participantProgress = allParticipantsToSort[i][1]
      sortedParticipants.push(participantProgress)
    }

    return sortedParticipants
  }

  getLeaderboard = async () => {
    const challenge = this.state.challenge

    const gatherParticipants = async function (startingRow, batchSize, participantsArray) {
      const leaderboardQuery = new Parse.Query('ChallengeProgress')
        .include('challenge')
        .equalTo('challenge', challenge)
        .limit(batchSize)
        .skip(startingRow)

      const challengeProgresses = await leaderboardQuery.find()

      for (const challengeProgress of challengeProgresses) {
        const progressToSort = [challengeProgress.get('progress'), challengeProgress]
        participantsArray.push(progressToSort)
      }
      if (challengeProgresses.length === batchSize) return gatherParticipants(startingRow + batchSize, batchSize, participantsArray)
      return participantsArray
    }
    const startingRow = 0
    const batchSize = 1000
    return await gatherParticipants(startingRow, batchSize, [])
  }

  formatDateForLocalTime = (date) => {
    const output = this.getUTCDate(date)
    return output.toLocaleString()
  }

  getUTCDate = (date, flip) => {
    if (!date) return
    const output = new Date(date)
    const adjust = flip ? -1 : 1
    output.setTime(output.getTime() + (output.getTimezoneOffset() * 60 * 1000 * adjust))
    return output
  }

  handleDeleteContentClicked = (event) => {
    event.preventDefault()
    if (!window.confirm("Are you sure you'd like to delete this item? You cannot undo this action.")) {
      return
    }
    const content = this.state.challenge
    content.destroy().then(() => {
      window.location.href = '/#/challenges'
      window.location.reload()
    })
  }

  handleContentURLChange = (event) => {
    this.setState({
      tempContentURL: event.target.value
    })
  }

  handleNameChange = (event) => {
    this.setState({
      tempName: event.target.value
    })
  }

  handleDescriptionChange = (event) => {
    this.setState({
      tempDescription: event.target.value
    })
  }

  handleSaveButtonClick = (event) => {
    event.preventDefault()

    const startDate = this.state.tempStartDate
    if (!startDate) {
      alert("Couldn't save: missing start date")
      return
    }

    const endDate = this.state.tempEndDate
    if (!endDate) {
      alert("Couldn't save: missing end date")
      return
    }

    const title = (this.state.tempName || '').trim()
    if (title.length === 0) {
      alert("Couldn't save: missing name")
      return
    }

    const description = (this.state.tempDescription || '').trim()
    if (description.length === 0) {
      alert("Couldn't save: missing description")
      return
    }

    const metricType = this.state.tempMetricType
    if (!metricType) {
      alert("Couldn't save: missing goal type")
      return
    }

    const goal = this.state.tempGoal
    if (!goal) {
      alert("Couldn't save: missing goal")
      return
    }

    const metricUnit = this.state.tempMetricUnit
    if (!metricUnit) {
      alert("Couldn't save: missing metric unit")
      return
    }

    const modes = this.state.tempModes
    if (!modes || modes.length === 0) {
      alert("Couldn't save: missing modes")
      return
    }

    const iconRequirements = (img) => {
      if (img.width > 300 || img.height > 300) {
        window.alert('This icon is too big! Max allowed size is 300x300')
        return false
      } else if (img.height != img.width) {
        window.alert('Icon must be square')
        return false
      }
      return true
    }

    const imgRequirements = (img) => {
      if (img.width > 1000 || img.height > 500) {
        alert('This image is too big! Max allowed size is 1000x500')
        return false
      } else if (img.height > img.width) {
        alert('Image width should be greater than image height')
        return false
      }
      return true
    }

    let iconURL = this.state.tempIconURL
    this.saveImage(this.iconInput, iconRequirements).then((url) => {
      if (url) {
        iconURL = url
      }
      return this.saveImage(this.imageInput, imgRequirements)
    }).then((url) => {
      const imageURL = url || this.state.tempImageURL
      this.saveChallenge(startDate, endDate, title, description, metricType, goal, metricUnit, iconURL, imageURL, this.state.challenge, modes)
    }, (error) => {
      window.alert('There was an error saving the image. Please make sure image file names are unique. ' + error.message)
    })
  }

  saveImage = (fileInput, requirements) => {
    if (!fileInput.current.files[0]) {
      console.log('No image to save.')
      return new Promise((resolve, reject) => { resolve() })
    }

    const file = fileInput.current.files[0]
    console.log('file is: ' + file.name)
    const img = new Image()

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        if (requirements && !requirements(img)) {
          reject(new Error('Image fails requirements'))
          return
        }

        // checks passed, upload image
        const parseImage = new Parse.File(file.name, file)
        parseImage.save().then((fileSuccess, fileError) => {
          if (!fileSuccess) {
            console.log('Error saving Image. ' + fileError)
            reject(fileError)
            return
          }

          console.log('Successfully saved image:' + parseImage.url())
          resolve(parseImage.url())
        }, (error) => {
          console.log('Error saving Image. ' + error)
          reject(error)
        })
      }
    })

    img.src = window.URL.createObjectURL(file)
    return promise
  }

  saveChallenge = (startDate, endDate, name, description, metricType, goal, metricUnit, iconURL, imageURL, existingContent, mode) => {
    const challenge = existingContent || new Parse.Object('Challenge')
    challenge.set('startDate', startDate)
    challenge.set('endDate', endDate)
    challenge.set('name', name)
    challenge.set('description', description)
    challenge.set('metricType', metricType)
    challenge.set('goal', goal)
    challenge.set('metricUnit', metricUnit)
    challenge.set('iconUrl', iconURL)
    challenge.set('imageUrl', imageURL)
    challenge.set('mode', mode)
    challenge.save().then(() => {
      console.log('succesfully saved challenge. id:' + challenge.id)

      this.setState({
        challenge: challenge
      }, this.reload)
    })
  }

  reload = () => {
    if (this.state.id) {
      window.location.reload()
    } else if (this.state.challenge) {
      window.location.href = '/#/challenges/' + this.state.challenge.id
      window.location.reload()
    } else {
      window.location.href = '/#/challenges'
      window.location.reload()
    }
  }

  handleCancelButtonClick = (event) => {
    this.setState({
      isAdding: false
    })
  }

  handleNewButtonClick = (event) => {
    this.setState({
      isAdding: true
    })
  }

  handleStartDateChange = (date) => {
    const newDate = new Date(date)
    console.log(newDate)
    newDate.setHours(0, 0, 0)
    this.setState({
      tempStartDate: this.getUTCDate(newDate, true)
    })
  }

  handleEndDateChange = (date) => {
    const newDate = new Date(date)
    newDate.setHours(23, 59, 59)
    this.setState({
      tempEndDate: this.getUTCDate(newDate, true)
    })
  }

  handleGoalChange = (event) => {
    const value = Number(event.target.value)
    if (isNaN(value)) {
      event.preventDefault()
      return
    }
    this.setState({
      tempGoal: value
    })
  }

  handleModesChange = (event) => {
    const value = Number(event.target.value)
    const modes = this.state.tempModes || []
    if (modes.includes(value)) {
      // remove it from the array
      modes.splice(modes.indexOf(value), 1)
    } else {
      modes.push(value)
    }
    this.setState({
      tempModes: modes
    })
  }

  handleMetricTypeChange = (event) => {
    const value = event.target.value
    let unit
    if (value === 'totalTime') {
      unit = 'second'
    } else if (value === 'totalWorkoutsCompleted') {
      unit = 'count'
    } else if (value === 'totalDistance') {
      unit = 'meter'
    }
    this.setState({
      tempMetricType: value,
      tempMetricUnit: unit
    })
  }

  handleEdit = () => {
    this.setState({
      isEditing: true
    })
  }

  modeString = (modeValue) => {
    switch (modeValue) {
      case 0:
        return 'All workout types'
      case 1:
        return 'Pool swims'
      case 2:
        return 'Open water swims'
      case 3:
        return 'Dryland workouts'
      case 4:
        return 'Mixed'
      case 5:
        return 'Open Pool'
      case 6:
        return 'Guided Pool'
      default:
        return 'All workout types'
    }
  }

  modeStringFromArray = (modeValues) => {
    return modeValues.map((value) => { return this.modeString(value) }).join(', ')
  }

  convertToCSV = async (leaderboard) => {
    const promises = []
    for (let i = 0; i < leaderboard.length; i++) {
      const obj = leaderboard[i]
      const query = new Parse.Query('_User')
      promises.push(query.get(obj.get('userId')))
    }
    const queryResults = await Promise.all(promises)
    for (let j = 0; j < queryResults.length; j++) {
      leaderboard[j].set('email', queryResults[j].get('email'))
    }

    const units = this.state.challenge.get('metricUnit') === 'second' ? 'minutes' : this.state.challenge.get('metricUnit')
    const header = 'firstName, lastName, email, progress (' + units + '), didComplete, isPrivate, isHidden\n'
    const data = leaderboard.map((object) => {
      const firstName = object.get('firstName') || ''
      const lastName = object.get('lastName') || ''
      const email = object.get('email') || ''
      const progress = this.state.challenge.get('metricUnit') === 'second' ? (object.get('progress') / 60) | 0 : object.get('progress')
      const didComplete = object.get('progress') >= this.state.challenge.get('goal') ? 'true' : 'false'
      const isPrivate = object.get('isPrivate') ? 'true' : 'false'
      const isHidden = object.get('isHidden') ? 'true' : 'false'

      return firstName +
            ',' + lastName +
            ',' + email +
            ',' + progress +
            ',' + didComplete +
            ',' + isPrivate +
            ',' + isHidden
    }).join('\n')
    return header + data
  }

  handleDownload = (button) => {
    this.setState({ isDownloadingCSV: true })
    const element = document.createElement('a')
    this.convertToCSV(this.state.leaderboard).then((data) => {
      const file = new Blob([data], { type: 'text/csv' })
      element.href = URL.createObjectURL(file)
      element.download = this.state.challenge.get('name') + ' emails.csv'
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
      this.setState({ isDownloadingCSV: false })
    })
  }

  renderEditView = () => {
    return (
            <div className="container">
                <br/>
                <form id="addcontent-form">
                    <div className="form-group">
                        <label htmlFor="title"><b>Name</b></label>
                        <input type="text" className="form-control" id="title" placeholder="title" value={this.state.tempName} onChange={this.handleNameChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description"><b>Description</b></label>
                        <input type="text" className="form-control" id="description" placeholder="description" value={this.state.tempDescription} onChange={this.handleDescriptionChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="date"><b>Start Date</b> <span className="text-muted">Local time.</span><br/>
                        <small className="text-muted">Time will be adjusted to 00:00</small>
                        </label><br/>
                        <DateTimePicker showTimezone="true" id="date" onChange={this.handleStartDateChange} value={this.getUTCDate(this.state.tempStartDate)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="date"><b>End Date</b> <span className="text-muted">Local time.</span><br/>
                        <small className="text-muted">Time will be adjusted to 23:59:59</small>
                        </label><br/>
                        <DateTimePicker id="date" onChange={this.handleEndDateChange} value={this.getUTCDate(this.state.tempEndDate)} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="description"><b>Modes</b> <span className="text-muted">Types of workouts that will count toward this challenge.</span></label>
                        <br/>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" checked={(this.state.tempModes || []).includes(2)} onChange={this.handleModesChange}/>
                            <label className="form-check-label" htmlFor="inlineCheckbox2">{this.modeString(2)}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="3" checked={(this.state.tempModes || []).includes(3)} onChange={this.handleModesChange}/>
                            <label className="form-check-label" htmlFor="inlineCheckbox3">{this.modeString(3)}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox4" value="5" checked={(this.state.tempModes || []).includes(5)} onChange={this.handleModesChange}/>
                            <label className="form-check-label" htmlFor="inlineCheckbox5">{this.modeString(5)}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox5" value="6" checked={(this.state.tempModes || []).includes(6)} onChange={this.handleModesChange}/>
                            <label className="form-check-label" htmlFor="inlineCheckbox6">{this.modeString(6)}</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="content_url"><b>Goal Type</b></label>
                        <select className="custom-select" value={this.state.tempMetricType} onChange={this.handleMetricTypeChange}>
                            <option selected>--</option>
                            <option value="totalTime">totalTime</option>
                            <option value="totalWorkoutsCompleted">totalWorkoutsCompleted</option>
                            <option value="totalDistance">totalDistance</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="description"><b>Goal</b> <i>({this.state.tempMetricUnit || ''})</i></label>
                        <input type="text" className="form-control" id="description" placeholder="description" value={this.state.tempGoal} onChange={this.handleGoalChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="file_upload"><b>Icon Image</b></label>
                        <input type="file" ref={this.iconInput} id="file_upload"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="file_upload"><b>Main Image</b></label>
                        <input type="file" ref={this.imageInput} id="file_upload"/>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={this.handleSaveButtonClick}>Save</button>
                    &nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.handleCancelButtonClick}>Cancel</button>
                </form>
            </div>
    )
  }

  renderReadonlyView = () => {
    const challenge = this.state.challenge
    if (!challenge) {
      return (<div>Loading...</div>)
    }

    return (
            <div className="container">
                <br/>
                <div className="row">
                    <a href={challenge.get('imageUrl')}>
                        <img src={challenge.get('imageUrl')} alt={challenge.get('imageUrl')}/>
                    </a>
                    <a href={challenge.get('iconUrl')}>
                        <img src={challenge.get('iconUrl')} alt={challenge.get('iconUrl')}/>
                    </a>
                </div>
                <br/>
                <div className="row">
                    <div className="col">
                        <h1>{challenge.get('name')}</h1>
                    </div>
                    <div className="col">
                        { this.state.isEditing ? <button className="btn btn-success" onClick={this.handleSaveButtonClick} disabled={this.state.saving}>Save</button> : <button className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>}
                    </div>
                </div>
                <div className="row">
                    <p className="text-muted col">{challenge.get('description')}</p>
                </div>
                <div className="row">
                    <p className="col">
                        <b>Start Date</b>
                        <br/>
                        <span className="text-muted">{this.formatDateForLocalTime(challenge.get('startDate'))}</span>
                    </p>
                </div>
                <div className="row">
                    <p className="col">
                        <b>End Date</b>
                        <br/>
                        <span className="text-muted">{this.formatDateForLocalTime(challenge.get('endDate'))}</span>
                    </p>
                </div>
                <div className="row">
                    <p className="col">
                        <b>Modes</b>
                        <br/>
                        <span>
                            {this.modeStringFromArray(challenge.get('mode'))}
                        </span>
                    </p>
                </div>
                <div className="row">
                    <p className="col">
                        <b>Goal</b>
                        <br/>
                        <span>
                            <code>{challenge.get('metricType')}</code>: {challenge.get('goal')} {challenge.get('metricUnit')} <small className="text-muted">{challenge.get('metricUnit') === 'second' ? '(' + ((challenge.get('goal') / 60) | 0) + ' minutes)' : ''}</small>
                        </span>
                    </p>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-danger" onClick={this.handleDeleteContentClicked}>
                            Delete
                        </button>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col">
                        <h2>Leaderboard <small className="text-muted">{this.state.leaderboard.length} members</small> <button className="btn btn-sm btn-secondary" onClick={this.handleDownload} disabled={this.state.isDownloadingCSV}>{this.state.isDownloadingCSV ? 'Loading...' : 'Download CSV'}</button></h2>

                        {this.renderTableHeader()}
                        {this.renderLeaderboard()}
                    </div>
                </div>
            </div>
    )
  }

  renderTableHeader () {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>Rank</th>
                <th className="bg-primary" key={'1h'}>Name</th>
                <th className="bg-primary" key={'2h'}>Progress ({this.state.leaderboard.filter(obj => obj.get('progress') >= this.state.challenge.get('goal')).length})</th>
            </tr>
    )
  }

  isRanked = (challengeProgress) => {
    return !challengeProgress.get('isPrivate') && challengeProgress.get('firstName') && challengeProgress.get('lastName') && !challengeProgress.get('isHidden')
  }

  handleToggleHidden = (challengeProgress) => {
    const isHidden = challengeProgress.get('isHidden')
    const action = isHidden ? 'Are you sure you want to unhide this member?' : 'Are you sure you want to hide this member?'
    const confirm = window.confirm(action)
    if (!confirm) return

    challengeProgress.set('isHidden', !isHidden)
    challengeProgress.save().then(() => {
      window.location.reload()
    })
  }

  renderLeaderboard = () => {
    if (this.state.leaderboard.length === 0) {
      return (<div>Loading...</div>)
    }
    const rendered = []

    let rank = 0
    for (let i = 0; i < this.state.leaderboard.length; i++) {
      const object = this.state.leaderboard[i]
      const isRanked = this.isRanked(object)
      const isHidden = object.get('isHidden')
      if (isRanked) rank += 1
      rendered.push(
                <tr className="data">
                    <td>
                        <b>
                            { isRanked ? rank + '.' : '👻'}
                        </b>
                    </td>
                     <td>
                        <a href={'/#/members/' + object.get('userId')}>
                            <span className={isRanked ? '' : 'text-muted'}>
                                {object.get('firstName') || '-'} {object.get('lastName') || '-'}
                            </span>
                        </a>
                     </td>
                     <td>
                        <span className={isRanked ? '' : 'text-muted'}>
                            {this.state.challenge.get('metricUnit') === 'second' ? (object.get('progress') / 60) | 0 : object.get('progress')} {this.state.challenge.get('metricUnit') === 'second' ? 'minutes' : this.state.challenge.get('metricUnit')} {object.get('progress') >= this.state.challenge.get('goal') ? '✅' : ''}
                        </span>
                     </td>
                     <td>
                        <button className="btn btn-sm btn-outline-secondary" onClick={() => this.handleToggleHidden(object)}>
                            {isHidden ? 'Unhide' : 'Hide'}
                        </button>
                    </td>
                </tr>
      )
    }
    return rendered
  }

  render = () => {
    return this.state.isEditing ? this.renderEditView() : this.renderReadonlyView()
  }
}
