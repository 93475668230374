import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import TrainingPlanEntryRow from './TrainingPlanEntryRowComponent'

export default class TrainingPlanWeek extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      week: props.week,
      trainingPlan: props.trainingPlan,
      weekIndex: props.weekIndex,
      addWorkoutClicked: props.addWorkoutClicked,
      onCommentChange: props.onCommentChange,
      removeWeekClicked: props.removeWeekClicked,
      removeWorkout: props.removeWorkout,
      isAdmin: props.isAdmin
    }
  }

  removeWeekClicked = (event) => {
    event.preventDefault()
    console.log('remove week clicked, weekindex:' + this.state.weekIndex)
    this.state.removeWeekClicked(this.state.weekIndex)
  }

  handleNewWorkout = async (event) => {
    event.preventDefault()
    await this.state.addWorkoutClicked(this.state.weekIndex, 0)
    this.setState({
      week: this.state.week
    })
  }

  render = () => {
    const week = this.state.week

    return (
      <div className="row">
        <div>
          <br></br>
          <h2>Week {this.state.weekIndex + 1} <button className="btn btn-sm btn-outline-danger float-right" onClick={this.removeWeekClicked}>Delete Week {this.state.weekIndex + 1}</button></h2>
          <hr />
          <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr key={'0-header'} className="data">
                    <th className="bg-primary" key={'0h'} scope="col">Details</th>
                    <th className="bg-primary" key={'1h'} scope="col">Workout</th>
                    <th className="bg-primary" key={'2h'} scope="col">LCM Workout (Deprecated)</th>
                    <th className="bg-primary" key={'3h'} scope="col">Status</th>
                    <th className="bg-primary" key={'4h'} scope="col">
                      <button className="btn btn-sm btn-secondary" hidden={(this.state.week.get('entries') || []).length} onClick={this.handleNewWorkout}>+Entry</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {week
                    ? week.get('entries').map((entry, index) => {
                      return <TrainingPlanEntryRow
                        key={index}
                        trainingPlanEntry={entry}
                        trainingPlanWeek={this.state.week}
                        trainingPlan={this.state.trainingPlan}
                        weekIndex={this.state.weekIndex}
                        entryIndex={index}
                        onCommentChange={this.state.onCommentChange}
                        removeWorkout={this.state.removeWorkout}
                        addWorkoutClicked={this.state.addWorkoutClicked}
                        isAdmin={this.state.isAdmin} />
                    })
                    : null}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    )
  }
}
