import React from 'react'
import Parse from 'parse'

export default class GenPlan extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      props: props,
      userFirstName: null,
      endDate: null,
      goal: null,
      numWorkoutsPerWeek: 2,
      avgWorkoutDistance: 2000,
      goalEventDistanceMeters: null,
      goalEventDistanceIsDisplayedInYards: true,
      goalEventStroke: null,
      isGoalEventOpenWater: false,
      skillLevel: 0,
      userId: null,
      model: null,
      prompt: null,
      plan: null,
      isGenerating: false,
      latency: null
    }
  }

  strokeNames = ['free', 'back', 'breast', 'fly', 'IM', 'kick', 'pull', 'drill', 'choice', 'FrIM']
  equipmentOptions = ['paddles', 'fins', 'snorkel', 'pull_buoy', 'kickboard']

  renderHeader = () => {
    return (
      <div className='container'>
        <h1>Generate Plan</h1>
        <form>
          <div className="row">
            <div className="col-auto mb-3">
              <label htmlFor="endDate" className="form-label font-weight-bold">End Date</label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                value={this.state.endDate?.toISOString().substring(0, 10)}
                onChange={e => { this.setState({ endDate: new Date(e.target.value) }) }} />
            </div>
            <div className="col-auto mb-3">
              <label htmlFor="goal" className="form-label font-weight-bold">Goal</label>
              <input
                type="text"
                className="form-control"
                id="goal"
                name="goal"
                placeholder="Goal"
                value={this.state.goal}
                onChange={e => { this.setState({ goal: e.target.value }) }} />
            </div>
            <div className="col-auto mb-3">
              <label htmlFor="numWorkoutsPerWeek" className="form-label font-weight-bold">Workouts per Week</label>
              <input
                type="number"
                className="form-control"
                id="numWorkoutsPerWeek"
                name="numWorkoutsPerWeek"
                min="1"
                max="14"
                placeholder="Workouts per Week"
                value={this.state.numWorkoutsPerWeek}
                onChange={e => { this.setState({ numWorkoutsPerWeek: e.target.value }) }} />
            </div>
            <div className="col-auto mb-3">
              <label htmlFor="avgWorkoutDistance" className="form-label font-weight-bold">Avg Workout Distance (meters)</label>
              <input
                type="number"
                className="form-control"
                id="avgWorkoutDistance"
                name="avgWorkoutDistance"
                min="100"
                step="100"
                placeholder="Avg Workout Distance"
                value={this.state.avgWorkoutDistance}
                onChange={e => { this.setState({ avgWorkoutDistance: e.target.value }) }} />
            </div>
          </div>
          <div className="row">
            <div className="col-auto mb-3">
              <label htmlFor="userFirstName" className="form-label font-weight-bold">User First Name</label>
              <input
                type="text"
                className="form-control"
                id="userFirstName"
                name="userFirstName"
                placeholder="First Name"
                value={this.state.userFirstName}
                onChange={e => { this.setState({ userFirstName: e.target.value }) }} />
            </div>
            <div className="col-auto mb-3">
              <label htmlFor="userId" className="form-label font-weight-bold">User ID</label>
              <input
                type="text"
                className="form-control"
                id="userId"
                name="userId"
                placeholder="User ID"
                value={this.state.userId}
                onChange={e => { this.setState({ userId: e.target.value }) }} />
            </div>
            <div className="col-auto mb-3">
              <label htmlFor="skillLevel" className="form-label font-weight-bold">Skill Level</label>
              <input
                type="number"
                className="form-control"
                id="skillLevel"
                name="skillLevel"
                min="0"
                max="5"
                placeholder="Skill Level"
                value={this.state.skillLevel}
                onChange={e => { this.setState({ skillLevel: e.target.value }) }} />
            </div>
            <div className="col-auto mb-3">
              <label htmlFor="model" className="form-label font-weight-bold">Model</label>
              <input
                type="text"
                className="form-control"
                id="model"
                name="model"
                placeholder="Default"
                value={this.state.model}
                onChange={e => { this.setState({ model: e.target.value }) }} />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-auto mb-3">
              <label htmlFor="goalEventDistanceMeters" className="form-label font-weight-bold">Goal Event Distance (meters)</label>
              <input
                type="number"
                className="form-control"
                id="goalEventDistanceMeters"
                name="goalEventDistanceMeters"
                placeholder="Goal Event Distance"
                step="25"
                value={this.state.goalEventDistanceMeters}
                onChange={e => { this.setState({ goalEventDistanceMeters: e.target.value }) }} />
            </div>
            <div className="col-auto form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="goalEventDistanceIsDisplayedInYards"
                name="goalEventDistanceIsDisplayedInYards"
                checked={this.state.goalEventDistanceIsDisplayedInYards}
                onChange={e => { this.setState({ goalEventDistanceIsDisplayedInYards: e.target.checked }) }} />
              <label className="form-check-label" htmlFor="goalEventDistanceIsDisplayedInYards">Display Distance in Yards</label>
            </div>
            <div className="col-auto mb-3">
                <div className="input-group mb-3">
                  <label className="input-group-text" htmlFor="goalEventStroke">Goal Event Stroke</label>
                  <select
                    className="form-select"
                    id="goalEventStroke"
                    value={this.state.goalEventStroke}
                    onChange={e => { this.setState({ goalEventStroke: e.target.value }) }}>
                      <option key={-1} value={null}>None</option>
                    {this.strokeNames.map((stroke, index) => {
                      return <option key={index} value={stroke}>{stroke}</option>
                    })}
                  </select>
                </div>
            </div>
            <div className="col-auto form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="isGoalEventOpenWater"
                name="isGoalEventOpenWater"
                checked={this.state.isGoalEventOpenWater}
                onChange={e => { this.setState({ isGoalEventOpenWater: e.target.checked }) }} />
              <label className="form-check-label" htmlFor="isGoalEventOpenWater">Open Water Event</label>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="prompt" className="form-label font-weight-bold">Prompt</label>
            <textarea
              className="form-control"
              id="prompt"
              name="prompt"
              placeholder="Prompt"
              rows="3"
              value={this.state.prompt}
              onChange={e => { this.setState({ prompt: e.target.value }) }}></textarea>
          </div>
          <button type="submit" className="btn btn-primary" disabled={this.state.isGenerating} onClick={this.generatePlan}>Generate</button>
        </form>
      </div>
    )
  }

  renderPlanContainer = () => {
    return (
      <div className='container'>
        {this.state.isGenerating && <p className="placeholder-glow"><span className="placeholder col-12"></span></p>}
        {this.state.plan && this.renderPlan()}
      </div>
    )
  }

  renderPlan = () => {
    return (<div>
      <h2>Generated Plan</h2>
      <p>Took {this.state.latency / 1000} seconds</p>
      <pre>
        <code>
          {JSON.stringify(this.state.plan, null, 2)}
        </code>
      </pre>
    </div>)
  }

  generatePlan = async () => {
    this.setState({ isGenerating: true })
    const start = new Date()
    Parse.Cloud.run('generatePlanRaw', this.state).then((result) => {
      const end = new Date()
      this.setState({ plan: result, isGenerating: false, latency: (end.getTime() - start.getTime()) })
    }, (error) => {
      console.error(error.message)
      window.alert('Error generating plan: ' + error.message)
      this.setState({ plan: error.message, isGenerating: false })
    })
  }

  render = () => {
    return (
      <div>
        {this.renderHeader()}
        <hr />
        {this.renderPlanContainer()}
      </div>
    )
  }
}
