import React from 'react'
import Parse from 'parse'
import 'bootstrap/dist/css/bootstrap.min.css'

export default class ResetPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: ''
    }
  }

  resetPassword = (email) => {
    Parse.User.requestPasswordReset(email).then(() => {
      window.alert('Password reset link has been sent to ' + email)
      this.setState({
        email: ''
      })
    }, function (error) {
      window.alert(error.message)
    })
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  handleSubmitResetPassword = (event) => {
    console.log('Sending password reset link for email: ' + this.state.email)
    this.resetPassword(this.state.email)
  }

  render = () => {
    return (
            <div className="container">
              <br/>
              <div className="row justify-content-center">
                <div className="col-sm-6">
                  <div className="card">
                      <h5 className="card-header">Password Reset</h5>
                      <br/>
                      <div className="card-body">
                        <input type="text" className="form-control" id="reset_email" placeholder="email" value={this.state.email} onChange={this.handleEmailChange}/>
                        <br/>
                        <button type="button" className="btn btn-primary" onClick={this.handleSubmitResetPassword}>Send Password Reset</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
    )
  }
}
