import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'

export default class ReleaseNotesDetailComponent extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        releaseNotes: null,
        isEditing: false
      }
      this.loadContent()
    } else {
      // When `id` is "new", we are intending to make a new object.
      // This makes a new one and sets the mode to editing right away
      this.state = {
        releaseNotes: new Parse.Object('ReleaseNotes'),
        isEditing: true
      }
    }

    this.loadContent = this.loadContent.bind(this)
  }

  loadContent = () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      alert('Error: No challenge id. URL format: https://admin.myswimpro.com/#/releasenotes/id')
      return
    }

    const challengeType = new Parse.Object('ReleaseNotes')
    const query = new Parse.Query(challengeType)
    query.get(id).then((results) => {
      this.setState({
        releaseNotes: results
      })
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  handleEdit = (event) => {
    event.preventDefault()
    this.setState({
      isEditing: true
    })
  }

  handleAddNote = (event) => {
    event.preventDefault()
    const notes = this.state.releaseNotes.get('notes') || []
    notes.push({ comment: '' })
    this.state.releaseNotes.set('notes', notes)
    this.setState({
      releaseNotes: this.state.releaseNotes
    })
  }

  handleSave = async (event) => {
    event.preventDefault()
    this.state.releaseNotes.save().then((result) => {
      window.location.href = '/#/releasenotes/' + result.id
      window.location.reload()
    }, (error) => {
      window.alert(error.message)
    })
  }

  deleteNote = (index) => {
    const notes = this.state.releaseNotes.get('notes') || []
    if (!notes.length) return
    notes.splice(index, 1)
    this.state.releaseNotes.set('notes', notes)
    this.setState({
      releaseNotes: this.state.releaseNotes
    })
  }

  updateNote = (index, value) => {
    console.log(value)
    this.state.releaseNotes.get('notes')[index].comment = value
    this.setState({
      releaseNotes: this.state.releaseNotes
    })
  }

  handleDelete = () => {
    const ok = confirm('Are you sure you want to delete ' + this.state.releaseNotes.get('platform') + ' release ' + this.state.releaseNotes.get('version') + '?')
    if (!ok) return
    this.state.releaseNotes.destroy().then(() => {
      window.location.href = '/#/releasenotes/'
      window.location.reload()
    }, (error) => {
      alert(error.message)
    })
  }

  handleAbortRelease = async () => {
    this.state.releaseNotes.set('status', 'RELEASE_ABORTED')
    await this.state.releaseNotes.save()
    this.setState({
      releaseNotes: this.state.releaseNotes
    })
  }

  handleHaltRollout = async () => {
    this.state.releaseNotes.set('status', 'ROLLOUT_HALTED')
    await this.state.releaseNotes.save()
    this.setState({
      releaseNotes: this.state.releaseNotes
    })
  }

  handleRolloutComplete = async () => {
    const confirmed = window.confirm('Are you sure you want to mark as ROLLOUT_COMPLETE? Only select OK if you rolled it out manually already.')
    if (!confirmed) return

    this.state.releaseNotes.set('status', 'ROLLOUT_COMPLETE')
    await this.state.releaseNotes.save()
    this.setState({
      releaseNotes: this.state.releaseNotes
    })
  }

  renderEditView = () => {
    const releaseNotes = this.state.releaseNotes
    const notes = (releaseNotes.get('notes') || [])
    if (!releaseNotes) return (<div>Loading...</div>)
    return (<div>
      <form>
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1">Platform</label>
          <select className="form-control" id="exampleFormControlSelect1" value={releaseNotes.get('platform')} onChange={e => { e.preventDefault(); this.state.releaseNotes.set('platform', e.target.value) }}>
            <option value="undefined">--</option>
            <option value="android">Android</option>
            <option value="ios">iOS</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Version</label>
          <input type="text" className="form-control" id="exampleInputEmail1" value={releaseNotes.get('version')} onChange={e => { e.preventDefault(); this.state.releaseNotes.set('version', e.target.value) }}/>
          <small className="form-text text-muted">Version number without build number. Ex: <code>7.3.2</code></small>
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">Notes</label>
          {
            notes.map((note, index) => {
              return (
                <div key={index}>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" value={note.comment} onChange={e => { e.preventDefault(); this.updateNote(index, e.target.value) }}></textarea>
                  <small hidden={!notes.length} className="form-text"><a href="#" className="text-danger" onClick={event => { event.preventDefault(); this.deleteNote(index) }}>Delete Note</a></small>
                  <br/>
                </div>
              )
            })
          }
          <br/><a href="#" onClick={this.handleAddNote}><button className="btn btn-sm btn-outline-primary">+ Note</button></a>
        </div>
        <br/>
        <button onClick={this.handleSave} type="submit" className="btn btn-primary">Save</button>
      </form>
    </div>)
  }

  renderStatus = (status) => {
    switch (status) {
      case 'ALPHA':
        return (<span className="badge badge-secondary">{ status }</span>)
      case 'BETA':
        return (<span className="badge badge-info">{ status }</span>)
      case 'SUBMITTED':
        return (<span className="badge badge-primary">{ status }</span>)
      case 'RELEASE_ABORTED':
      case 'ROLLOUT_HALTED':
        return (<span className="badge badge-danger">{ status }</span>)
      case 'ROLLOUT_COMPLETE':
        return (<span className="badge badge-success">{ status }</span>)
      default:
        return (<span className="badge badge-light">{ status }</span>)
    }
  }

  renderReadOnlyView = () => {
    const releaseNotes = this.state.releaseNotes
    if (!releaseNotes) return (<div>Loading...</div>)

    return (<div>
      <div className="row align-content-between">
        <div className="col">
          <h1>{releaseNotes.get('platform')} {releaseNotes.get('version')}</h1>
          <h4>{ this.renderStatus(releaseNotes.get('status')) }</h4>
          <small className="text-muted">Created { releaseNotes.get('createdAt').toLocaleString() }</small><br/>
          <small className="text-muted">Last updated { releaseNotes.get('updatedAt').toLocaleString() }</small>
        </div>
        <div className="col text-right">
          <button className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>
        </div>
      </div>
      <br/>
      <ul>
        {releaseNotes.get('notes').map((note) => {
          return (<li key={note.comment} className="lead">{note.comment}</li>)
        })}
      </ul>
      <br/>
      <div className="row">
        <div className="col">
          <button className="btn btn-danger" onClick={this.handleDelete}>Delete</button>
        </div>
        <div className="col" hidden={ releaseNotes.get('status') !== 'BETA' && releaseNotes.get('status') !== 'ALPHA' }>
          <button className="btn btn-warning" onClick={this.handleAbortRelease}>Abort Release</button>
        </div>
        <div className="col" hidden={ releaseNotes.get('status') !== 'SUBMITTED' }>
          <button className="btn btn-warning" onClick={this.handleHaltRollout}>Halt Rollout</button>
        </div>
        <div className="col" hidden={ releaseNotes.get('status') !== 'SUBMITTED' }>
          <button className="btn btn-success" onClick={this.handleRolloutComplete}>Rollout Complete</button>
        </div>
      </div>
    </div>)
  }

  render = () => {
    return (<div className="container">
      <br/>
      {this.state.isEditing ? this.renderEditView() : this.renderReadOnlyView()}
    </div>)
  }
}
