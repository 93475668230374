import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class AchievementsList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: [],
      isLoading: true
    }

    this.loadContent = this.loadContent.bind(this)

    this.loadContent()
  }

  loadContent = () => {
    console.log('loadContent')

    const content = new Parse.Object('Achievement')
    const query = new Parse.Query(content)
    query.limit(1000)
    query.descending('createdAt')
    query.find().then((results) => {
      const content = results
      console.log('got result for content')
      const totalCount = content.count
      if (!content) {
        alert('No content found.')
        return
      }

      this.setState({
        content: content,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  renderTable = () => {
    return this.state.content.map((content, index) => {
      return (
               <tr key={index} className="data">
                    <td>
                      <a href={content.get('icon_url_locked')}>
                        <img height="100px" src={content.get('icon_url_locked')} alt={content.get('icon_url_locked')}/>
                      </a>
                    </td>
                    <td>
                      <a href={content.get('icon_url_unlocked')}>
                        <img height="100px" src={content.get('icon_url_unlocked')} alt={content.get('icon_url_unlocked')}/>
                      </a>
                    </td>
                    <td>
                      <a href={'/#/achievements/' + content.id}>
                        <b>{content.get('name')}</b>
                      </a>
                      <br/>
                      {content.get('description')}<br/>
                    </td>
                    <td>
                      {JSON.stringify(content.get('conditions'), null, 2)}
                    </td>
               </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>Locked Image</th>
                <th className="bg-primary" key={'1h'}>Unlocked Image</th>
                <th className="bg-primary" key={'2h'}>Details</th>
                <th className="bg-primary" key={'2h'}>Conditions</th>
            </tr>
    )
  }

  handleNewButtonClick = (event) => {
    window.location.href = '/#/achievements/new'
    window.location.reload()
  }

  render = () => {
    console.log('render content table component')
    return (
        <div>
            <Route path="/achievements">
                <div className="container">
                    <br/>
                    <div>
                        <h1 id="title">Achievements</h1>
                        <div className="row justify-content-end">
                            <div className="col-sm-2">
                                <button className="btn btn-primary" onClick={this.handleNewButtonClick}>
                                    New
                                </button>
                            </div>
                        </div>
                        <br/>
                    </div>
                    <br/>
                    <table id="featuredcontent" className="data">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>
                    { this.state.isLoading &&
                        <div>Loading...</div>
                    }
                </div>
            </Route>
        </div>)
  }
}
