import React from 'react'
import Parse from 'parse'

export default class GenWorkout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      props: props,
      distance: 1000,
      poolLength: 25,
      isYards: true,
      energyLevel: null,
      focus: null,
      strokes: [true, true, true, true, true, true, true, true, true, true],
      equipment: [],
      useHistory: false,
      userId: null,
      model: null,
      prompt: null,
      workout: null,
      isGenerating: false,
      latency: null
    }
  }

  strokeNames = ['freestyle', 'backstroke', 'breaststroke', 'butterfly', 'IM', 'kick', 'pull', 'drill', 'choice', 'FrIM']
  equipmentOptions = ['paddles', 'fins', 'snorkel', 'pull_buoy', 'kickboard']
  energyLevelOptions = ['low-energy', 'average', 'high-energy']
  focusOptions = ['endurance', 'speed', 'technique', 'recovery']

  renderHeader = () => {
    return (
      <div className='container'>
        <h1>Generate Workout</h1>
        <form>
          <div className="row">
            <div className="col-auto mb-3">
              <label htmlFor="distance" className="form-label font-weight-bold">Distance</label>
              <input
                type="number"
                className="form-control"
                id="distance"
                min="100"
                max="99999"
                step="100"
                name="distance"
                placeholder="Distance"
                value={this.state.distance}
                onChange={e => { this.setState({ distance: e.target.value }) }} />
            </div>
            <div className="col-auto row mb-3 align-items-center">
              <div className="col-auto">
                <label htmlFor="poolLength" className="form-label font-weight-bold">Pool Length</label>
                <input type="number"
                  className="form-control"
                  id="poolLength"
                  min="1"
                  max="200"
                  name="poolLength"
                  placeholder="Pool Length"
                  value={this.state.poolLength}
                  onChange={e => { this.setState({ poolLength: e.target.value }) }} />
              </div>
              <div className="form-check col-auto">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isYards"
                  name="isYards"
                  checked={this.state.isYards}
                  onChange={e => { this.setState({ isYards: e.target.value }) }} />
                <label className="form-check-label" htmlFor="isYards">Yards</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto mb-3">
              <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="energyLevel">Energy Level</label>
                <select
                  className="form-select"
                  id="energyLevel"
                  value={this.state.energyLevel}
                  onChange={e => { this.setState({ energyLevel: e.target.value }) }}>
                  <option key={-1} value={null}>None</option>
                  {this.energyLevelOptions.map((energy, index) => {
                    return <option key={index} value={energy}>{energy}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="col-auto mb-3">
            <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="focus">Focus</label>
                <select
                  className="form-select"
                  id="focus"
                  value={this.state.focus}
                  onChange={e => { this.setState({ focus: e.target.value }) }}>
                  <option key={-1} value={null}>None</option>
                  {this.focusOptions.map((energy, index) => {
                    return <option key={index} value={energy}>{energy}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="strokes" className="form-label font-weight-bold">Strokes</label>
            <div className="row">
              {this.state.strokes.map((stroke, index) => {
                return <div className="form-check col-auto" key={index}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={this.strokeNames[index]}
                    name={this.strokeNames[index]}
                    checked={stroke}
                    onChange={e => {
                      const strokes = this.state.strokes
                      strokes[index] = !!e.target.checked
                      this.setState({ strokes })
                    }} />
                  <label className="form-check-label" htmlFor={this.strokeNames[index]}>{this.strokeNames[index]}</label>
                </div>
              })
              }
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="equipment" className="form-label font-weight-bold font-weight-bold">Equipment</label>
            <div className="row">
              {this.equipmentOptions.map((equipment, index) => {
                return <div className="form-check col-auto" key={index}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={this.equipmentOptions[index]}
                    name={this.equipmentOptions[index]}
                    checked={this.state.equipment.includes(equipment)}
                    onChange={e => {
                      const equipment = this.state.equipment || []
                      if (e.target.checked) {
                        equipment.push(this.equipmentOptions[index])
                      } else {
                        equipment.splice(equipment.indexOf(this.equipmentOptions[index]), 1)
                      }
                      this.setState({ equipment })
                    }} />
                  <label className="form-check-label" htmlFor={this.equipmentOptions[index]}>{this.equipmentOptions[index]}</label>
                </div>
              })
              }
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-auto mb-3">
              <label htmlFor="userId" className="form-label font-weight-bold">User ID</label>
              <input
                type="text"
                className="form-control"
                id="userId"
                name="userId"
                placeholder="User ID"
                value={this.state.userId}
                onChange={e => { this.setState({ userId: e.target.value }) }} />
            </div>
            <div className="col-auto form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="useHistory"
                name="useHistory"
                checked={this.state.useHistory}
                onChange={e => { this.setState({ userHistory: e.target.checked }) }} />
              <label className="form-check-label" htmlFor="useHistory">Use Workout History</label>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="model" className="form-label font-weight-bold">Model</label>
            <input
              type="text"
              className="form-control"
              id="model"
              name="model"
              placeholder="Default"
              value={this.state.model}
              onChange={e => { this.setState({ model: e.target.value }) }} />
          </div>
          <div className="mb-3">
            <label htmlFor="prompt" className="form-label font-weight-bold">Prompt</label>
            <textarea
              className="form-control"
              id="prompt"
              name="prompt"
              placeholder="Prompt"
              rows="3"
              value={this.state.prompt}
              onChange={e => { this.setState({ prompt: e.target.value }) }}></textarea>
          </div>
          <button type="submit" className="btn btn-primary" disabled={this.state.isGenerating} onClick={this.generateWorkout}>Generate</button>
        </form>
      </div>
    )
  }

  renderWorkoutContainer = () => {
    return (
      <div className='container'>
        {this.state.isGenerating && <p className="placeholder-glow"><span className="placeholder col-12"></span></p>}
        {this.state.workout && this.renderWorkout()}
      </div>
    )
  }

  renderWorkout = () => {
    return (<div>
      <h2>Generated Workout</h2>
      <p>Took {this.state.latency / 1000} seconds</p>
      <p>Raw distance: {this.calculateDistance()}</p>
      <pre>
        <code>
          {JSON.stringify(this.state.workout, null, 2)}
        </code>
      </pre>
    </div>)
  }

  calculateDistance = () => {
    const workout = this.state.workout
    if (!workout) return
    let dist = 0
    for (const setGroup of workout.setGroups) {
      for (const set of setGroup.sets) {
        dist += set.distance * set.reps * setGroup.reps
      }
    }
    return dist
  }

  generateWorkout = async () => {
    this.setState({ isGenerating: true })
    const start = new Date()
    const params = JSON.parse(JSON.stringify(this.state)) // deep copy
    const strokesRaw = params.strokes
    const strokes = []
    for (let i = 0; i < strokesRaw.length; i++) {
      if (strokesRaw[i]) strokes.push(this.strokeNames[i])
    }
    params.strokes = strokes
    Parse.Cloud.run('generateWorkout', params).then((result) => {
      const end = new Date()
      this.setState({ workout: result, isGenerating: false, latency: (end.getTime() - start.getTime()) })
    }, (error) => {
      console.error(error.message)
      window.alert('Error generating workout: ' + error.message)
      this.setState({ workout: error.message, isGenerating: false })
    })
  }

  render = () => {
    return (
      <div>
        {this.renderHeader()}
        <hr />
        {this.renderWorkoutContainer()}
      </div>
    )
  }
}
