import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import DateTimePicker from 'react-datetime-picker'
import PropTypes from 'prop-types'

export default class AchievementsDetailComponent extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        achievmeent: null,
        tempConditions: [{}],
        isEditing: false
      }
      this.loadContent()
    } else {
      // When `id` is "new", we are intending to make a new Challenge object.
      // This makes a new one and sets the mode to editing right away
      this.state = {
        achievmeent: null,
        tempConditions: [{}],
        isEditing: true
      }
    }

    this.loadContent = this.loadContent.bind(this)
    this.incompleteIconInput = React.createRef()
    this.completeIconInput = React.createRef()
  }

  propTypes = {
    id: PropTypes.string,
    match: {
      params: {
        id: PropTypes.string
      }
    }
  }

  loadContent = () => {
    const id = this.props.id || this.props.match.params.id
    if (!id) {
      alert('Error: No id. URL format: https://admin.myswimpro.com/#/achievement/id')
      return
    }

    const achievementType = new Parse.Object('Achievement')
    const query = new Parse.Query(achievementType)
    query.get(id).then((results) => {
      const content = results
      if (!content) {
        alert('No achievement found for id ' + id)
        return
      }

      this.setState({
        achievement: content,
        tempName: content.get('name'),
        tempDescription: content.get('description'),
        tempConditions: content.get('conditions')
      })
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  formatDateForLocalTime = (date) => {
    const output = this.getUTCDate(date)
    return output.toLocaleString()
  }

  getUTCDate = (date, flip) => {
    if (!date) return
    const output = new Date(date)
    const adjust = flip ? -1 : 1
    output.setTime(output.getTime() + (output.getTimezoneOffset() * 60 * 1000 * adjust))
    return output
  }

  handleDeleteContentClicked = (event) => {
    event.preventDefault()
    if (!window.confirm("Are you sure you'd like to delete this item? You cannot undo this action.")) {
      return
    }
    const content = this.state.achievement
    content.destroy().then(() => {
      window.location.href = '/#/achievements'
      window.location.reload()
    })
  }

  handleNameChange = (event) => {
    this.setState({
      tempName: event.target.value
    })
  }

  handleDescriptionChange = (event) => {
    this.setState({
      tempDescription: event.target.value
    })
  }

  formatDateForLocalTime = (date) => {
    const output = this.getUTCDate(date)
    return output.toLocaleString()
  }

  getUTCDate = (date, flip) => {
    if (!date) return
    const output = new Date(date)
    const adjust = flip ? -1 : 1
    output.setTime(output.getTime() + (output.getTimezoneOffset() * 60 * 1000 * adjust))
    return output
  }

  availableUnitsForMetricType = (metricType) => {
    switch (metricType) {
      case 'totalDistance':
        return ['meter']
      case 'totalTime':
        return ['second']
      case 'totalWorkoutsCompleted':
        return ['count']
      case 'longestStreak':
        return ['count']
      case 'specialDate':
        return ['date']
      case 'hasRace':
        return ['boolean']
      default:
        return []
    }
  }

  availableAggregationsForMetricType = (metricType) => {
    switch (metricType) {
      case 'totalDistance':
        return ['aggregate', 'individual']
      case 'totalTime':
        return ['aggregate', 'individual']
      case 'totalWorkoutsCompleted':
        return ['aggregate']
      case 'longestStreak':
        return ['aggregate']
      case 'specialDate':
        return ['calendar']
      case 'hasRace':
        return ['interaction']
      default:
        return []
    }
  }

  isRepetitionsValidForAggregation = (aggregation) => {
    switch (aggregation) {
      case 'aggregate':
        return false
      case 'individual':
        return true
      case 'calendar':
        return false
      case 'interaction':
        return false
      default:
        return false
    }
  }

  handleSaveButtonClick = (event) => {
    event.preventDefault()

    const title = (this.state.tempName || '').trim()
    if (title.length === 0) {
      alert("Couldn't save: missing name")
      return
    }

    const description = (this.state.tempDescription || '').trim()
    if (description.length === 0) {
      alert("Couldn't save: missing description")
      return
    }

    const conditions = this.state.tempConditions
    if (!conditions) {
      alert("Couldn't save: missing conditions")
      return
    }

    const iconRequirements = (img) => {
      if (img.width > 300 || img.height > 300) {
        window.alert('This icon is too big! Max allowed size is 300x300')
        return false
      } else if (img.height !== img.width) {
        window.alert('Icon must be square')
        return false
      }
      return true
    }

    const imgRequirements = (img) => {
      if (img.width > 1000 || img.height > 500) {
        alert('This image is too big! Max allowed size is 1000x500')
        return false
      } else if (img.height > img.width) {
        alert('Image width should be greater than image height')
        return false
      }
      return true
    }

    let completedIconURL = this.state.tempCompletedIconURL
    this.saveImage(this.incompleteIconInput, iconRequirements).then((url) => {
      if (url) {
        completedIconURL = url
      }
      return this.saveImage(this.completeIconInput, imgRequirements)
    }).then((url) => {
      const incompleteIconURL = url || this.state.tempImageURL
      this.saveAchievement(this.state.achievement, title, description, incompleteIconURL, completedIconURL, conditions)
    }, (error) => {
      window.alert('There was an error saving the image. Please make sure image file names are unique. ' + error.message)
    })
  }

  saveImage = (fileInput, requirements) => {
    if (!fileInput || !fileInput.current || !fileInput.current.files[0]) {
      console.log('No image to save.')
      return new Promise((resolve, reject) => { resolve() })
    }

    const file = fileInput.current.files[0]
    console.log('file is: ' + file.name)
    const img = new Image()

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        if (requirements && !requirements(img)) {
          reject(new Error('Image fails requirements'))
          return
        }

        // checks passed, upload image
        const parseImage = new Parse.File(file.name, file)
        parseImage.save().then((fileSuccess, fileError) => {
          if (!fileSuccess) {
            console.log('Error saving Image. ' + fileError)
            reject(fileError)
            return
          }

          console.log('Successfully saved image:' + parseImage.url())
          resolve(parseImage.url())
        }, (error) => {
          console.log('Error saving Image. ' + error)
          reject(error)
        })
      }
    })

    img.src = window.URL.createObjectURL(file)
    return promise
  }

  saveAchievement = (existingContent, name, description, incompleteImageURL, completeImageURL, conditions) => {
    const achievement = existingContent || new Parse.Object('Achievement')
    achievement.set('name', name)
    achievement.set('description', description)
    achievement.set('icon_url_locked', incompleteImageURL)
    achievement.set('icon_url_unlocked', completeImageURL)
    achievement.set('conditions', conditions)
    achievement.save().then(() => {
      console.log('succesfully saved achievement. id:' + achievement.id)

      this.setState({
        achievement: achievement
      }, this.reload)
    })
  }

  reload = () => {
    if (this.state.id) {
      window.location.reload()
    } else if (this.state.achievements) {
      window.location.href = '/#/achievements/' + this.state.achievements.id
      window.location.reload()
    } else {
      window.location.href = '/#/achievements'
      window.location.reload()
    }
  }

  handleCancelButtonClick = (event) => {
    this.setState({
      isAdding: false,
      isEditing: false
    })
  }

  handleNewButtonClick = (event) => {
    this.setState({
      isAdding: true
    })
  }

  handleEdit = () => {
    this.setState({
      isEditing: true
    })
  }

  handleMetricTypeChange = (event) => {
    const value = event.target.value
    const conditions = this.state.tempConditions
    conditions[0].metric_type = value
    conditions[0].metric_unit = this.availableUnitsForMetricType(value)[0]
    conditions[0].condition_type = this.availableAggregationsForMetricType(value)[0]
    if (conditions[0].metric_unit) conditions[0].metric_value = new Date()
    this.setState({
      tempConditions: conditions
    })
  }

  handleMetricUnitChange = (event) => {
    const value = event.target.value
    const conditions = this.state.tempConditions
    conditions[0].metric_unit = value
    this.setState({
      tempConditions: conditions
    })
  }

  handleValueChange = (event) => {
    const value = event.target.value
    const conditions = this.state.tempConditions
    conditions[0].metric_value = value
    this.setState({
      tempConditions: conditions
    })
  }

  handleConditionTypeChange = (event) => {
    const value = event.target.value
    const conditions = this.state.tempConditions
    console.log(value)
    conditions[0].condition_type = value
    this.setState({
      tempConditions: conditions
    })
  }

  handleRepetitionChange = (event) => {
    const value = event.target.value
    const conditions = this.state.tempConditions
    conditions[0].repetition = value
    this.setState({
      tempConditions: conditions
    })
  }

  handleOWSChange = (event) => {
    const value = !!Number(event.target.value)
    console.log(value)
    const conditions = this.state.tempConditions
    conditions[0].is_open_water = value
    this.setState({
      tempConditions: conditions
    })
  }

  handleDateChange = (date) => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0)
    const conditions = this.state.tempConditions
    conditions[0].metric_value = newDate
    this.setState({
      tempConditions: conditions
    })
  }

  renderEditView = () => {
    const conditions = this.state.tempConditions
    if (!conditions) {
      return (<span>Loading...</span>)
    }
    return (
            <div className="container">
                <br/>
                <form id="addcontent-form">
                    <div className="form-group">
                        <label htmlFor="title"><b>Name</b></label>
                        <input type="text" className="form-control" id="title" placeholder="title" value={this.state.tempName} onChange={this.handleNameChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description"><b>Description</b></label>
                        <input type="text" className="form-control" id="description" placeholder="description" value={this.state.tempDescription} onChange={this.handleDescriptionChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="content_url"><b>Measurement</b></label>
                        <select className="custom-select" value={this.state.tempConditions[0].metric_type} onChange={this.handleMetricTypeChange}>
                            <option value="totalTime">Duration</option>
                            <option value="totalWorkoutsCompleted">Workouts Completed</option>
                            <option value="totalDistance">Distance</option>
                            <option value="specialDate">Date</option>
                            <option value="hasRace">Has Race</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="content_url"><b>Aggregation</b></label>
                        <select className="custom-select" value={this.state.tempConditions[0].condition_type} onChange={this.handleConditionTypeChange}>
                            {this.availableAggregationsForMetricType(this.state.tempConditions[0].metric_type).map((aggregation) => {
                              return (<option key={aggregation} value={aggregation}>{aggregation}</option>)
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="content_url"><b>Unit</b></label>
                        <select className="custom-select" value={this.state.tempConditions[0].metric_unit} onChange={this.handleMetricUnitChange}>
                            {this.availableUnitsForMetricType(this.state.tempConditions[0].metric_type).map((metricUnit) => {
                              return (<option key={metricUnit} value={metricUnit}>{metricUnit}</option>)
                            })}
                        </select>
                    </div>
                    <div className="form-group" hidden={this.state.tempConditions[0].metric_unit === 'date'}>
                        <label htmlFor="description"><b>Value</b> <i>({this.state.tempConditions[0].metric_unit || ''})</i></label>
                        <input type="text" className="form-control" id="description" value={this.state.tempConditions[0].metric_value} onChange={this.handleValueChange}/>
                    </div>
                    <div className="form-group" hidden={this.state.tempConditions[0].metric_unit !== 'date'}>
                        <label htmlFor="date"><b>Date</b> <span className="text-muted">Local time.</span><br/>
                        <small className="text-muted">Time will be adjusted to 00:00:00</small>
                        </label><br/>
                        <DateTimePicker id="date" onChange={this.handleDateChange} value={this.state.tempConditions[0].metric_unit === 'date' ? this.getUTCDate(new Date(this.state.tempConditions[0].metric_value)) : new Date()} />
                    </div>
                    <div className="form-group" hidden={!this.isRepetitionsValidForAggregation(this.state.tempConditions[0].condition_type)}>
                        <label htmlFor="description"><b>Repetitions</b></label>
                        <input type="number" className="form-control" id="description" value={this.state.tempConditions[0].repetition} onChange={this.handleRepetitionChange}/>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" checked={!!this.state.tempConditions[0].is_open_water} onChange={this.handleOWSChange}/>
                        <label className="form-check-label" htmlFor="inlineCheckbox1">Must be Open Water</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="file_upload"><b>Incomplete Icon Image</b></label>
                        <input type="file" ref={this.incompleteIconInput} id="file_upload"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="file_upload"><b>Complete Icon Image</b></label>
                        <input type="file" ref={this.completeIconInput} id="file_upload"/>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={this.handleSaveButtonClick}>Save</button>
                    &nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.handleCancelButtonClick}>Cancel</button>
                </form>
            </div>
    )
  }

  renderReadonlyView = () => {
    const achievement = this.state.achievement
    if (!achievement) {
      return (<div>Loading...</div>)
    }

    return (
            <div className="container">
                <br/>
                <div className="row">
                    <a href={achievement.get('icon_url_locked')}>
                        <img src={achievement.get('icon_url_locked')} alt={achievement.get('icon_url_locked')}/>
                    </a>
                    <a href={achievement.get('icon_url_unlocked')}>
                        <img src={achievement.get('icon_url_unlocked')} alt={achievement.get('icon_url_unlocked')}/>
                    </a>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-9">
                        <h1>{achievement.get('name')} <small className="text-muted">{achievement.id}</small></h1>
                    </div>
                    <div className="col-sm-3">
                        { this.state.isEditing ? <button className="btn btn-success" onClick={this.handleSaveButtonClick} disabled={this.state.saving}>Save</button> : <button className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>}
                    </div>
                </div>
                <div className="row">
                    <p className="lead col">{achievement.get('description')}</p>
                </div>
                <div>
                  <div>
                    { this.state.tempConditions.map((condition) => {
                      return (
                        <div key={condition} className="row">
                          <div className="col-sm-3">
                            <b>Measurement</b><br/>
                            {condition.metric_type}<br/>
                            <b>Value</b><br/>
                            {condition.metric_unit}: {condition.metric_unit === 'date' ? this.formatDateForLocalTime(condition.metric_value).toString() : condition.metric_value.toString()}<br/>
                          </div>
                          <div className="col-sm-3">
                            <b>Aggregation</b><br/>
                            {condition.condition_type}<br/>
                            <b>Repetitions</b><br/>
                            {condition.repetition ?? 'n/a'}<br/>
                          </div>
                          <div className="col-sm-3">
                            <b>Open Water</b><br/>
                            {condition.is_open_water ? 'required' : 'n/a'}
                          </div>
                        </div>
                      )
                    }) }
                  </div>
                  <br/>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-danger" onClick={this.handleDeleteContentClicked}>
                            Delete
                        </button>
                    </div>
                </div>
                <br/>
            </div>
    )
  }

  render = () => {
    return this.state.isEditing ? this.renderEditView() : this.renderReadonlyView()
  }
}
