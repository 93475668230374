import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { QRCode } from 'react-qrcode-logo'

export default class DownloadTool extends React.Component {
  ref = React.createRef()
  constructor (props) {
    super(props)
    this.state = {}
  }

  copyToClipboard = async () => {
    try {
      const element = document.querySelector('.user-select-all')
      await navigator.clipboard.writeText(element.textContent)
      // Optional: Provide feedback or perform additional actions upon successful copy
    } catch (error) {
      console.error('Failed to copy to clipboard:', error)
      // Optional: Handle and display the error to the user
    }
  }

  handleDownload = () => {
    this.ref.current?.download()
  }

  buildUrl = () => {
    const url = new URL('https://app.myswimpro.com/download')
    if (this.state.utm_source) url.searchParams.append('utm_source', this.state.utm_source)
    if (this.state.utm_medium) url.searchParams.append('utm_medium', this.state.utm_medium)
    if (this.state.utm_campaign) url.searchParams.append('utm_campaign', this.state.utm_campaign)
    if (this.state.utm_content) url.searchParams.append('utm_content', this.state.utm_content)
    return <div>
      <div className="row mb-2">
        <div className="col">
          <div className="card">
            <div className="card-header">
              URL
            </div>
            <div className="card-body d-flex justify-content-between align-middle">
              <code className="user-select-all">{url.href}</code>
            </div>
            <button className="btn btn-sm btn-outline-secondary" onClick={this.copyToClipboard}>Copy link to Clipboard</button>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-header">
              QR Code
            </div>
            <QRCode
              value={url.href}
              className="card-img-top"
              ref={this.ref}
              size="500"
              logoImage="/app_icon.png"
              logoWidth="100"
              logoPadding="12"/>
            <button className="btn btn-sm btn-outline-secondary" onClick={this.handleDownload}>Download QR Code</button>
          </div>
        </div>
      </div>
      <b>Expected Behavior:</b>
      <ul>
        <li>
          <b>Desktop:</b> Shows upsell with download link and QR code. <code>utm_campaign</code> parameter tracked in Google Analytics, not passed through to store.
        </li>
        <li>
          <b>iOS + Android:</b> Immediately redirects to Apple App Store or Google Play Store. <code>utm_campaign</code> parameter tracked in App Store Connect and Google Play Console.
        </li>
      </ul>
    </div>
  }

  render = () => {
    return (
      <div className="container">
        <h2 className="mt-5">
          Download Link Builder
        </h2>
        <div className="card">
          <div className="card-body">
            <form>
              <div className="card text-bg-light">
                <div className="card-header">
                  Tracking Parameters
                </div>
                <div className="row card-body">
                  <div className="col-sm-3">
                    <label htmlFor="utm_medium" className="form-label fw-bold">utm_campaign</label>
                    <input type="text" placeholder='Required' className="form-control" id="utm_medium" aria-describedby="emailHelp" value={this.state.utm_campaign} onChange={e => this.setState({ utm_campaign: e.target.value })} />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="utm_source" className="form-label fw-bold">utm_source</label>
                    <input type="text" className="form-control" id="utm_source" aria-describedby="emailHelp" value={this.state.utm_source} onChange={e => this.setState({ utm_source: e.target.value })} />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="utm_medium" className="form-label fw-bold">utm_medium</label>
                    <input type="text" className="form-control" id="utm_medium" aria-describedby="emailHelp" value={this.state.utm_medium} onChange={e => this.setState({ utm_medium: e.target.value })} />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="exampleInputEmail2" className="form-label fw-bold">utm_content</label>
                    <input type="text" className="form-control" id="utm_content" aria-describedby="emailHelp" value={this.state.utm_content} onChange={e => this.setState({ utm_content: e.target.value })} />
                  </div>
                </div>
              </div>
            </form>
            <div className="card text-bg-light mt-4">
              <div className="card-header">Output</div>
              <div className="card-body">
                {this.buildUrl()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
