import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import SetDetail from './SetDetailComponent'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default class SetGroupRow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      setGroup: props.setGroup,
      index: props.index,
      isEditing: false,
      onUpdate: props.onUpdate,
      onDelete: props.onDelete,
      onDuplicateSetGroup: props.onDuplicateSetGroup,
      defaultSetType: props.defaultSetType,
      setClassName: props.isAdmin ? 'AdminSet' : 'Set_v3',
      isAdmin: props.isAdmin
    }
  }

  componentWillReceiveProps = (newProps) => {
    this.setState({ setGroup: newProps.setGroup })
  }

  editClicked = (event) => {
    this.setState({
      isEditing: !this.state.isEditing
    })
  }

  doneClicked = (event) => {
    event.preventDefault()
    // update setGroup, propagate to parent
    this.setState(
      {
        isEditing: false
      },
      this.state.onUpdate(this.state.setGroup, this.state.index)
    )
  }

  deleteClicked = (event) => {
    event.preventDefault()
    this.state.onDelete(this.state.index)
  }

  addSetClicked = (event) => {
    event.preventDefault()
    const setGroup = this.state.setGroup
    const sets = setGroup.get('sets') || []
    const newSet = new Parse.Object(this.state.setClassName)
    // set some defaults
    newSet.set('zone', 1)
    newSet.set('stroke', 0)
    newSet.set('distance', 100) // default is 100
    newSet.set('reps', 1) // default is 1 rep
    newSet.set('exerciseType', 0)
    newSet.set('setType', this.state.defaultSetType)
    sets.push(newSet)
    setGroup.set('sets', sets)
    this.setState({
      setGroup: setGroup
    })
  }

  handleDeleteSet = (index) => {
    const setGroup = this.state.setGroup
    const sets = setGroup.get('sets')
    sets.splice(index, 1)
    setGroup.set('sets', sets)
    this.setState({
      setGroup: setGroup
    })
  }

  handleUpdateSet = (set, index) => {
    const setGroup = this.state.setGroup
    const sets = setGroup.get('sets')
    sets[index] = set
    setGroup.set('sets', sets)
    this.setState({
      setGroup: setGroup
    })
  }

  onTitleChange = (event) => {
    event.preventDefault()

    const setGroup = this.state.setGroup
    setGroup.set('title', event.target.value)
    this.setState({
      setGroup: setGroup
    })
  }

  onRepsChange = (event) => {
    event.preventDefault()
    const setGroup = this.state.setGroup
    setGroup.set('reps', parseInt(event.target.value))
    this.setState({
      setGroup: setGroup
    })
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const setGroup = this.state.setGroup
    const sets = setGroup.get('sets')
    const dragSets = sets[result.source.index]
    sets.splice(result.source.index, 1)
    sets.splice(result.destination.index, 0, dragSets)

    setGroup.set('sets', sets)
    this.setState({
      setGroup: setGroup
    })
  }

  setDuplicateSet = async (set, index) => {
    const setGroup = this.state.setGroup
    const sets = setGroup.get('sets')
    const newSet = new Parse.Object(this.state.setClassName)
    // set some defaults
    newSet.set('zone', set.get('zone'))
    newSet.set('stroke', set.get('stroke'))
    newSet.set('distance', set.get('distance')) // default is 100
    newSet.set('reps', set.get('reps')) // default is 1 rep
    newSet.set('exerciseType', set.get('exerciseType'))
    newSet.set('setType', this.state.defaultSetType)
    sets.splice(index + 1, 0, newSet)
    setGroup.set('sets', sets)
    this.setState({
      setGroup: setGroup
    })
  }

  renderNotEditing = () => {
    const setGroup = this.state.setGroup
    const index = this.state.index

    return (
      <div className="card">
        <div className="card-header">
          <div
            className="row d-flex align-items-center justify-content-between grabbable"
            style={{ cursor: 'move' }}
          >
            <div className="col-md-8">
              {setGroup.get('title') === '' || !setGroup.get('title')
                ? (
                <div>-</div>
                  )
                : (
                <div>
                  {setGroup.get('title')}{' '}
                  <span style={{ fontSize: 13, color: 'gray' }}>
                    {setGroup.id}
                  </span>{' '}
                </div>
                  )}
              <i>{setGroup.get('reps')}x</i>
            </div>
            <div className="mr-3">
              <button className="btn btn-primary" onClick={this.editClicked}>
                Edit
              </button>
              <button
                className="btn btn-outline-secondary ml-2"
                onClick={() => this.state.onDuplicateSetGroup(index)}
              >
                Duplicate Set Group
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div>
            {setGroup.get('sets')
              ? (
                  setGroup.get('sets').length === 0
                    ? (
                <span className="text-muted">No sets</span>
                      )
                    : (
                        setGroup.get('sets').map((set, setIndex) => {
                          return (
                    <SetDetail
                      key={setIndex}
                      index={setIndex}
                      set={set}
                      onDelete={this.handleDeleteSet}
                      onUpdate={this.handleUpdateSet}
                      isEditing={false}
                    />
                          )
                        })
                      )
                )
              : (
              <span>No sets</span>
                )}
          </div>
        </div>
      </div>
    )
  }

  renderEditing = () => {
    const setGroup = this.props.setGroup
    return (
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-8">
              <input
                type="text"
                value={setGroup.get('title') || ''}
                onChange={this.onTitleChange}
                placeholder="Title"
              ></input>
              <br />
              <input
                type="number"
                value={setGroup.get('reps') || 1}
                onChange={this.onRepsChange}
                placeholder="Reps"
              ></input>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div>
            {setGroup.get('sets') !== undefined
              ? (
              <Droppable
                droppableId="droppable"
                type={'droppableSubItem'}
                set={this.state.setGroup}
              >
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {setGroup.get('sets').map((set, setIndex) => (
                      <Draggable
                        key={set.id}
                        draggableId={`${set.id}-${setGroup.id}`}
                        index={setIndex}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={setIndex}
                          >
                            <SetDetail
                              key={setIndex}
                              index={setIndex}
                              set={set}
                              onDelete={this.handleDeleteSet}
                              onUpdate={this.handleUpdateSet}
                              onDuplicate={this.setDuplicateSet}
                              isEditing={true}
                            />
                            <br />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
                )
              : null}
          </div>
          <div className="row justify-content-center">
            <button className="btn btn-secondary col-sm-4" onClick={this.addSetClicked}>
              + Add Set
            </button>
            <button className="btn btn-success col-sm-4" onClick={this.doneClicked}>
              Done
            </button>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-danger float-right"
                onClick={this.deleteClicked}
              >
                Delete Set Group
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    return this.state.isEditing ? this.renderEditing() : this.renderNotEditing()
  }
}
