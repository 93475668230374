import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import debounce from 'lodash.debounce'
import SubscriptionTableRow from './SubscriptionTableRowComponent'

export default class SubscriptionTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      subscriptions: props.subscriptions,
      offset: 0,
      totalCount: 0,
      isLoading: true
    }

    this.loadSubscriptions = this.loadSubscriptions.bind(this)

    // Binds scroll event handler
    window.onscroll = debounce(() => {
      console.log('on scroll called')
      console.log(this.state.error)
      console.log('isLoading: ' + this.state.isLoading)
      console.log('hasMore: ' + this.state.hasMore)
      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (this.state.error !== undefined || this.state.isLoading || !this.state.hasMore) return

      console.log('loading more...')
      // Checks that the page has scrolled to the bottom
      if (window.innerHeight + Math.floor(document.documentElement.scrollTop) === document.documentElement.offsetHeight) {
        this.loadSubscriptions()
      }
    }, 100)

    this.loadSubscriptions()
  }

  loadSubscriptions = () => {
    const userId = this.state.userIdTarget
    const creationSource = this.state.creationSourceTarget
    const stripeCustomerId = this.state.stripeCustomerIdTarget
    const googleOrderId = this.state.googleOrderIdTarget

    console.log('Loading subscriptions')
    if (userId) {
      console.log('with userId: ' + userId)
    }
    if (creationSource) {
      console.log('with creationSource: ' + creationSource)
    }

    const Subscription = new Parse.Object('Subscription_v4')
    const query = new Parse.Query(Subscription)
    query.withCount(true)

    if (userId) query.equalTo('userId', userId)
    if (creationSource) query.equalTo('creationSource', creationSource)
    if (stripeCustomerId) query.matches('stripeCustomerId', '(' + stripeCustomerId + ')', 'i')
    if (googleOrderId) query.startsWith('googleOrderId', googleOrderId)

    query.limit(100)
    const currentOffset = this.state.offset
    query.skip(currentOffset)

    query.find().then((results) => {
      const subscriptions = results.results
      const totalCount = results.count
      if (!subscriptions) {
        alert('No subscriptions found.')
        return
      }

      let sanitizedSubscriptions = subscriptions.map(subscription => {
        return {
          objectId: subscription.id,
          planId: subscription.get('planId'),
          planAmount: subscription.get('planAmount'),
          planCurrency: subscription.get('planCurrency'),
          planInterval: subscription.get('planInterval'),
          planStartDate: subscription.get('planStartDate'),
          planCancelDate: subscription.get('planCancelDate'),
          periodStartDate: subscription.get('periodStartDate'),
          periodExpirationDate: subscription.get('periodExpirationDate'),
          trialStartDate: subscription.get('trialStartDate'),
          trialEndDate: subscription.get('trialEndDate'),
          creationSource: subscription.get('creationSource'),
          lastUpdateSource: subscription.get('lastUpdateSource'),
          stripeCustomerId: subscription.get('stripeCustomerId'),
          stripeSubscriptionId: subscription.get('stripeSubscriptionId'),
          userId: subscription.get('userId'),
          countryCode: subscription.get('countryCode')
        }
      })

      if (currentOffset !== 0) {
        sanitizedSubscriptions = this.state.subscriptions.concat(sanitizedSubscriptions)
      }

      let hasMore = false
      if (subscriptions.length === 100) {
        hasMore = true
      }

      console.log(sanitizedSubscriptions)
      console.log('more subscriptions : ' + hasMore)

      this.setState({
        subscriptions: sanitizedSubscriptions,
        offset: currentOffset + 100,
        hasMore: hasMore,
        isLoading: false,
        totalCount: totalCount
      })
      console.log(this.state)
    }, (error) => {
      alert('Error getting user: ' + error.message)
    })
  }

  handleUserIdTextFieldChange = (event) => {
    this.setState({
      userIdTarget: event.target.value.trim()
    })
  }

  handleCreationSourceTextFieldChange = (event) => {
    this.setState({
      creationSourceTarget: event.target.value.trim()
    })
  }

  handleStripeCustomerIdTextFieldChange = (event) => {
    this.setState({
      stripeCustomerIdTarget: event.target.value.trim()
    })
  }

  handleGoogleOrderIDTextFieldChange = (event) => {
    this.setState({
      googleOrderIdTarget: event.target.value.trim()
    })
  }

  handleButtonClick = (event) => {
    this.setState({
      isLoading: true,
      subscriptions: [],
      totalCount: 0,
      offset: 0
    }, this.loadSubscriptions)
  }

  renderTable = () => {
    return this.state.subscriptions.map((subscription, index) => {
      return (
        <SubscriptionTableRow subscription={subscription} key={index}/>
      )
    })
  }

  renderTableHeader = () => {
    return (
      <tr key={'0-header'} className="data">
        <th className="bg-primary" key={0}>ID</th>
        <th className="bg-primary" key={0}>User</th>
        <th className="bg-primary" key={1}>Plan</th>
        <th className="bg-primary" key={2}>Created by</th>
        <th className="bg-primary" key={3}>Updated by</th>
        <th className="bg-primary" key={4}>Stripe Customer ID</th>
      </tr>
    )
  }

  render = () => {
    return (
            <div className="container">
                <br/>
                <h1 id="title">Subscriptions</h1>
                <div className="card-footer">
                    <form id="userlookup-form" className="form-inline">
                      <div className="form-row">
                        <div className="col">
                          <div className="form-group">
                              <input type="text" className="form-control" id="userid" placeholder="User Id" onChange={this.handleUserIdTextFieldChange}/>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                              <input type="text" className="form-control" id="creationSource" placeholder="Creation Source" onChange={this.handleCreationSourceTextFieldChange}/>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                              <input type="text" className="form-control" id="stripeCustomerId" placeholder="Stripe Customer Id" onChange={this.handleStripeCustomerIdTextFieldChange}/>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                              <input type="text" className="form-control" id="googleOrderId" placeholder="Google Order ID" onChange={this.handleGoogleOrderIDTextFieldChange}/>
                          </div>
                        </div>
                        <div className="col">
                          <button type="button" className="btn btn-primary" onClick={this.handleButtonClick}>Lookup</button>
                        </div>
                        <div className="col">
                            {
                                this.state.totalCount > 0 ? <p>{this.state.totalCount} results</p> : null
                            }
                        </div>
                      </div>
                    </form>
                </div>
                <table id="subscriptions" className="table-display table-striped">
                    <tbody>
                        {this.renderTableHeader()}
                        {this.renderTable()}
                    </tbody>
                </table>
                { this.state.isLoading &&
                    <div>Loading...</div>
                }
            </div>
    )
  }
}
