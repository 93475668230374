import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'

export default class EffortVariationDetail extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        isLoading: true,
        id: this.props.match.params.id,
        editing: false,
        saving: false,
        numResults: -1,
        existingTags: [],
        existingEffortVariation: []
      }
      this.loadEffortVariation()
    } else {
      // When `id` is "new", we are intending to make a new Exercise object.
      // This makes a new one and sets the mode to editing right away
      const effortVariationType = new Parse.Object('EffortVariation')
      this.state = {
        isLoading: false,
        effortVariation: effortVariationType,
        editing: true,
        saving: false,
        numResults: -1,
        existingTags: [],
        existingEffortVariation: []
      }
    }

    this.imageInput = React.createRef()
    this.loadEffortVariation()
  }

  loadEffortVariation = () => {
    const effortVariationType = new Parse.Object('EffortVariation')
    const query = new Parse.Query(effortVariationType)
    query.equalTo('objectId', this.state.id)
    console.log('load effortVariation with id: ' + this.state.id)
    query.first().then((result) => {
      this.setState({
        effortVariation: result,
        isLoading: false
      })
    }, (error) => {
      console.log(error)
      window.alert('Error fetching effortVariation')
    })
  }

  handleEdit = () => {
    const newEditing = !this.state.editing
    this.setState({
      editing: newEditing
    })
  }

  handleSave = async (event) => {
    event.preventDefault()
    this.setState({
      saving: true
    })
    await this.saveEffortVariation()
    if (this.state.id) {
      window.location.reload()
    } else {
      window.location.href = '/#/effortVariation/' + this.state.effortVariation.id
      window.location.reload()
    }
  }

  saveEffortVariation = async () => {
    if (this.state.effortVariation.get('enumValue') === undefined) {
      this.state.effortVariation.set('enumValue', this.state.numResults)
    }
    const image = await this.saveImage(this.imageInput)
    if (image) {
      this.state.effortVariation.set('image', image)
    }
    return await this.state.effortVariation.save()
  }

  handleCancel = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  handleNameChange = (event) => {
    this.state.effortVariation.set('name', event.target.value)
  }

  handleDescriptionChange = (event) => {
    this.state.effortVariation.set('description', event.target.value)
  }

  handleTypeChange = (event) => {
    this.state.effortVariation.set('type', event.target.value)
  }

  handleNew = async (event) => {
    const newKey = prompt('Please enter a key name for this effortVariation. Careful- you can\'t change it once it\'s set! No spaces allowed.\n\nEx: pull_buoy, fins, medicine_ball')
    if (!newKey) return
    const newName = prompt('Please enter the name of the effortVariation. Members will see this name.')
    if (!newName) return
    const newObj = new Parse.Object('EffortVariation')
    newObj.set('key', newKey)
    newObj.set('name', newName)
    await newObj.save()
    window.location.href = '/#/effortVariation/' + newObj.id
    window.location.reload()
  }

  saveImage = async (fileInput, requirements) => {
    if (!fileInput || !fileInput.current) return new Promise((resolve, reject) => { resolve() })
    if (!fileInput.current.files[0]) {
      console.log('No image to save.')
      return new Promise((resolve, reject) => { resolve() })
    }

    const file = fileInput.current.files[0]
    console.log('file is: ' + file.name)
    const img = new Image()

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        if (requirements && !requirements(img)) {
          reject(new Error('Image fails requirements'))
          return
        }

        // checks passed, upload image
        const parseImage = new Parse.File(file.name, file)
        parseImage.save().then((fileSuccess, fileError) => {
          if (!fileSuccess) {
            console.log('Error saving Image. ' + fileError)
            reject(fileError)
            return
          }

          console.log('Successfully saved image:' + parseImage.url())
          resolve(parseImage)
        }, (error) => {
          console.log('Error saving Image. ' + error)
          reject(error)
        })
      }
    })

    img.src = window.URL.createObjectURL(file)
    return promise
  }

  getEditButtons = () => {
    if (this.state.editing) {
      return (
        <div>
            <button className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving}>Save</button>
        </div>
      )
    }

    return (
      <div>
          <button type="button" className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>
          &nbsp;&nbsp;
          <button type="button" className="btn btn-primary" onClick={this.handleNew}>New</button>
      </div>
    )
  }

  renderNotEditing = (effortVariation) => {
    return (
      <div className="container">
        <br/>
        <div className="row">
          <div className="col">
            <h1>
              <img src={effortVariation.get('image') ? effortVariation.get('image').url() : ''} className="img-fluid" height="40px" width="40px"/>
              {effortVariation.get('name')} <small className="text-muted">{effortVariation.id}</small>
            </h1>
          </div>
          <div className="col text-right">
            {this.getEditButtons()}
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col">
            <p>
              <b>Key:</b><br/>
              <span className="text-muted"><code>{effortVariation.get('key')}</code></span>
              <p><b>Description:</b><br/>
              <span className="text-muted">{effortVariation.get('description')}</span></p>
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderEditing = (effortVariation) => {
    return (
            <div className="container">
                <br/>
                <span className="text-muted">{effortVariation.get('key')}</span>
                <br/>
                <hr/>
                <form>
                  <div className="form-group">
                    <label htmlFor="file_upload"><b>Image</b> <small className="text-muted">If different from current</small></label><br/>
                    <input type="file" ref={this.imageInput} id="file_upload"/>
                  </div>
                  <div className="form-group">
                    <label><b>Name</b></label>
                    <input type="text" className="form-control" placeholder={effortVariation.get('name')} defaultValue={effortVariation.get('name')} onChange={this.handleNameChange}/>
                  </div>
                  <div className="form-group">
                    <label><b>Description</b></label>
                    <input type="text" className="form-control" placeholder={effortVariation.get('description')} defaultValue={effortVariation.get('description')} onChange={this.handleDescriptionChange}/>
                  </div>
                  <button type="submit" className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving} >Save</button>
                  <br/><br/>
                  <button type="submit" className="btn btn-secondary" onClick={this.handleCancel} disabled={this.state.saving} >Cancel</button>
                </form>
            </div>
    )
  }

  render = () => {
    if (this.state.effortVariation === undefined) {
      return (<div>Loading...</div>)
    }
    const video = this.state.effortVariation

    return this.state.editing ? this.renderEditing(video) : this.renderNotEditing(video)
  }
}
