import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { formatSeconds } from '../../Utils'

export default class WorkoutDetails extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      workout: props.workout,
      editingInfo: false,
      skill: props.workout.get('skillLevel'),
      course: props.workout.get('poolCourse'),
      type: props.workout.get('workoutType'),
      className: props.workout.className
    }

    this.deleteWorkout = props.onDelete.bind(this)
    this.onUpdateSkillLevel = props.onUpdateSkillLevel.bind(this)
    this.onUpdatePoolCourse = props.onUpdatePoolCourse.bind(this)
    this.onUpdateWorkoutType = props.onUpdateWorkoutType.bind(this)
  }

  updatePoolCourse = (event) => {
    event.preventDefault()
    this.setState({
      course: parseInt(event.target.value)
    }, this.onUpdatePoolCourse(event))
  }

  updateSkillLevel = (event) => {
    event.preventDefault()
    this.setState({
      skill: parseInt(event.target.value)
    }, this.onUpdateSkillLevel(event))
  }

  updateWorkoutType = (event) => {
    event.preventDefault()
    this.setState({
      type: parseInt(event.target.value)
    }, this.onUpdateWorkoutType(event))
  }

  render = () => {
    const workout = this.state.workout
    return (
            <div className='border my-3 p-4'>
                <div className="row mb-3">
                    <div className="col-md-3"><b>Id:</b> <span>{workout.id}</span></div>
                    <div className="col-md-3"><b>Total Distance:</b> {(this.props.totalDistance || 0).toString()}</div>
                    <div className="col-md-3"><b>Total Time:</b> {formatSeconds(this.props.totalTime)}</div>
                </div>

                <div className="row mb-1">
                    <div className="form-inline col-md-3">
                        <label><b>Skill Level:</b></label>
                        <select className="form-control ml-3" value={this.state.skill} onChange={this.updateSkillLevel}>
                            <option value="undefined">--</option>
                            <option value="0">Level 1</option>
                            <option value="1">Level 2</option>
                            <option value="2">Level 3</option>
                            <option value="3">Level 4</option>
                            <option value="4">Level 5</option>
                        </select>
                    </div>
                    <div className="form-inline col-md-3">
                        <label><b>Pool Course:</b></label>
                            <select className="form-control ml-3" value={this.state.course} onChange={this.updatePoolCourse}>
                                <option value="undefined">--</option>
                                <option value="0">Short Course</option>
                                <option value="2">Long Course</option>
                        </select>
                    </div>
                    <div className="form-inline col-md-3">
                        <label><b>Workout Type:</b></label>
                        <select className="form-control ml-3" value={this.state.type} onChange={this.updateWorkoutType}>
                            <option value="undefined">--</option>
                            <option value="0">Free</option>
                            <option value="1">Distance</option>
                            <option value="2">Sprint</option>
                            <option value="3">Kick</option>
                            <option value="4">IM</option>
                            <option value="5">Fly</option>
                            <option value="6">Back</option>
                            <option value="7">Breast</option>
                            <option value="8">Upper Body</option>
                            <option value="9">Lower Body</option>
                            <option value="10">Total Body</option>
                            <option value="11">Shoulders</option>
                            <option value="12">Core</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <a href={'https://parse-dashboard.myswimpro.com/apps/MySwimPro/browser/' + this.state.className + '?filters=%5B%7B%22field%22%3A%22objectId%22%2C%22constraint%22%3A%22eq%22%2C%22compareTo%22%3A%22' + workout.id + '%22%7D%5D'}>View Workout in Parse</a>
                    </div> <br />
                </div>
                <br/>
                <div className="">
                        <button className="btn btn-danger" onClick={this.deleteWorkout}>Delete workout</button>
                    </div>
            </div>
    )
  }
}
