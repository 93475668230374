import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import debounce from 'lodash.debounce'
import { getSkillLevelString, getPoolCourseString, getWorkoutTypeString } from '../../Utils'
import {
  Link,
  Route
} from 'react-router-dom'
import { Table } from 'react-bootstrap'

export default class WorkoutTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      workouts: props.workouts || [],
      offset: 0,
      totalCount: 0,
      isAdmin: props.isAdmin,
      isPremium: false,
      isLoading: false,
      setGroupClassName: props.isAdmin ? 'AdminSetGroup' : 'SetGroup_v3',
      basePath: props.isAdmin ? '/adminworkouts/' : '/workouts/',
      className: props.isAdmin ? 'AdminWorkout' : 'Workout_v3'
    }

    this.loadWorkouts = this.loadWorkouts.bind(this)

    // Binds scroll event handler
    window.onscroll = debounce(() => {
      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (this.state.error !== undefined || this.state.isLoading || !this.state.hasMore) return

      // Checks that the page has scrolled to the bottom
      if (window.innerHeight + Math.floor(document.documentElement.scrollTop) === document.documentElement.offsetHeight) {
        console.log('Loading more from scroll...')
        this.loadWorkouts()
      }
    }, 10)

    this.loadWorkouts()
  }

  componentWillUnmount = () => {
    window.onscroll = null
  }

  baseWorkoutQuery (skill, course, type) {
    console.log('creating base query with -- skill:' + skill + ' course:' + course + ' type:' + type)
    const workout = new Parse.Object(this.state.className)
    const query = new Parse.Query(workout)
    query.withCount(true)
    query.descending('createdAt')
    if (skill !== undefined) {
      query.equalTo('skillLevel', skill)
    }
    if (course !== undefined) {
      query.equalTo('poolCourse', course)
    }
    if (type !== undefined) {
      query.equalTo('workoutType', type)
    }

    return query
  }

  loadWorkouts = () => {
    console.log('loadWorkouts')
    const skill = this.state.skill
    const course = this.state.course
    const isPremium = this.state.isPremium
    const isAdminWorkout = this.state.isAdminWorkout
    const isLibrary = this.state.isLibrary
    const type = this.state.type
    const isDryland = this.state.isDryland
    const isTestSetWorkout = this.state.isTestSetWorkout

    let query

    if (isLibrary === true) {
      const innerQuery1 = this.baseWorkoutQuery(skill, course, type)
      const innerQuery2 = this.baseWorkoutQuery(skill, course, type)
      innerQuery1.equalTo('isPremium', true)
      innerQuery2.equalTo('isAdminWorkout', true)
      query = Parse.Query.or(innerQuery1, innerQuery2)
      query.withCount(true)
      query.include(['setgroups.sets'])
    } else {
      query = this.baseWorkoutQuery(skill, course, type)
      if (isPremium === true) {
        query.equalTo('isPremium', true)
      }
      if (isAdminWorkout === true) {
        query.equalTo('isAdminWorkout', true)
      }
    }
    if (isDryland === true) {
      query.equalTo('isAllStrength', true)
    }
    if (isTestSetWorkout === true) {
      query.equalTo('isTestSetWorkout', true)
    }

    query.limit(10000)
    const currentOffset = this.state.offset
    query.skip(currentOffset)

    console.log(query)
    query.find().then((results) => {
      const workouts = results.results || []
      const totalCount = results.count
      if (!workouts) {
        alert('No workouts found.')
        return
      }

      console.log('got results, count: ' + workouts.length)

      let sanitizedWorkouts = workouts.map(workout => {
        return workout.toJSON()
      })

      if (currentOffset !== 0) {
        sanitizedWorkouts = this.state.workouts.concat(sanitizedWorkouts)
      }

      let hasMore = false
      if (workouts.length === 1000) {
        hasMore = true
      }

      this.setState({
        workouts: sanitizedWorkouts,
        offset: currentOffset + 1000,
        hasMore: hasMore,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting user: ' + error.message)
    })
  }

  handleButtonClick = (event) => {
    this.setState({
      isLoading: true,
      workouts: [],
      totalCount: 0,
      offset: 0
    }, this.loadWorkouts)
  }

  handleNewWorkoutClicked = (event) => {
    event.preventDefault()
    const workout = new Parse.Object(this.state.className)
    workout.save().then(() => {
      this.props.history.push(this.state.basePath + workout.id)
    })
  }

  changePoolCourse = (event) => {
    console.log('changePoolCourse: ' + event.target.value)
    this.setState({
      course: parseInt(event.target.value)
    })
  }

  changeSkillLevel = (event) => {
    console.log('changeSkillLevel: ' + event.target.value)
    this.setState({
      skill: parseInt(event.target.value)
    })
  }

  changeWorkoutType = (event) => {
    console.log('changeWorkoutType: ' + event.target.value)
    this.setState({
      type: parseInt(event.target.value)
    })
  }

  changeIsPremium = (event) => {
    console.log('changeIsPremium: ' + event.target.value)
    this.setState({
      isPremium: event.target.checked
    })
  }

  changeIsAdminWorkout = (event) => {
    console.log('changeIsAdminWorkout: ' + event.target.value)
    this.setState({
      isAdminWorkout: event.target.checked
    })
  }

  changeIsLibrary = (event) => {
    console.log('changeIsLibrary: ' + event.target.value)
    this.setState({
      isLibrary: event.target.checked
    })
  }

  changeIsDryland = (event) => {
    console.log('changeIsDryland: ' + event.target.value)
    this.setState({
      isDryland: event.target.checked
    })
  }

  changeIsTestSetWorkout = (event) => {
    console.log('changeIsTestSetWorkout: ' + event.target.value)
    this.setState({
      isTestSetWorkout: event.target.checked
    })
  }

  render = () => {
    console.log('Rendering workout table component. isAdmin: ' + this.state.isAdmin)
    console.log('WORKOUT---', this.state.workouts)
    const column = [
      {
        title: 'Title',
        field: 'title',
        render: (rowData) => {
          return <Link className="btn-link" to={this.state.basePath + rowData.objectId}><b>{rowData.title || '-'}</b></Link>
        }
      },
      { title: 'Distance', field: 'totalDistance' },
      {
        title: 'Level',
        field: 'skillLevel',
        render: (rowData) => getSkillLevelString(rowData.skillLevel)
      },
      {
        title: 'Course',
        field: 'poolCourse',
        render: (rowData) => getPoolCourseString(rowData.poolCourse)
      },
      {
        title: 'Type',
        field: 'workoutType',
        render: (rowData) => getWorkoutTypeString(rowData.workoutType)
      },
      {
        title: 'Mode',
        render: rawData => rawData.isAllStrength ? 'Dryland' : 'Swim'
      },
      {
        title: 'Test Set',
        render: rawData => rawData.isTestSetWorkout ? '🚩' : ''
      }
    ]
    return (
        <div>
            <Route path={this.state.isAdmin ? '/adminworkouts' : '/workouts'}>
                <div className="container">
                    <br/>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0 30px 0' }}>
                        <h2 id="title">{this.state.isAdmin ? 'Admin ' : ''}Workout Library</h2>
                        <div className="">
                            <button type="button" className="btn btn-primary" onClick={this.handleNewWorkoutClicked}>New Workout</button>
                        </div>
                    </div>
                    <div className="mb-5 p-4" style={{ background: '#f5f5f5' }}>
                        <form id="userlookup-form" className="">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.changePoolCourse}>
                                        <option value="-1">Course</option>
                                        <option value="0">SCY</option>
                                        <option value="2">LCM</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.changeSkillLevel}>
                                        <option value="-1">Skill</option>
                                        <option value="0">Level 1</option>
                                        <option value="1">Level 2</option>
                                        <option value="2">Level 3</option>
                                        <option value="3">Level 4</option>
                                        <option value="4">Level 5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                            <div className="form-group">
                                    <select className="form-control" onChange={this.changeWorkoutType}>
                                        <option value="-1">Type</option>
                                        <option value="0">Free</option>
                                        <option value="1">Distance</option>
                                        <option value="2">Sprint</option>
                                        <option value="3">Kick</option>
                                        <option value="4">IM</option>
                                        <option value="5">Fly</option>
                                        <option value="6">Back</option>
                                        <option value="7">Breast</option>
                                        <option value="8">Upper Body</option>
                                        <option value="9">Lower Body</option>
                                        <option value="10">Total Body</option>
                                        <option value="11">Shoulders</option>
                                        <option value="12">Core</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="flex" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isPremium" onChange={this.changeIsPremium}/><span>&nbsp; isPremium</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isAdminWorkout" onChange={this.changeIsAdminWorkout}/><span>&nbsp; isAdminWorkout</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isLibrary" onChange={this.changeIsLibrary}/><span>&nbsp; Library (isPremium or isAdminWorkout)</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isDryland" onChange={this.changeIsDryland}/><span>&nbsp; Dryland</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isTestSetWorkout" onChange={this.changeIsTestSetWorkout}/><span>&nbsp; Test Set Workout</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-3" style={{ alignItems: 'center' }}>
                              <div className="mr-3">
                                  <button type="button" className="btn btn-primary" onClick={this.handleButtonClick}>Lookup</button>
                              </div>
                              <div className="text-success">
                                  {
                                      this.state.totalCount > 0 ? <span>{this.state.totalCount} total results</span> : null
                                  }
                              </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    <Table
                    data={this.state.workouts} columns={column} isLoading={this.state.isLoading &&
                      <div style={{ height: '100vh', display: 'absolute', top: '0' }}>Loading...</div>} title={<span>Click on column heading for sorting</span>} options={{
                        paging: true,
                        pageSize: 100, // make initial page size
                        emptyRowsWhenPaging: false, // To avoid of having empty rows
                        pageSizeOptions: [25, 50, 100, 200],
                        sortIcon: 'show', // rows selection options
                        headerStyle: {
                          backgroundColor: '#007bff',
                          color: '#FFF',
                          fontSize: '17px',
                          position: 'sticky',
                          top: 0
                        },
                        maxBodyHeight: 490,
                        rowStyle: x => {
                          if (x.tableData.id % 2) {
                            return { backgroundColor: '#f2f2f2' }
                          }
                        },
                        searchFieldStyle: {
                          border: '1px solid #e2e2e2',
                          padding: '8px 0px 8px 8px'
                        }
                      }}
                      localization={{ toolbar: { searchPlaceholder: 'Search by Title and Distance' } }}
                    />
                    { this.state.isLoading &&
                        <div style={{ textAlign: 'center' }}>Loading More Data</div>
                    }
                </div>
            </Route>
        </div>)
  }
}
