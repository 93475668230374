import React from 'react'
import TrainingPlan from './TrainingPlanComponent.js'

export default class AdminPlan extends React.Component {
  constructor (props) {
    console.log(props)
    super(props)
    this.state = {
      props: props
    }
  }

  render = () => {
    return (<TrainingPlan {...this.state.props} isAdmin={true} />)
  }
}
