import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class EquipmentList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      equipment: []
    }

    this.loadEquipment = this.loadEquipment.bind(this)

    this.loadEquipment()
  }

  loadEquipment = () => {
    const equipmentType = new Parse.Object('Equipment')
    const query = new Parse.Query(equipmentType)
    query.limit(1000)
    query.find().then((results) => {
      const equipment = results
      const totalCount = equipment.count
      if (!equipment || equipment.length === 0) {
        alert('No equipment found.')
        return
      }

      this.setState({
        equipment: equipment,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting equipment: ' + error.message)
    })
  }

  filteredEquipment = () => {
    const filter = this.state.filter
    if (filter && filter !== '') {
      const lowercaseFilter = filter.toLowerCase()
      return this.state.equipment.filter((equipment) => {
        const tagMatch = equipment.get('key').toLowerCase().includes(lowercaseFilter)
        const nameMatch = equipment.get('name').toLowerCase().includes(lowercaseFilter)
        return nameMatch || tagMatch
      })
    }
    return this.state.equipment
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value
    })
  }

  handleNew = async (event) => {
    const newKey = prompt('Please enter a key name for this equipment. Careful- you can\'t change it once it\'s set! No spaces allowed.\n\nEx: pull_buoy, fins, medicine_ball')
    if (!newKey) return
    const newName = prompt('Please enter the name of the equipment. Members will see this name.')
    if (!newName) return
    const newObj = new Parse.Object('Equipment')
    newObj.set('key', newKey)
    newObj.set('name', newName)
    await newObj.save()
    window.location.href = '/#/equipment/' + newObj.id
    window.location.reload()
  }

  renderTable = () => {
    return this.filteredEquipment().map((equipment, index) => {
      return (
        <tr key={index} className="data">
            <th scope="row">
              <code>{equipment.get('key')}</code>
            </th>
            <td>
              <img src={equipment.get('image').url()} className="img-fluid" height="40px" width="40px"/>
            </td>
            <td>
              <b>
                <a href={'/#/equipment/' + equipment.id}>
                  {equipment.get('name')}
                </a>
              </b>
              <br/>
              {equipment.get('type')}
              <br/>
              <a href={equipment.get('purchaseUrl')}>{equipment.get('purchaseUrl')}</a>
            </td>
        </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
      <tr key={'0-header'} className="data">
        <th className="bg-primary" key={'0h'} scope="col">Key</th>
        <th className="bg-primary" key={'0h'} scope="col">Image</th>
        <th className="bg-primary" key={'1h'} scope="col">Details</th>
      </tr>
    )
  }

  render = () => {
    return (
        <div>
            <Route path="/equipment">
                <div className="container">
                    <br/>
                    <h1>Equipment</h1>

                    <div className="row">
                        <form className="col-sm-6">
                            <div className="form-group">
                                <input type="text" className="form-control" id="labelFilter" placeholder="Filter" onChange={this.handleFilterChange}/>
                            </div>
                        </form>
                        <div className="col-sm-1 offset-md-5">
                          <button className="btn btn-primary" onClick={this.handleNew}>Add</button>
                        </div>
                    </div>

                    <small className="text-muted"><b>Note:</b> An equipment can not be deleted after being created and it&apos;s meaning should not be significantly modified after being deployed.</small>
                    <table id="featuredcontent" className="table table-striped table-hover">
                        <thead>
                          {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.renderTable()}
                        </tbody>
                    </table>

                </div>
            </Route>
        </div>)
  }
}
