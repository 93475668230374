import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class ChallengesList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: [],
      isLoading: true
    }

    this.loadContent = this.loadContent.bind(this)

    this.loadContent()
  }

  loadContent = () => {
    console.log('loadContent')

    const content = new Parse.Object('Challenge')
    const query = new Parse.Query(content)
    query.limit(1000)
    query.descending('startDate')
    query.find().then((results) => {
      const content = results
      console.log('got result for content')
      const totalCount = content.count
      if (!content) {
        alert('No content found.')
        return
      }

      this.setState({
        content: content,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  modeString = (modeValue) => {
    switch (modeValue) {
      case 0:
        return 'All workout types'
      case 1:
        return 'Pool'
      case 2:
        return 'Open Water'
      case 3:
        return 'Dryland'
      case 4:
        return 'Mixed'
      case 5:
        return 'Open Pool'
      case 6:
        return 'Guided Pool'
      default:
        return 'All workout types'
    }
  }

  modeStringFromArray = (modeValues) => {
    return modeValues.map((value) => { return this.modeString(value) }).join(', ')
  }

  renderTable = () => {
    const now = new Date()
    return this.state.content.map((content, index) => {
      const imageUrl = content.get('imageUrl')
      const iconUrl = content.get('iconUrl')

      const isActive = content.get('startDate') < now && content.get('endDate') > now
      return (
               <tr key={index} className="data">
                    <td className={ isActive ? 'bg-success text-light' : '' }>
                        { isActive ? 'ACTIVE' : '' }
                        { isActive ? <br/> : ''}
                        <b>
                            {content.get('startDate').toLocaleString('en-US', { timeZone: 'UTC', hour12: false })}</b> - <b>{content.get('endDate').toLocaleString('en-US', { timeZone: 'UTC', hour12: false })}
                        </b>
                    </td>
                    <td>
                        <a href={iconUrl}>
                            <img height="100px" src={iconUrl} alt={iconUrl}/>
                        </a>
                    </td>
                    <td>
                        <a href={imageUrl}>
                            <img height="100px" src={imageUrl} alt={imageUrl}/>
                        </a>
                    </td>
                    <td>
                        <b>{content.get('name')}</b><br/>
                        {content.get('description')}<br/>
                        <br/>
                        <b>Goal:</b> <code>{content.get('metricType')}</code> | {content.get('goal')} {content.get('metricUnit')}
                        <br/>
                        <b>Modes:</b> {this.modeStringFromArray(content.get('mode'))}
                    </td>
                    <td className="text-center">
                        <a href={'#/challenges/' + content.id}>
                            <button className="btn btn-secondary">
                                View
                            </button>
                        </a>
                    </td>
               </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>{'Date'}</th>
                <th className="bg-primary" key={'1h'}>{'Icon'}</th>
                <th className="bg-primary" key={'2h'}>{'Image'}</th>
                <th className="bg-primary" key={'3h'}>{'Content'}</th>
                <th className="bg-primary" key={'4h'}>{''}</th>
            </tr>
    )
  }

  handleNewButtonClick = (event) => {
    window.location.href = '/#/challenges/new'
    window.location.reload()
  }

  render = () => {
    console.log('render content table component')
    return (
        <div>
            <Route path="/challenges">
                <div className="container">
                    <br/>
                    <div>
                        <h1 id="title">Challenges</h1>
                        <div className="row justify-content-end">
                            <div className="col-sm-2">
                                <button className="btn btn-primary" onClick={this.handleNewButtonClick}>
                                    New
                                </button>
                            </div>
                        </div>
                        <br/>
                    </div>
                    <br/>
                    <table id="featuredcontent" className="data">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>
                    { this.state.isLoading &&
                        <div>Loading...</div>
                    }
                </div>
            </Route>
        </div>)
  }
}
