import React from 'react'
import Parse from 'parse'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  secondsFromInterval,
  minutesFromInterval,
  getZoneString,
  getStrokeString,
  formatSeconds,
  numExercises
} from '../../Utils'

export default class SetDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      set: props.set,
      index: props.index,
      isEditing: props.isEditing,
      onUpdate: props.onUpdate,
      onDuplicate: props.onDuplicate,
      onDelete: props.onDelete,
      minutes: props.set && minutesFromInterval(props.set.get('interval')),
      seconds:
        props.set &&
        formatSeconds(secondsFromInterval(props.set.get('interval'))),
      exerciseTypes: [],
      effortVariations: [],
      equipmentTypes: []
    }

    this.onIntervalUpdate = this.onIntervalUpdate.bind(this)
    this.loadAllExerciseTypes()
    this.loadAllEquipmentTypes()
    this.loadAllEffortVariations()
    this.loadAllVideos()
    this.loadVideoIfNecessary()
  }

  loadAllExerciseTypes = () => {
    const exerciseType = new Parse.Object('DrylandExercise')
    const query = new Parse.Query(exerciseType)
    query.limit(1000)
    query.find().then(
      (results) => {
        this.setState({
          exerciseTypes: results
        })
      },
      (error) => {
        alert(error)
      }
    )
  }

  loadAllEquipmentTypes = () => {
    const exerciseType = new Parse.Object('Equipment')
    const query = new Parse.Query(exerciseType)
    query.limit(1000)
    query.find().then(
      (results) => {
        this.setState({
          equipmentTypes: results
        })
      },
      (error) => {
        alert(error)
      }
    )
  }

  loadAllEffortVariations = () => {
    const exerciseType = new Parse.Object('EffortVariation')
    const query = new Parse.Query(exerciseType)
    query.limit(1000)
    query.find().then(
      (results) => {
        this.setState({
          effortVariations: results
        })
      },
      (error) => {
        alert(error)
      }
    )
  }

  loadAllVideos = () => {
    const videoType = new Parse.Object('Video_v3')
    const query = new Parse.Query(videoType)
    query.limit(1000)
    query.find().then(
      (results) => {
        this.setState({
          videos: results
        })
      },
      (error) => {
        alert(error)
      }
    )
  }

  loadVideoIfNecessary = () => {
    if (!this.state.set.get('video')) return
    this.state.set.get('video').fetch()
  }

  componentWillReceiveProps = (newProps) => {
    this.setState({ set: newProps.set })
  }

  deleteClicked = (index) => {
    this.state.onDelete(this.state.index)
  }

  onRepsChange = (event) => {
    event.preventDefault()
    const set = this.state.set
    set.set('reps', parseInt(event.target.value))
    this.state.onUpdate(set)
  }

  onDistanceChange = (event) => {
    event.preventDefault()
    const set = this.state.set
    set.set('distance', parseInt(event.target.value))
    this.state.onUpdate(set, this.state.index)
  }

  onIsTestSetChanged = (event) => {
    const value = event.target.checked
    const set = this.state.set
    set.set('isTestSet', !!value)
    this.state.onUpdate(set, this.state.index)
  }

  onStrokeChange = (event) => {
    event.preventDefault()
    const value = event.target.value
    const set = this.state.set
    if (value === '6' && this.state.equipmentTypes) {
      // Pull set; add pull_buoy to equipment if we have it
      const existingEquipment = set.get('equipment') || []
      const pullBuoyEquipment = this.state.equipmentTypes.find(
        (equipment) => equipment.get('key') === 'pull_buoy'
      )
      const index = existingEquipment.indexOf(pullBuoyEquipment)
      if (index === -1) {
        existingEquipment.push(pullBuoyEquipment.get('key'))
        set.set('equipment', existingEquipment)
      }
    }
    set.set('stroke', parseInt(value))
    this.state.onUpdate(set, this.state.index)
  }

  onExerciseChange = (event) => {
    event.preventDefault()
    const set = this.state.set
    set.set('exerciseType', parseInt(event.target.value))

    this.state.onUpdate(set, this.state.index)
  }

  onMinutesChange = (event) => {
    event.preventDefault()
    this.setState(
      {
        minutes: parseInt(event.target.value)
      },
      this.onIntervalUpdate
    )
  }

  onSecondsChange = (event) => {
    event.preventDefault()
    const inputValue = parseInt(event.target.value)
    if (inputValue > 60) {
      this.setState(
        {
          minutes: this.state.minutes + inputValue / 60,
          seconds: inputValue % 60
        },
        this.onIntervalUpdate
      )
    } else {
      this.setState(
        {
          minutes: this.state.minutes ? this.state.minutes : 0,
          seconds: parseInt(event.target.value)
        },
        this.onIntervalUpdate
      )
    }
  }

  onIntervalUpdate = () => {
    const calculateInterval = this.state.seconds + this.state.minutes * 60
    const set = this.state.set
    set.set('interval', parseInt(calculateInterval))
    this.state.onUpdate(set, this.state.index)
  }

  onZoneChange = (event) => {
    event.preventDefault()
    const set = this.state.set
    set.set('zone', parseInt(event.target.value))
    this.state.onUpdate(set, this.state.index)
  }

  onMediaURLChange = (event) => {
    event.preventDefault()
    const set = this.state.set
    set.set('mediaURL', event.target.value)
    this.state.onUpdate(set, this.state.index)
  }

  onNotesChange = (event) => {
    event.preventDefault()
    const set = this.state.set
    set.set('notes', event.target.value)
    this.state.onUpdate(set, this.state.index)
  }

  onSetTypeChange = (event) => {
    const set = this.state.set
    set.set('setType', parseInt(event.target.value))
    this.state.onUpdate(set, this.state.index)
    this.setState({
      set: set
    })
  }

  onVariationChange = (event) => {
    let value = event.target.value
    if (value === 'None') value = undefined
    const set = this.state.set
    set.set('effortVariation', value)
    this.setState({
      set: set
    })
  }

  createExerciseOptions = () => {
    const names = []
    for (let i = 0; i < this.state.exerciseTypes.length; i++) {
      const exerciseName = this.state.exerciseTypes[i].get('name')
      names.push({ index: i, name: exerciseName })
    }
    names.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    return names.map((value) => {
      return (
        <option value={value.index} key={value.index}>
          {value.name}
        </option>
      )
    })
  }

  createEffortVariationOptions = () => {
    const names = []
    for (let i = 0; i < this.state.effortVariations.length; i++) {
      const name = this.state.effortVariations[i].get('name')
      const key = this.state.effortVariations[i].get('key')
      names.push({ key: key, name: name })
    }
    names.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    return names.map((value) => {
      return (
        <option value={value.key} key={value.key}>
          {value.name}
        </option>
      )
    })
  }

  createVideoOptions = () => {
    const videos = this.state.videos
    if (!videos || !videos.length) {
      console.log('no vids to map')
      return
    }
    videos.sort((a, b) => {
      if (a.get('title') > b.get('title')) {
        return 1
      } else if (a.get('title') < b.get('title')) {
        return -1
      }
      return 0
    })
    console.log('mapping ' + videos.length + ' vids')
    return videos.map((value) => {
      return (
        <option value={value.id} key={value.id}>
          {value.get('title')}
        </option>
      )
    })
  }

  onVideoChange = (event) => {
    console.log('vid change: ' + event.target.value)
    if (event.target.value) {
      const vid = this.state.videos.find(
        (element) => element.id === event.target.value
      )
      const set = this.state.set
      set.set('video', vid)
      this.setState({
        set: set
      })
    } else {
      this.state.set.unset('video')
    }
  }

  createEquipmentOptions = () => {
    const names = []
    for (let i = 0; i < this.state.equipmentTypes.length; i++) {
      const name = this.state.equipmentTypes[i].get('name')
      const key = this.state.equipmentTypes[i].get('key')
      names.push({ key: key, name: name })
    }
    names.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    const existingEquipment = this.state.set.get('equipment') || []
    return names.map((value) => {
      const name = value.name + 'checkbox' + this.state.index
      return (
        <div
          key={value.key + '-' + this.state.index}
          className="form-check form-check-inline"
        >
          <input
            className="form-check-input"
            type="checkbox"
            id={name}
            value={value.key}
            checked={existingEquipment.includes(value.key)}
            onChange={this.updateEquipment}
          />
          <label className="form-check-label" htmlFor={name}>
            {value.name}
          </label>
        </div>
      )
    })
  }

  updateEquipment = (event) => {
    const set = this.state.set
    const setIndex = this.state.index
    const equipment = event.target.value
    const existingEquipment = set.get('equipment') || []
    const index = existingEquipment.indexOf(equipment)
    if (index === -1) {
      existingEquipment.push(equipment)
    } else {
      existingEquipment.splice(index, 1)
    }
    set.set('equipment', existingEquipment)
    this.state.onUpdate(set, setIndex)
    this.setState({
      set: set
    })
  }

  getExerciseString = (value) => {
    if (value === undefined) {
      return 'undefined'
    }
    if (!this.state.exerciseTypes[value]) {
      return 'undefined'
    }
    return this.state.exerciseTypes[value].get('name')
  }

  renderStrengthNotEditing = () => {
    const set = this.state.set
    const setIndex = this.state.index
    return (
      <div key={setIndex} className="container">
        <b>{this.getExerciseString(set.get('exerciseType'))}</b> &nbsp; @{' '}
        {set.get('interval')
          ? formatSeconds(set.get('interval'))
          : 'no interval'}
        <div className="text-muted">
          {set.get('notes') !== undefined && set.get('notes') !== ''
            ? (
            <div key={'notesSet:' + setIndex}>
              &nbsp;&nbsp;&nbsp;&nbsp;{set.get('notes')}
            </div>
              )
            : (
                ''
              )}
        </div>
      </div>
    )
  }

  renderStrengthEditing = () => {
    const set = this.state.set
    const setIndex = this.state.index
    return (
      <div key={setIndex} className="card">
        <div className="card-header">
          <div className="row justify-content-between align-items-center">
            <label className="col-sm-3">
              <div className="form-row form-group">
                <select
                  className="form-control"
                  value={set.get('setType')}
                  onChange={this.onSetTypeChange}
                >
                  <option value="0">Swim</option>
                  <option value="1">Dryland</option>
                </select>
              </div>
            </label>
            <div className="col text-right">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={this.deleteClicked}
              >
                Delete Set
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div>
            <b>Exercise Type</b>
            <br />
            <select
              className="form-control col-md-3"
              value={set.get('exerciseType')}
              onChange={this.onExerciseChange}
            >
              {this.createExerciseOptions()}
            </select>
            <br />
          </div>
          <div>
            <b>Interval</b>
            <br />
            <input
              type="number"
              value={
                set.get('interval')
                  ? minutesFromInterval(set.get('interval'))
                  : ''
              }
              placeholder="minutes"
              onChange={this.onMinutesChange}
            ></input>
            <input
              type="number"
              value={
                set.get('interval')
                  ? formatSeconds(secondsFromInterval(set.get('interval')))
                  : ''
              }
              placeholder="seconds"
              onChange={this.onSecondsChange}
            ></input>
            <br />
            <br />
          </div>
          <div>
            <b>Notes</b>
            <br />
            <input
              type="text"
              value={set.get('notes')}
              placeholder="Notes"
              onChange={this.onNotesChange}
            ></input>
            <br />
          </div>
        </div>
      </div>
    )
  }

  renderSwimNotEditing = () => {
    const set = this.state.set
    const setIndex = this.state.index
    return (
      <div key={setIndex} className="container">
        {set.get('isTestSet')
          ? (
          <div><b className="bg-warning">🚩 TEST SET:</b></div>
            )
          : (
              ''
            )}
        <b>
          {set.get('reps')} x {set.get('distance')} &nbsp;{' '}
          {getStrokeString(set.get('stroke'))} &nbsp; @{' '}
          {set.get('interval') ? formatSeconds(set.get('interval')) : ''}
        </b>{' '}
        &nbsp; {getZoneString(set.get('zone'))}
        <span>
          {set.get('effortVariation') ? ', ' + set.get('effortVariation') : ''}
        </span>{' '}
        <span className="text-muted">
          {set.get('equipment') ? set.get('equipment').join(', ') : ''}
        </span>
        <div className="pl-4">
          {set.get('video')
            ? (
            <video
              src={set.get('video').get('videoUrl')}
              poster={
                set.get('video').get('image')
                  ? set.get('video').get('image').url()
                  : ''
              }
              controls
              height="100px"
              width="200px"
            ></video>
              )
            : (
            <span className="text-muted">
              {set.get('mediaURL') ? '(' + set.get('mediaURL') + ')' : ''}
            </span>
              )}
          {set.get('notes') && set.get('notes') !== ''
            ? (
            <div key={'notesSet:' + setIndex}>{set.get('notes')}</div>
              )
            : (
                ''
              )}
        </div>
      </div>
    )
  }

  renderSwimEditing = () => {
    const set = this.state.set
    const setIndex = this.state.index
    return (
      <div key={setIndex} className="card">
        <div className="card-header">
          <div className="row justify-content-between">
            <label className="col-sm-3">
              <div className="form-row form-group">
                <select
                  className="form-control"
                  value={set.get('setType')}
                  onChange={this.onSetTypeChange}
                >
                  <option value="0">Swim</option>
                  <option value="1">Dryland</option>
                </select>
              </div>
            </label>
            <div className="col text-right">
              <button
                className="btn btn-outline-secondary  btn-sm mr-2"
                onClick={() => this.state.onDuplicate(set, setIndex)}
              >
                Duplicate Set
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={this.deleteClicked}
              >
                Delete Set
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div>
            <form>
              <div className="bg-light p-2 rounded">
                <div className="row">
                  <div className="form-row form-group col-sm-4">
                    <label className="col-sm-4">
                      <b>Reps</b>
                    </label>
                    <input
                      className="col-sm-8"
                      type="number"
                      value={set.get('reps') || 1}
                      placeholder="reps"
                      onChange={this.onRepsChange}
                    ></input>
                  </div>
                  <div className="form-row form-group col-sm-4">
                    <label className="col-sm-4">
                      <b>Distance</b>
                    </label>
                    <input
                      className="col-sm-8"
                      type="number"
                      step="25"
                      value={set.get('distance') || 100}
                      placeholder="distance"
                      onChange={this.onDistanceChange}
                    ></input>
                  </div>
                  <div className="form-row form-group col-sm-4">
                    <label className="col-sm-4">
                      <b>Stroke</b>
                    </label>
                    <select
                      className="form-control col-sm-8"
                      value={set.get('stroke')}
                      onChange={this.onStrokeChange}
                    >
                      <option value={undefined} key={undefined}>
                        None
                      </option>
                      <option value="0">Free</option>
                      <option value="1">Back</option>
                      <option value="2">Breast</option>
                      <option value="3">Fly</option>
                      <option value="4">IM</option>
                      <option value="5">Kick</option>
                      <option value="6">Pull</option>
                      <option value="7">Drill</option>
                      <option value="8">Stroke</option>
                      <option value="9">FRIM</option>
                    </select>
                  </div>
                </div>
                <div className="form-row form-group">
                  <label className="col-sm-1">
                    <b>Interval</b>
                  </label>
                  <input
                    className="col-sm-1"
                    type="number"
                    value={
                      set.get('interval')
                        ? minutesFromInterval(set.get('interval'))
                        : ''
                    }
                    placeholder="minutes"
                    onChange={this.onMinutesChange}
                  ></input>
                  <span className="text-muted col-sm-1">minutes&nbsp;</span>
                  <input
                    className="col-sm-1"
                    type="number"
                    value={
                      set.get('interval')
                        ? formatSeconds(
                          secondsFromInterval(set.get('interval'))
                        )
                        : ''
                    }
                    placeholder="seconds"
                    onChange={this.onSecondsChange}
                  ></input>
                  <span className="text-muted col-sm-1">seconds&nbsp;</span>
                </div>
              </div>
              <br />
              <div className="bg-light p-2 rounded">
                <div className="row">
                  <div className="form-row form-group col-sm-6">
                    <label className="col-sm-4">
                      <b>Effort Level</b>
                    </label>
                    <select
                      className="form-control col-sm-8"
                      value={set.get('zone')}
                      onChange={this.onZoneChange}
                    >
                      <option value={undefined} key={undefined}>
                        None
                      </option>
                      <option value="1">1-Easy</option>
                      <option value="2">2-Moderate</option>
                      <option value="3">3-Endurance</option>
                      <option value="4">4-Threshold</option>
                      <option value="5">5-Best Average</option>
                      <option value="6">6-Race Pace</option>
                      <option value="7">7-Sprint</option>
                    </select>
                  </div>
                  <div className="form-row form-group col-sm-6">
                    <label className="col-sm-4">
                      <b>Effort Variation</b>
                    </label>
                    <select
                      className="form-control col-md-8"
                      value={set.get('effortVariation')}
                      onChange={this.onVariationChange}
                    >
                      <option value={undefined} key={undefined}>
                        None
                      </option>
                      {this.createEffortVariationOptions()}
                    </select>
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group bg-light p-2 rounded">
                <div className="form-row">
                  <label className="col-sm-2">
                    <b>Equipment</b>
                  </label>
                  {this.createEquipmentOptions()}
                </div>
              </div>
            </form>
          </div>
          <div className="form-group form-row bg-light p-2 rounded">
            <div className="form-row col-sm-6">
              <label className="col-sm-3">
                <b>Video: &nbsp;</b>
              </label>
              <select
                className="form-control col-md-8"
                value={set.get('video') ? set.get('video').id : undefined}
                onChange={this.onVideoChange}
              >
                <option value={undefined} key={undefined}>
                  None
                </option>
                {this.createVideoOptions()}
              </select>
            </div>
            <div className="form-row col-sm-6">
              <label className="col-sm-2">
                <b>Notes: &nbsp;</b>
              </label>
              <input
                className="col-sm-10"
                type="text"
                value={set.get('notes')}
                placeholder="Optional"
                onChange={this.onNotesChange}
              ></input>
            </div>
            <div className="form-row col-sm-6">
              <label className="col-sm-2">
                <b>Is Test Set: &nbsp;</b>
              </label>
              <input
                className="col-sm-10"
                type="checkbox"
                checked={set.get('isTestSet')}
                onChange={this.onIsTestSetChanged}
              ></input>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    const set = this.state.set
    const isStrength = set.get('setType') === 1
    if (this.state.isEditing) {
      if (isStrength) {
        return this.renderStrengthEditing()
      } else {
        return this.renderSwimEditing()
      }
    } else {
      if (isStrength) {
        return this.renderStrengthNotEditing()
      } else {
        return this.renderSwimNotEditing()
      }
    }
  }
}
