import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class DrylandExercises extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      exercises: []
    }

    this.loadExercises = this.loadExercises.bind(this)

    this.loadExercises()
  }

  loadExercises = () => {
    const exerciseType = new Parse.Object('DrylandExercise')
    const query = new Parse.Query(exerciseType)
    query.limit(1000)
    query.find().then((results) => {
      const exercises = results
      console.log('Got result for exercises')
      const totalCount = exercises.count
      if (!exercises || exercises.length === 0) {
        alert('No exercises found.')
        return
      }

      this.setState({
        exercises: exercises,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting exercises: ' + error.message)
    })
  }

  filteredExercises = () => {
    const filter = this.state.filter
    if (filter && filter !== '') {
      const lowercaseFilter = filter.toLowerCase()
      return this.state.exercises.filter((exercise) => {
        const tagMatch = (exercise.get('tags') || []).map(tag => tag.toLowerCase()).includes(lowercaseFilter)
        const equipmentMatch = (exercise.get('equipment') || []).map(tag => tag.toLowerCase()).includes(lowercaseFilter)
        const titleMatch = exercise.get('name').toLowerCase().includes(lowercaseFilter)
        return titleMatch || equipmentMatch || tagMatch
      })
    }
    return this.state.exercises
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value
    })
  }

  renderTable = () => {
    return this.filteredExercises().map((exercise, index) => {
      return (
               <tr key={index} className="data">
                    <td>{exercise.get('enumValue')}</td>
                    <td>
                        <a href={'/#/exercises/' + exercise.id}><b>{exercise.get('name')}</b></a>
                        <br/>
                        <span className="text-muted">{exercise.get('descriptionShort')}</span>
                    </td>
                    <td>{exercise.get('descriptionLong')}</td>
                    <td>{exercise.get('tags') ? exercise.get('tags').join(', ') : 'none'}</td>
                    <td>{exercise.get('difficulty')}</td>
                    <td>{exercise.get('equipment') ? exercise.get('equipment').join(', ') : 'none'}</td>
               </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>{''}</th>
                <th className="bg-primary" key={'1h'}>{'Name'}</th>
                <th className="bg-primary" key={'2h'}>{'Description'}</th>
                <th className="bg-primary" key={'3h'}>{'Tags'}</th>
                <th className="bg-primary" key={'4h'}>{'Difficulty'}</th>
                <th className="bg-primary" key={'5h'}>{'Equipment'}</th>
            </tr>
    )
  }

  render = () => {
    return (
        <div>
            <Route path="/exercises">
                <div className="container">
                    <br/>
                    <h1>Dryland Exercises</h1>

                    <div className="row">
                        <form className="col-sm-6">
                            <div className="form-group">
                                <input type="text" className="form-control" id="labelFilter" placeholder="Filter" onChange={this.handleFilterChange}/>
                            </div>
                        </form>
                        <div className="col-sm-1 offset-md-5">
                            <a href="/#/exercises/new">
                                <button className="btn btn-primary" href="/#/exercises/new">Add</button>
                            </a>
                        </div>
                    </div>

                    <small className="text-muted"><b>Note:</b> An exercise can not be deleted after being created and it&apos;s meaning should not be significantly modified after being deployed.</small>
                    <table id="featuredcontent" className="data">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>

                </div>
            </Route>
        </div>)
  }
}
