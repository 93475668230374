import Parse from 'parse'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

export default class WODHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      wod: props.wod,
      isEditing: props.isEditing
    }
    this.fileInput = React.createRef()
  }

  handleEditInfoClicked = (event) => {
    event.preventDefault()
    if (this.state.isEditing) {
      // save
      console.log('saving wod with new title: ' + this.state.newTitle + ' and new date: ' + this.state.newDate)
      console.log('and file: ' + this.fileInput.current.files[0])
      if (this.state.newTitle) this.state.wod.set('title', this.state.newTitle)
      if (this.state.newDate instanceof Date && !isNaN(this.state.newDate)) this.state.wod.set('workoutDate', this.state.newDate)
      if (this.fileInput.current.files[0]) {
        const file = this.fileInput.current.files[0]
        console.log('file is: ' + file.name)
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
          // target size is 500 x 250
          // max size is 1000 x 500
          // alert when aspect ratio is height > width
          if (img.width > 1000 || img.height > 500) {
            alert('This image is too big! Max allowed size is 1000x500')
            return
          } else if (img.height > img.width) {
            alert('Image width should be greater than image height')
            return
          }
          // checks passed, upload image
          const parseImage = new Parse.File(file.name, file)
          parseImage.save().then((fileSuccess, fileError) => {
            if (fileSuccess) {
              this.state.wod.set('image', parseImage)
            }
            this.state.wod.save().then((success, error) => {
              this.setState({
                isEditing: false
              })
            })
          })
        }
        return
      }
      this.state.wod.save().then((success, error) => {
        this.setState({
          isEditing: false
        })
      })
    } else {
      this.setState({
        isEditing: true
      })
    }
  }

  onDateChange = (e) => {
    const selectedDate = e.target.value
    console.log('selectedDate: ' + selectedDate)
    const dateComponents = selectedDate.split('-')
    console.log(new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2]))
    this.setState({
      newDate: new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2])
    })
  }

  onTitleChange = (e) => {
    const selectedTitle = e.target.value
    console.log('onTitleChange: ' + selectedTitle)
    this.setState({
      newTitle: selectedTitle
    })
  }

  handleDeleteClicked = (e) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this WOD? You cannot undo this action.')
    if (shouldDelete) {
      console.log('Deleting WOD...')
      this.state.wod.destroy()
      window.location.href = '/#/wods/'
      window.location.reload()
    }
  }

  render = () => {
    const wod = this.state.wod
    const wodDate = wod.get('workoutDate')
    let formattedDate
    if (!wodDate && !this.state.newDate) {
      formattedDate = new Date().toISOString().slice(0, 10)
    } else {
      const dateToUse = this.state.newDate ? this.state.newDate : wodDate
      formattedDate = dateToUse.getFullYear() + '-' +
            ((dateToUse.getMonth() + 1) < 10 ? '0' : '') + ((dateToUse.getMonth() + 1)) + '-' +
            (dateToUse.getDate() < 10 ? '0' : '') + dateToUse.getDate()
    }

    return (
            <div>
                <div>
                    {wod.get('image') ? <img src={wod.get('image').url()}></img> : <p>No Image</p>}
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Title</b>
                        </div>
                        <div className="col-md-10">
                            {
                              this.state.isEditing
                                ? <textarea rows="1" cols="50" value={this.state.newTitle ? this.state.newTitle : wod.get('title')} onChange={this.onTitleChange}></textarea>
                                : (wod.get('title') ? <p>{wod.get('title')}</p> : <p>none</p>)
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Date</b>
                        </div>
                        <div className="col-md-10">
                            {
                              this.state.isEditing
                                ? <input type="date" value={formattedDate} onChange={this.onDateChange} />
                                : (wod.get('workoutDate') ? <p>{wod.get('workoutDate').toLocaleDateString('en-US')}</p> : <p>none</p>)
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Image</b>
                        </div>
                        <div className="col-md-10">
                            {
                                this.state.isEditing
                                  ? <input type="file" ref={this.fileInput} />
                                  : (wod.get('image') ? <a href={wod.get('image').url()}>{wod.get('image')._name}</a> : <p>none</p>)
                            }
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-2">
                            <a href={'https://parse-dashboard.myswimpro.com/apps/MySwimPro/browser/WorkoutOfTheDay?filters=%5B%7B%22field%22%3A%22objectId%22%2C%22constraint%22%3A%22eq%22%2C%22compareTo%22%3A%22' + wod.id + '%22%7D%5D'}>View WOD in Parse</a>
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-outline-secondary" onClick={this.handleEditInfoClicked}>
                                {this.state.isEditing ? <span>Done</span> : <span>Edit</span>}
                            </button>
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-danger" onClick={this.handleDeleteClicked}>
                                Delete WOD
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
    )
  }
}
