import Parse from 'parse'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { clonePlan, getModeString } from '../../Utils'
import Select from 'react-select'

const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' }
]

const options1 = [
  { value: 'Get Started', label: 'Get Started' },
  { value: 'Improve Technique', label: 'Improve Technique' },
  { value: 'Lose Weight', label: 'Lose Weight' },
  { value: 'Enhance Speed', label: 'Enhance Speed' },
  { value: 'Improve Endurance', label: 'Improve Endurance' },
  { value: 'Individual Medley Training', label: 'Individual Medley Training' },
  { value: 'Open Water Race', label: 'Open Water Race' },
  { value: '5 km Open Water Race', label: '5 km Open Water Race' },
  { value: '10km Open Water Race', label: '10km Open Water Race' }
]

export default class TrainingPlanHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      plan: props.plan,
      plans: [],
      isEditing: props.isEditing,
      skillLevel: [],
      goal: [],
      nextPlan: null,
      isLoading: false,
      className: props.isAdmin ? 'AdminTrainingPlan' : 'TrainingPlan'
    }
    this.mainImageFileInput = React.createRef()
    this.videoThumbnailFileInput = React.createRef()
    this.loadTrainingPlans()
  }

  componentDidMount () {
    if (!this.state.newMode) {
      this.setState({
        skillLevel: this.state.plan.get('skillLevel'),
        goal: this.state.plan.get('goal')
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.nextPlan != this.props.nextPlan) {
      this.setState({
        nextPlan: {
          label: this.props.nextPlan.get('name'),
          value: this.props.nextPlan.get('objectId')
        }
      })
    }
  }

  loadTrainingPlans = () => {
    console.log('loadTrainingPlans')
    const plan = new Parse.Object('AdminTrainingPlan')
    const query = new Parse.Query(plan)
    query.limit(100)
    query.descending('createdAt')
    console.log(Parse.User.current().get('sessionToken'))
    query.find({ sessionToken: Parse.User.current().get('sessionToken') }).then(
      (results) => {
        const plans = results
        const totalCount = plans.length
        if (!plans) {
          alert('No training plans found.')
          return
        }

        console.log('got results, count: ' + totalCount)

        const sanitizedPlans = plans.map((plan) => {
          if (!plan) {
            return {
              title: '⚠️ ERROR: MISSING PLAN ⚠️'
            }
          }
          return {
            objectId: plan.id,
            title: plan.get('name'),
            duration: plan.get('trainingPlanWeeks')
              ? plan.get('trainingPlanWeeks').length
              : 0,
            planId: plan.id,
            mode: plan.get('mode')
          }
        })

        console.log(sanitizedPlans)

        this.setState({
          plans: sanitizedPlans.filter(
            (item) => item.objectId != this.state.plan.id
          ),

          isLoading: false
        })
      },
      (error) => {
        alert('Error getting plans: ' + error.message)
      }
    )
  }

  toggleHidden = () => {
    const plan = this.state.plan
    const currentValue = !!plan.get('isHidden')
    plan.set('isHidden', !currentValue)
    this.setState({ plan })
  }

  handleEditInfoClicked = async (event) => {
    event.preventDefault()
    const { goal, skillLevel, nextPlan } = this.state

    if (this.state.isEditing) {
      if (skillLevel && skillLevel.length == 0) {
        alert('SkillLevel is required!')
        return
      } else if (goal && goal.length == 0) {
        alert('Goal is required!')
        return
      } else if (!nextPlan) {
        alert('NextPlan is required!')
        return
      }

      // let nextLevelPointer = {
      //   __type: "Pointer",
      //   className: props.isAdmin ? "AdminTrainingPlan" : "TrainingPlan",
      //   objectId: this.state.nextPlan.value,
      // };
      // save
      console.log(
        'saving plan with new name: ' +
        this.state.newName +
        ' and new skilllevel: ' +
        this.state.skillLevel +
        ' and new secondary goal ' +
        this.state.goal +
        ' and new tertiary nextPlan ' +
        this.state.nextPlan.value
      )

      // return;
      this.state.plan.set('isAllStrength', this.state.isAllStrength)

      this.state.plan.set('SkillLevel', this.state.skillLevel)
      this.state.plan.set('Goal', this.state.goal)

      if (this.state.nextPlan.value) {
        const nextPlanObject = new Parse.Object('AdminTrainingPlan')
        nextPlanObject.id = this.state.nextPlan.value
        this.state.plan.set('nextPlan', nextPlanObject)
      }

      this.state.plan.set(
        'requiresDynamicExerciseTypes',
        this.state.requiresDynamicExerciseTypes
      )
      if (this.state.newName) this.state.plan.set('name', this.state.newName)
      if (this.state.newDescription) { this.state.plan.set('description', this.state.newDescription) }
      if (this.state.newDescriptionSecondary) {
        this.state.plan.set(
          'descriptionSecondary',
          this.state.newDescriptionSecondary
        )
      }
      if (this.state.newDescriptionTertiary) {
        this.state.plan.set(
          'descriptionTertiary',
          this.state.newDescriptionTertiary
        )
      }
      if (this.state.newMode) {
        this.state.plan.set('mode', this.state.newMode)
        if (this.state.featuredPlan) { this.state.featuredPlan.set('mode', this.state.newMode) }
      }
      if (this.mainImageFileInput.current.files[0]) {
        const img = await this.uploadImage(this.mainImageFileInput.current.files[0])
        if (img) this.state.plan.set('image', img)
      }
      if (this.videoThumbnailFileInput.current.files[0]) {
        try {
          const img = await this.uploadImage(this.videoThumbnailFileInput.current.files[0])
          if (img) this.state.plan.set('videoThumbnail', img)
        } catch (error) {
          console.error(error)
          window.alert(error)
        }
      }
      this.state.plan.save().then((success, error) => {
        if (this.state.featuredPlan) {
          console.log('Saving featured plan...')
          this.state.featuredPlan.save().then((success, error) => {
            console.log('Saved featured plan')
            this.setState({
              isEditing: false
            })
          })
        } else {
          this.setState({
            isEditing: false
          })
        }
      })

      this.props.refreshData()
    } else {
      this.setState({
        isEditing: true
      })
    }
  }

  async uploadImage (file) {
    console.log('file is: ' + file.name)
    const img = await this.loadImage(file)

    if (img.width > 1500 || img.height > 1500) {
      alert('This image is too big! Max allowed size is 1500x1500')
      return
    }
    // checks passed, upload image
    const parseImage = new Parse.File(file.name, file)
    await parseImage.save()
    return parseImage
  }

  async loadImage (file) {
    const img = new Image()
    img.src = window.URL.createObjectURL(file)
    await img.decode()
    return img
  }

  onNameChange = (e) => {
    const selectedName = e.target.value
    this.setState({
      newName: selectedName
    })
  }

  onVideoUrlChange = (e) => {
    const newUrl = e.target.value
    if (newUrl) {
      this.state.plan.set('videoUrl', newUrl)
    } else {
      this.state.plan.unset('videoUrl')
    }
  }

  onDescriptionChange = (e) => {
    const selectedDescription = e.target.value
    this.setState({
      newDescription: selectedDescription
    })
  }

  onDescriptionSecondaryChange = (e) => {
    const selectedDescriptionSecondary = e.target.value
    this.setState({
      newDescriptionSecondary: selectedDescriptionSecondary
    })
  }

  onDescriptionTertiaryChange = (e) => {
    const selectedDescriptionTertiary = e.target.value
    this.setState({
      newDescriptionTertiary: selectedDescriptionTertiary
    })
  }

  onStrengthChange = (event) => {
    console.log('onStrengthChange: ' + event.target.checked)
    this.setState({
      isAllStrength: event.target.checked
    })
  }

  onDynamicExerciseChange = (event) => {
    console.log('onDynamicExerciseChange: ' + event.target.checked)
    this.setState({
      requiresDynamicExerciseTypes: event.target.checked
    })
  }

  handleModeChange = (event) => {
    this.setState({
      newMode: parseInt(event.target.value),
      isAllStrength: event.target.value === '3'
    })
  }

  handleChange = (mode, selectedOption) => {
    console.log('test', mode, selectedOption)
    this.setState({ [mode]: selectedOption }, () =>
      console.log('Option selected:', this.state.selectedOption)
    )
  }

  handleCloneHit = async (event) => {
    event.preventDefault()
    const newPlan = await clonePlan(this.state.plan)
    const root = newPlan.className.includes('Admin')
      ? 'adminplans'
      : 'trainingplans'
    window.location.href = '/#/' + root + '/' + newPlan.id
    window.location.reload()
  }

  renderEditing = () => {
    const plan = this.state.plan

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <b>Title</b>
            <br />
            <textarea
              rows="1"
              cols="50"
              value={this.state.newName ? this.state.newName : plan.get('name')}
              onChange={this.onNameChange}
            ></textarea>
          </div>
        </div>
        <div className="row justify-content-right">
          <div className="col">
            <button
              className="btn btn-success float-right"
              onClick={this.handleEditInfoClicked}
            >
              {this.state.isEditing ? <span>Done</span> : <span>Edit</span>}
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="form-group">
            <input id="isHiddenCheckbox" type="checkbox" checked={plan.get('isHidden')} onChange={this.toggleHidden} />
            <label htmlFor="isHiddenCheckbox">&nbsp;Is Hidden</label>
            <br/>
            <label htmlFor="content_url">
              <b>Mode</b>
            </label>
            <select
              className="custom-select"
              value={
                this.state.newMode
                  ? this.state.newMode
                  : this.state.plan.get('mode')
              }
              onChange={this.handleModeChange}
            >
              <option selected>--</option>
              <option value="1">Pool</option>
              {/* <option value="2">Open Water</option> */}
              <option value="3">Dryland</option>
              <option value="4">Mixed (swim + dryland)</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <label htmlFor="content_url">
              <b>skillLevels </b>
            </label>
            <Select
              value={
                this.state.newMode
                  ? this.state.skillLevel
                  : this.state.plan.get('skillLevel')
              }
              onChange={(value) => this.handleChange('skillLevel', value)}
              options={options}
              isMulti
              name="skillLevel"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="content_url">
              <b>goals </b>
            </label>
            <Select
              value={
                this.state.newMode
                  ? this.state.goal
                  : this.state.plan.get('goal')
              }
              onChange={(value) => this.handleChange('goal', value)}
              options={options1}
              isMulti
              name="goal"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="content_url">
              <b>nextPlan </b>
            </label>
            <Select
              value={this.state.nextPlan}
              onChange={(value) => this.handleChange('nextPlan', value)}
              options={this.state.plans.map((item) => {
                return {
                  label: item.title || 'NIL',
                  value: item?.objectId
                }
              })}
              name="nextPlan"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <label>
              <input
                disabled={!this.state.isEditing}
                type="checkbox"
                checked={this.state.plan.get('requiresDynamicExerciseTypes')}
                onChange={this.onDynamicExerciseChange}
              />
              &nbsp; <b>Requires Dynamic Exercise Types</b>{' '}
              <span className="text-muted">
                Does this plan require dynamic exercise types (Entered after
                April 15th, 2020)?
              </span>
            </label>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <b>Headline</b>
            <br />
            <textarea
              rows="1"
              cols="100"
              value={
                this.state.newDescription
                  ? this.state.newDescription
                  : plan.get('description')
              }
              onChange={this.onDescriptionChange}
            ></textarea>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <b>Who&apos;s it for?</b>
            <br />
            <textarea
              rows="3"
              cols="100"
              value={
                this.state.newDescriptionSecondary
                  ? this.state.newDescriptionSecondary
                  : plan.get('descriptionSecondary')
              }
              onChange={this.onDescriptionSecondaryChange}
            ></textarea>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <b>How does it work?</b>
            <br />
            <textarea
              rows="3"
              cols="100"
              value={
                this.state.newDescriptionTertiary
                  ? this.state.newDescriptionTertiary
                  : plan.get('descriptionTertiary')
              }
              onChange={this.onDescriptionTertiaryChange}
            ></textarea>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <b>Training Plan Main Image: </b>
            <input type="file" ref={this.mainImageFileInput} />
          </div>
          <br/>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label><b>Video thumbnail image</b></label>
              <input type="file" ref={this.videoThumbnailFileInput} />
            </div>
            <br/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <b>Video URL: </b>
            <textarea
              rows="1"
              cols="50"
              value={plan.get('videoUrl')}
              onChange={this.onVideoUrlChange}
              placeholder="https://s3.amazon.com/video.mp4"
            ></textarea>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
      </div>
    )
  }

  renderNotEditing = () => {
    const plan = this.state.plan
    return (
      <div className="container">
        <div className="row justify-content-between">
          <h1 className="col-md-10">
            {plan.get('name')}&nbsp;
            <small className="text-muted">{plan.id}</small>
          </h1>
          <div className="col-md-2">
            <button
              className="btn btn-outline-secondary float-right"
              onClick={this.handleCloneHit}
            >
              Clone Plan
            </button>
          </div>
        </div>
        <div className="row justify-content-right">
          <div className="col">
            <button
              className="btn btn-secondary float-right"
              onClick={this.handleEditInfoClicked}
            >
              {this.state.isEditing ? <span>Done</span> : <span>Edit</span>}
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <b>Mode:</b>
            <p className="text-muted">{getModeString(plan.get('mode'))}</p>
          </div>
          <div className="col">
            <b>SkillLevel:</b>
            <p className="text-muted">
              {plan &&
                plan.get('skillLevel') &&
                plan.get('skillLevel').map((item) => item.label + ',')}
            </p>
          </div>

          <div className="col">
            <b>Goals:</b>
            <p className="text-muted">
              {plan &&
                plan.get('goal') &&
                plan.get('goal').map((item) => item.label + ',')}
            </p>
          </div>

          <div className="col">
            <b>NextPlan:</b>
            <p className="text-muted">
              {this.props.nextPlan && this.props.nextPlan.get('name')}
            </p>
          </div>

          <div className="col">
            <b>Visibility</b>
            <br />
            <p className="text-muted">{plan.get('isHidden') ? 'Hidden' : 'Visible'}</p>
          </div>

          <div className="col">
            <b>AI Model</b>
            <br />
            <code className="text-muted">{plan.get('aiModel') || ''}</code>
          </div>

        </div>

        <div className="row">
          <div className="col-md-12">
            <label>
              <input
                disabled={!this.state.isEditing}
                type="checkbox"
                checked={this.state.plan.get('requiresDynamicExerciseTypes')}
                onChange={this.onDynamicExerciseChange}
              />
              &nbsp; <b>Requires Dynamic Exercise Types</b>{' '}
              <span className="text-muted">
                Does this plan require dynamic exercise types (Entered after
                April 15th, 2020)?
              </span>
            </label>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <b>Headline</b>
            <br />
            <p className="text-muted">{plan.get('description')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <b>Who&apos;s it for?</b>
            <br />
            <p className="text-muted">{plan.get('descriptionSecondary')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <b>How does it work?</b>
            <br />
            <p className="text-muted">{plan.get('descriptionTertiary')}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <b>AI Prompt</b>
            <br/>
            <p className="text-muted">
              {plan.get('aiPrompt')}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <b>Image:</b>
            <br />
            <img
              className="img img-fluid"
              src={plan.get('image')?.url() || 'None'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <b>Video Thumbnail:</b><br />
            <p hidden={plan.get('videoThumbnail')}>⚠️ No thumbnail</p>
            <img className="img-fluid" src={plan.get('videoThumbnail') ? plan.get('videoThumbnail').url() : ''} />
          </div>
          <div className="col">
            <b>Video:</b><br />
            {plan.get('videoUrl') ? (<video src={plan.get('videoUrl')} controls className="embed-responsive"></video>) : (<span className="text-muted">No video</span>)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <div>
        {this.state.isEditing ? this.renderEditing() : this.renderNotEditing()}
      </div>
    )
  }
}
