import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'

export default class MemberListComponent extends React.Component {
  constructor (props) {
    super(props)

    const slicedUrl = window.location.href.split('?q=')

    this.loadContent = this.loadContent.bind(this)
    if (slicedUrl.length > 1) {
      const query = decodeURI(slicedUrl[1])
      this.state = {
        searchText: query
      }
      this.performSearch(query)
    } else {
      this.state = {
      }
      this.loadContent()
    }
  }

  loadContent = () => {
    const content = new Parse.Object('_User')
    const query = new Parse.Query(content)
    query.descending('createdAt')
    query.include('userInfo_v3')
    query.find().then((results) => {
      this.setState({
        members: results
      })
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  numberOfResultsText = () => {
    if (!this.state.members) return (<i>Loading...</i>)
    if (this.state.members.length === 100) return (<i>100+ results</i>)
    return (<i>{this.state.members.length} results</i>)
  }

  handleSearchChange = (event) => {
    this.setState({
      searchText: event.target.value
    })
  }

  handleSearch = (event) => {
    event.preventDefault()

    const query = this.state.searchText
    if (query && query.length > 0) {
      window.location.href = '/#/members?q=' + encodeURI(query)
    } else {
      window.location.href = '/#/members'
    }
    window.location.reload()
  }

  performSearch = (text) => {
    console.log('[MemberListComponent] Searching ' + text)

    const query = this.createSearchQuery(text)
    if (!query) {
      window.alert('Use keys for searching.')
      return
    }
    query.find().then((results) => {
      console.log('[MemberListComponent] Got ' + results.length + ' results.')
      const sorted = results.sort((lhs, rhs) => {
        if (lhs.get('createdAt') < rhs.get('createdAt')) {
          return 1
        } else if (lhs.get('createdAt') > rhs.get('createdAt')) {
          return -1
        }
        return 0
      })
      this.setState({
        members: results
      })
    }, (error) => {
      window.alert('Error searching. ' + error.message)
    })
  }

  createSearchQuery = (text) => {
    const searchDict = {}
    const words = text.split(' ')
    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      const isTermValid = word.split(':').length > 1
      if (word.startsWith('email:') && isTermValid) {
        searchDict.email = word.split(':')[1]
      } else if (word.startsWith('emailStartsWith:') && isTermValid) {
        searchDict.emailStartsWith = word.split(':')[1]
      } else if (word.startsWith('first:') && isTermValid) {
        searchDict.first = word.split(':')[1]
      } else if (word.startsWith('last:') && isTermValid) {
        searchDict.last = word.split(':')[1]
      } else if (word.startsWith('id:') && isTermValid) {
        searchDict.id = word.split(':')[1]
      } else if (word.startsWith('goid:') && isTermValid) {
        searchDict.googleOrderId = word.split(':')[1]
      }
    }
    if (Object.keys(searchDict).length === 0) {
      return null
    }

    // currently supported token types:
    // - user email (User table)
    // - user objectId (User table)
    // - user first name (UserInfo table)
    // - user last name (UserInfo table)
    const userType = new Parse.Object('_User')
    const userInfoType = new Parse.Object('UserInfo_v3')
    const subscriptionType = new Parse.Object('Subscription_v4')

    const query = new Parse.Query(userType)
    query.include(['userInfo_v3', 'subscription_v4'])

    if (searchDict.emailStartsWith) {
      console.log('[MemberListComponent] Query: email starts with: ' + searchDict.emailStartsWith)
      const tokenRegex = '^(' + searchDict.emailStartsWith + ')$'
      query.matches('email', tokenRegex, 'i')
    }
    if (searchDict.email) {
      console.log('[MemberListComponent] Query: email is: ' + searchDict.email)
      query.equalTo('email', searchDict.email)
    }
    if (searchDict.id) {
      console.log('[MemberListComponent] Query: id is exactly: ' + searchDict.id)
      query.equalTo('objectId', searchDict.id)
    }
    if (searchDict.first || searchDict.last) {
      const userInfoQuery = new Parse.Query(userInfoType)

      if (searchDict.first) {
        console.log('[MemberListComponent] Query: firstName starts with: ' + searchDict.first)
        const tokenRegex = '^(' + searchDict.first + ')$'
        userInfoQuery.matches('firstName', tokenRegex, 'i')
      }
      if (searchDict.last) {
        console.log('[MemberListComponent] Query: lastName starts with: ' + searchDict.last)
        const tokenRegex = '^(' + searchDict.last + ')$'
        userInfoQuery.matches('lastName', tokenRegex, 'i')
      }

      query.matchesQuery('userInfo_v3', userInfoQuery)
    }
    if (searchDict.googleOrderId) {
      const subQuery = new Parse.Query(subscriptionType)
      subQuery.equalTo('googleOrderId', searchDict.googleOrderId)
      query.matchesQuery('subscription_v4', subQuery)
    }

    return query
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>{'Name'}</th>
                <th className="bg-primary" key={'1h'}>{'Username'}</th>
                <th className="bg-primary" key={'2h'}>{'Account Created ▾'}</th>
            </tr>
    )
  }

  renderTable = () => {
    if (!this.state.members) return ''
    return this.state.members.map((member, index) => {
      return (
               <tr key={index} className="data">
                    <td>
                        <a href={'/#/members/' + member.id}>
                            <b>
                                {member.get('userInfo_v3') ? (member.get('userInfo_v3').get('firstName') || '-') + ' ' + (member.get('userInfo_v3').get('lastName') || '-') : '- -'}
                            </b>
                        </a>
                    </td>
                    <td>
                        { member.get('email') || member.get('username')}
                    </td>
                    <td>
                        {member.get('createdAt').toString()}
                    </td>
               </tr>
      )
    })
  }

  render = () => {
    return (
        <div>
            <Route path="/members">
                <div className="container">
                    <br/>
                    <div>
                        <h1 id="title">Members</h1>
                    </div>
                    <br/>
                    <form>
                        <div className="form-row">
                            <div className="form-group col">
                                <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Search" onChange={this.handleSearchChange} value={this.state.searchText}/>
                                <small id="emailHelp" className="form-text text-muted">Available tags: <code>email:example@gmail.com</code> | <code>id:abc123</code> | <code>first:FirstName</code> | <code>last:LastName</code> | <code>goid:GPA.abc123</code> | <code>emailStartsWith:maybeMyEmailAddressButITypod</code>. You can combine multiple tags.</small>
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary" onClick={this.handleSearch}>Search</button>
                            </div>
                        </div>
                    </form>
                    <table id="featuredcontent" className="data">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>
                    <br/>
                    { this.numberOfResultsText() }
                    <br/>
                </div>
            </Route>
        </div>)
  }
}
