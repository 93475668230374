import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'

export default class EquipmentDetail extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        isLoading: true,
        id: this.props.match.params.id,
        editing: false,
        saving: false,
        numResults: -1,
        existingTags: [],
        existingEquipment: []
      }
      this.loadEquipment()
    } else {
      // When `id` is "new", we are intending to make a new Exercise object.
      // This makes a new one and sets the mode to editing right away
      const equipmentType = new Parse.Object('Equipment')
      this.state = {
        isLoading: false,
        equipment: equipmentType,
        editing: true,
        saving: false,
        numResults: -1,
        existingTags: [],
        existingEquipment: []
      }
    }

    this.imageInput = React.createRef()
    this.loadEquipment()
  }

  loadEquipment = () => {
    const equipmentType = new Parse.Object('Equipment')
    const query = new Parse.Query(equipmentType)
    query.equalTo('objectId', this.state.id)
    console.log('load equipment with id: ' + this.state.id)
    query.first().then((result) => {
      this.setState({
        equipment: result,
        isLoading: false
      })
    }, (error) => {
      console.log(error)
      window.alert('Error fetching equipment')
    })
  }

  handleEdit = () => {
    const newEditing = !this.state.editing
    this.setState({
      editing: newEditing
    })
  }

  handleSave = async (event) => {
    event.preventDefault()
    this.setState({
      saving: true
    })
    await this.saveEquipment()
    if (this.state.id) {
      window.location.reload()
    } else {
      window.location.href = '/#/equipment/' + this.state.equipment.id
      window.location.reload()
    }
  }

  saveEquipment = async () => {
    if (this.state.equipment.get('enumValue') === undefined) {
      this.state.equipment.set('enumValue', this.state.numResults)
    }
    const image = await this.saveImage(this.imageInput)
    if (image) {
      this.state.equipment.set('image', image)
    }
    return await this.state.equipment.save()
  }

  handleCancel = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  handleNameChange = (event) => {
    this.state.equipment.set('name', event.target.value)
  }

  handlePurchaseUrlChange = (event) => {
    this.state.equipment.set('purchaseUrl', event.target.value)
  }

  handleTypeChange = (event) => {
    this.state.equipment.set('type', event.target.value)
  }

  handleNew = async (event) => {
    const newKey = prompt('Please enter a key name for this equipment. Careful- you can\'t change it once it\'s set! No spaces allowed.\n\nEx: pull_buoy, fins, medicine_ball')
    if (!newKey) return
    const newName = prompt('Please enter the name of the equipment. Members will see this name.')
    if (!newName) return
    const newObj = new Parse.Object('Equipment')
    newObj.set('key', newKey)
    newObj.set('name', newName)
    await newObj.save()
    window.location.href = '/#/equipment/' + newObj.id
    window.location.reload()
  }

  saveImage = async (fileInput, requirements) => {
    if (!fileInput.current.files[0]) {
      console.log('No image to save.')
      return new Promise((resolve, reject) => { resolve() })
    }

    const file = fileInput.current.files[0]
    console.log('file is: ' + file.name)
    const img = new Image()

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        if (requirements && !requirements(img)) {
          reject(new Error('Image fails requirements'))
          return
        }

        // checks passed, upload image
        const parseImage = new Parse.File(file.name, file)
        parseImage.save().then((fileSuccess, fileError) => {
          if (!fileSuccess) {
            console.log('Error saving Image. ' + fileError)
            reject(fileError)
            return
          }

          console.log('Successfully saved image:' + parseImage.url())
          resolve(parseImage)
        }, (error) => {
          console.log('Error saving Image. ' + error)
          reject(error)
        })
      }
    })

    img.src = window.URL.createObjectURL(file)
    return promise
  }

  getEditButtons = () => {
    if (this.state.editing) {
      return (
        <div>
            <button className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving}>Save</button>
        </div>
      )
    }

    return (
      <div>
          <button type="button" className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>
          &nbsp;&nbsp;
          <button type="button" className="btn btn-primary" onClick={this.handleNew}>New</button>
      </div>
    )
  }

  renderNotEditing = (equipment) => {
    return (
      <div className="container">
        <br/>
        <div className="row">
          <div className="col">
            <h1>
              <img src={equipment.get('image').url()} className="img-fluid" height="40px" width="40px"/>
              {' ' + equipment.get('name')} <small className="text-muted">{equipment.id}</small>
            </h1>
          </div>
          <div className="col text-right">
            {this.getEditButtons()}
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col">
            <p>
              <b>Key:</b><br/>
              <span className="text-muted"><code>{equipment.get('key')}</code></span>
            </p>
            <p>
              <b>Type:</b><br/>
              <span className="text-muted">{equipment.get('type')}</span>
            </p>
            <p><b>Purchase link:</b><br/>
            <span className="text-muted"><a href={equipment.get('purchaseUrl')}>{equipment.get('purchaseUrl')}</a></span></p>
          </div>
        </div>
      </div>
    )
  }

  renderEditing = (equipment) => {
    return (
            <div className="container">
                <br/>
                <span className="text-muted">{equipment.get('key')}</span>
                <br/>
                <hr/>
                <form>
                    <div className="form-group">
                      <label htmlFor="file_upload"><b>Image</b> <small className="text-muted">If different from current</small></label><br/>
                      <input type="file" ref={this.imageInput} id="file_upload"/>
                    </div>
                    <div className="form-group">
                      <label><b>Name</b></label>
                      <input type="text" className="form-control" placeholder={equipment.get('name')} defaultValue={equipment.get('name')} onChange={this.handleNameChange}/>
                    </div>
                    <br/>
                    <div className="form-group">
                      <label><b>Type</b></label>
                      <select className="custom-select" value={equipment.get('type')} onChange={this.handleTypeChange}>
                          <option value={undefined}>--</option>
                          <option value="swim">Swim</option>
                          <option value="dryland">Dryland</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label><b>Purchase URL</b></label>
                      <input type="text" className="form-control" placeholder={equipment.get('purchaseUrl')} defaultValue={equipment.get('purchaseUrl')} onChange={this.handlePurchaseUrlChange}/>
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving} >Save</button>
                    <br/><br/>
                    <button type="submit" className="btn btn-secondary" onClick={this.handleCancel} disabled={this.state.saving} >Cancel</button>
                </form>
            </div>
    )
  }

  render = () => {
    if (this.state.equipment === undefined) {
      return (<div>Loading...</div>)
    }
    const video = this.state.equipment

    return this.state.editing ? this.renderEditing(video) : this.renderNotEditing(video)
  }
}
