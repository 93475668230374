import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class TipOfTheDayListComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      isAdding: false,
      videos: []
    }

    this.loadVideos = this.loadVideos.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.fileInput = React.createRef()

    this.loadVideos()
  }

  filteredVideos = () => {
    const filter = this.state.filter
    if (filter && filter !== '') {
      const lowercaseFilter = filter.toLowerCase()
      return this.state.videos.filter((video) => {
        const tagMatch = video.tags.map(tag => tag.toLowerCase()).includes(lowercaseFilter)
        const titleMatch = video.title.toLowerCase().includes(lowercaseFilter)
        return titleMatch || tagMatch
      })
    }
    return this.state.videos
  }

  loadVideos = () => {
    console.log('loadVideos')

    Parse.Cloud.run('getTipOfTheDayList', {}).then((results) => {
      const videos = results.tips
      console.log(results)
      console.log('got result for videos')
      const totalCount = videos.count
      if (!videos) {
        alert('No videos found.')
        return
      }
      this.setState({
        videos: videos,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting videos: ' + error.message)
    })
  }

  handleVideoClicked = (objectId, index) => {
    const url = '/#/tipofthedays/' + objectId
    window.open(url)
  }

  handleNewVideoClicked = (event) => {
    window.open('/#/tipofthedays/new')
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value
    })
  }

  renderTable = () => {
    return this.filteredVideos().map((video, index) => {
      const { objectId, title, description, date, tags, imageUrl } = video
      return (
        <tr key={index} className="data">
          <td>
            <div className="row">
              <div className="col-sm-3">
                <img className="img-fluid" src={imageUrl} style={{ maxHeight: 50 }}></img>
              </div>
              <div className="col-sm-9">
                {date}
              </div>
            </div>
          </td>
          <td>
            <div className="col-sm-9">
                <b><a href={'/#/tipofthedays/' + objectId}>{title}</a></b><br/>
                <span className="text-muted">{description}</span>
              </div>
          </td>
          <td>{tags ? tags.join(', ') : ''}</td>
        </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
      <tr key={'0-header'} className="data">
          <th className="bg-primary" key={'0h'}>Date</th>
          <th className="bg-primary" key={'1h'}>Title</th>
          <th className="bg-primary" key={'2h'}>Tags</th>
      </tr>
    )
  }

  render = () => {
    console.log('render featured content table component')
    return (
        <div>
            <Route path="/tipofthedays">
                <br/>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-4">
                            <h1 id="title">Tip of the Day</h1>
                        </div>
                        <div className="col-md-4 text-right">
                            <button className="btn btn-primary" onClick={this.handleNewVideoClicked}>New</button>
                        </div>
                    </div>
                    <div>
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control" id="labelFilter" placeholder="Filter" onChange={this.handleFilterChange}/>
                            </div>
                        </form>
                    </div>

                    <div className="table-responsive">
                        <table id="videos" className="data table-display">
                            <tbody>
                                {this.renderTableHeader()}
                                {this.renderTable()}
                            </tbody>
                        </table>
                    </div>
                    { this.state.isLoading &&
                        <div>Loading...</div>
                    }
                </div>
            </Route>
        </div>)
  }
}
