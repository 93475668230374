import React from 'react'
import Parse from 'parse'
import Config from './config'
import {
  Redirect
} from 'react-router-dom'

export default class LoginForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      history: this.props.history
    }

    this.enterPressed = this.enterPressed.bind(this)
  }

  changeEnvironmentToDev = () => {
    Config.setEnvironment('dev')
    window.location.reload(false)
  }

  changeEnvironmentToProd = () => {
    Config.setEnvironment('prod')
    window.location.reload(false)
  }

  handleSubmitClick = (e) => {
    e.preventDefault()
    console.log('handle submit click')

    const history = this.state.history

    Parse.User.logIn(this.state.email, this.state.password).then(async function (user) {
      console.log('Signed in!')
      const roleQuery = new Parse.Query(Parse.Role)
      roleQuery.equalTo('name', 'Admin')
      const role = await roleQuery.first()
      const adminUserQuery = role.get('users').query()
      const users = await adminUserQuery.find()
      const userIds = users.map((user) => { return user.id })
      if (!userIds.includes(user.id)) {
        console.log('Unauthorized')
        await Parse.User.logOut()
      } else {
        history.push('/')
      }
    }, function (error) {
      alert('Login failed: ' + error.message)
    })
  }

  handleEmailTextFieldChange = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  handlePasswordTextFieldChange = (event) => {
    this.setState({
      password: event.target.value
    })
  }

  enterPressed = (e) => {
    const code = e.keyCode || e.which
    if (code === 13) { // 13 is the enter keycode
      this.handleSubmitClick(e)
    }
  }

  render = () => {
    if (this.state.redirect) {
      return <Redirect push to="/" />
    }
    return (
            <div className="container">
                <form id="loginform">
                    <div className="form-group">
                        <label type="email">Email</label>
                        <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="@myswimpro.com email" autoComplete="username" onChange={this.handleEmailTextFieldChange} />
                    </div>
                    <div className="form-group">
                        <label type="password">Password</label>
                        <input type="password" className="form-control" autoComplete="current-password" id="password" placeholder="Password" onChange={this.handlePasswordTextFieldChange} onKeyPress={this.enterPressed}/>
                        <small className="form-text text-muted"><button className="btn btn-link" id="forgot">Forgot password?</button></small>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={this.handleSubmitClick}>Submit</button>
                </form>
                <br/>
                <span>Database:</span>
                <div className="nav-item dropdown">
                  <button className="nav-link dropdown-toggle btn-secondary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={ { border: 'none' } }>
                      { Config.getEnvironment() === 'dev' ? '🧪 dev' : 'prod' }
                  </button>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <div className="btn dropdown-item" onClick={ this.changeEnvironmentToProd }>Production Database</div>
                      <div className="btn dropdown-item" onClick={ this.changeEnvironmentToDev }>Development Database</div>
                  </div>
              </div>
            </div>
    )
  }
}
