import Parse from 'parse'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { copyWorkout, cloneWorkout } from '../../Utils'
import Modal from 'react-modal'
import Dropdown from 'react-bootstrap/Dropdown'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

export default class TrainingPlanEntryRow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      trainingPlanEntry: props.trainingPlanEntry,
      trainingPlanWeek: props.trainingPlanWeek,
      trainingPlan: props.trainingPlan,
      weekIndex: props.weekIndex,
      entryIndex: props.entryIndex,
      editingComment: false,
      onCommentChange: props.onCommentChange,
      removeWorkout: props.removeWorkout,
      addWorkoutClicked: props.addWorkoutClicked,
      basePath: props.isAdmin ? '/adminworkouts/' : '/workouts/',
      className: props.isAdmin ? 'AdminWorkout' : 'Workout_v3',
      isAdmin: props.isAdmin,
      isModalOpen: false
    }
  }

  editWorkoutComment = (event) => {
    event.preventDefault()
    if (this.state.editingComment) {
      // saving, propagate change up to plan
      this.state.onCommentChange(
        this.state.weekIndex,
        this.state.entryIndex,
        this.state.currentComment
      )
    }
    this.setState({
      editingComment: !this.state.editingComment
    })
  }

  onCommentChange = (event) => {
    this.setState({
      currentComment: event.target.value
    })
  }

  removeWorkout = (event) => {
    event.preventDefault()
    this.state.removeWorkout(this.state.weekIndex, this.state.entryIndex)
  }

  copyToShortCourse = (inputWorkoutId) => {
    const entry = this.state.trainingPlanEntry
    if (inputWorkoutId === undefined || inputWorkoutId.length !== 10) {
      alert(
        'Please use a 10 character workout ID. ' +
          inputWorkoutId +
          ' is invalid.'
      )
      return
    }
    let destinationWorkout = entry.get('scyWorkout')
    if (destinationWorkout === undefined) {
      destinationWorkout = new Parse.Object(this.state.className)
      destinationWorkout
        .save()
        .then(() => {
          entry.set('scyWorkout', destinationWorkout)
          return entry.save()
        })
        .then(() => {
          return copyWorkout(
            inputWorkoutId,
            destinationWorkout.id,
            this.state.className
          )
        })
        .then(() => {
          this.setState({
            trainingPlanEntry: entry
          })
        })
      return
    }
    copyWorkout(inputWorkoutId, destinationWorkout.id, this.state.className)
  }

  copyToLongCourse = (inputWorkoutId) => {
    const entry = this.state.trainingPlanEntry
    if (inputWorkoutId === undefined || inputWorkoutId.length !== 10) {
      alert(
        'Please use a 10 character workout ID. ' +
          inputWorkoutId +
          ' is invalid.'
      )
      return
    }
    let destinationWorkout = entry.get('lcmWorkout')
    if (destinationWorkout === undefined) {
      destinationWorkout = new Parse.Object(this.state.className)
      destinationWorkout
        .save()
        .then(() => {
          entry.set('lcmWorkout', destinationWorkout)
          return entry.save()
        })
        .then(() => {
          return copyWorkout(
            inputWorkoutId,
            destinationWorkout.id,
            this.state.className
          )
        })
        .then(() => {
          this.setState({
            trainingPlanEntry: entry
          })
        })
      return
    }
    copyWorkout(inputWorkoutId, destinationWorkout.id, this.state.className)
  }

  newShortCourseWorkout = (event) => {
    event.preventDefault()
    const entry = this.state.trainingPlanEntry
    const newWorkout = new Parse.Object(this.state.className)
    newWorkout.set('setGroups', [])
    newWorkout
      .save()
      .then(() => {
        entry.set('scyWorkout', newWorkout)
        return entry.save()
      })
      .then(() => {
        this.setState({
          trainingPlanEntry: entry
        })
      })
  }

  newLongCourseWorkout = (event) => {
    const entry = this.state.trainingPlanEntry
    const newWorkout = new Parse.Object(this.state.className)
    newWorkout.set('setGroups', [])
    newWorkout
      .save()
      .then(() => {
        entry.set('lcmWorkout', newWorkout)
        return entry.save()
      })
      .then(() => {
        this.setState({
          trainingPlanEntry: entry
        })
      })
  }

  newEntry = async (event) => {
    event.preventDefault()
    await this.state.addWorkoutClicked(
      this.state.weekIndex,
      this.state.entryIndex + 1
    )
    this.setState({
      trainingPlanEntry: this.state.trainingPlanEntry
    })
  }

  onShortCourseCopyFieldChanged = (event) => {
    console.log(event)
    this.setState({
      shortCourseCopyInput: event.target.value
    })
  }

  onLongCourseCopyFieldChanged = (event) => {
    console.log(event)
    this.setState({
      longCourseCopyInput: event.target.value
    })
  }

  onCopyFromLCMClick = (event) => {
    if (
      !window.confirm(
        "Are you sure you'd like to copy LONG course to SHORT course on this entry?"
      )
    ) {
      return
    }
    this.setState({
      shortCourseCopyInput: event.target.value
    })
    this.copyToShortCourse(event.target.value)
  }

  onCopyFromSCYClick = (event) => {
    if (
      !window.confirm(
        "Are you sure you'd like to copy SHORT course to LONG course on this entry?"
      )
    ) {
      return
    }
    this.setState({
      longCourseCopyInput: event.target.value
    })
    this.copyToLongCourse(event.target.value)
  }

  toggleCompletion = (event) => {
    const isComplete = this.state.trainingPlanEntry.get('lcmDateCompleted') || this.state.trainingPlanEntry.get('scyDateCompleted')
    if (isComplete) {
      this.handleUncomplete(event)
    } else {
      this.handleComplete(event)
    }
  }

  handleUncomplete = (event) => {
    event.preventDefault()

    const ok = window.confirm('Clear completion dates?')
    if (!ok) return

    this.state.trainingPlanEntry.unset('lcmDateCompleted')
    this.state.trainingPlanEntry.unset('scyDateCompleted')
    this.state.trainingPlanEntry.save().then(
      (obj) => {
        this.setState({
          trainingPlanEntry: obj
        })
      },
      (err) => {
        window.alert('Error saving: ' + err.message)
      }
    )
  }

  handleComplete = (event) => {
    event.preventDefault()

    const ok = window.confirm('Mark as complete?')
    if (!ok) return

    const now = new Date()
    this.state.trainingPlanEntry.set('lcmDateCompleted', now)
    this.state.trainingPlanEntry.set('scyDateCompleted', now)
    this.state.trainingPlanEntry.save().then(
      (obj) => {
        this.setState({
          trainingPlanEntry: obj
        })
      },
      (err) => {
        window.alert('Error saving: ' + err.message)
      }
    )
  }

  handleUnskip = (event) => {
    event.preventDefault()

    const ok = window.confirm('Mark entry as not skipped?')
    if (!ok) return

    this.state.trainingPlanEntry.unset('skipped')
    this.state.trainingPlanEntry.save().then(
      (obj) => {
        this.setState({
          trainingPlanEntry: obj
        })
      },
      (err) => {
        window.alert('Error saving: ' + err.message)
      }
    )
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  newFromClone = async (e) => {
    e.preventDefault()
    const { workoutId } = this.state

    if (!workoutId) {
      alert('Workout Id is required!')
      return
    }

    const plan = new Parse.Object(this.state.className)

    const query = new Parse.Query(plan)

    query.equalTo('objectId', workoutId)

    const plans = await query.find()

    if (plans && plans.length > 0) {
      const cloneWorkOut = await cloneWorkout(plans[0])

      const entry = this.state.trainingPlanEntry
      entry.set(
        this.state.mode == 'scy' ? 'scyWorkout' : 'lcmWorkout',
        cloneWorkOut
      )
      entry.save().then(() => {
        this.setState({
          trainingPlanEntry: entry,
          mode: null,
          modalIsOpen: false
        })
      })
    } else {
      alert('Workout Id is not valid!')
    }
  }

  newFromClone = async (e) => {
    e.preventDefault()
    this.setState({
      modalIsOpen: true,
      mode: 'scy'
    })
  }

  generateWorkout = async (e) => {
    e.preventDefault()
    console.log('generate workout...')
    this.setState({ isGeneratingWorkout: true })
    const result = await Parse.Cloud.run('getPlanWorkout', {
      trainingPlanEntryId: this.state.trainingPlanEntry.id,
      trainingPlanWeekId: this.state.trainingPlanWeek.id,
      trainingPlanId: this.state.trainingPlan.id
    })
    console.log(result)
    window.location.href = '/#/workouts/' + result.id
    window.location.reload()
  }

  workoutDetails = (workout, entry) => {
    if (workout) {
      return <div>
        <a href={'#' + this.state.basePath + workout.id}>
          {workout.id}
        </a>
      </div>
    } else {
      const otherWorkout = workout === entry.get('scyWorkout') ? entry.get('lcmWorkout') : entry.get('scyWorkout')
      return <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          { this.state.isGeneratingWorkout ? 'Generating...' : 'None' }
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={this.generateWorkout}>Generate Workout</Dropdown.Item>
          <Dropdown.Item onClick={this.newShortCourseWorkout}>Write Workout</Dropdown.Item>
          <Dropdown.Item onClick={this.newFromClone}>New from clone</Dropdown.Item>
          <Dropdown.Item value={otherWorkout?.id} onClick={this.onCopyFromLCMClick}>Copy From Other Course</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    }
  }

  render = () => {
    const { modalIsOpen } = this.state
    const entry = this.state.trainingPlanEntry
    const currentComment = this.state.currentComment
      ? this.state.currentComment
      : entry.get('comments')
        ? entry.get('comments')
        : ''
    return (
      <tr key={this.state.entryIndex} className="data">
        <td>
          <b>{entry.get('workoutTitle')}</b>
          <p><i>{entry.get('workoutDistance')}</i></p>
          <p>{this.state.editingComment
            ? (<textarea
                rows="1"
                cols="50"
                value={currentComment}
                onChange={this.onCommentChange}></textarea>)
            : (entry.get('comments'))}
          </p>
        </td>
        <td>
          {this.workoutDetails(entry.get('scyWorkout'), entry)}
        </td>
        <td>
          {this.workoutDetails(entry.get('lcmWorkout'), entry)}
        </td>
        <td>
          <small className="text-muted" hidden={!entry.get('skipped')}>
            (Skipped)
          </small>
          <small className="text-muted" hidden={!entry.get('lcmDateCompleted')}>
            LCM Completed
            <br />
          </small>
          <small className="text-muted" hidden={!entry.get('scyDateCompleted')}>
            SCY Completed
          </small>
        </td>
        <td>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={this.editWorkoutComment}>{this.state.editingComment ? 'Save' : 'Edit'} Comment</Dropdown.Item>
              <Dropdown.Item onClick={this.newEntry}>New Entry Below</Dropdown.Item>
              <Dropdown.Item onClick={this.toggleCompletion}>Toggle Completed</Dropdown.Item>
              <Dropdown.Item onClick={this.handleUnskip}>Toggle Skipped</Dropdown.Item>
              <Dropdown.Item onClick={this.removeWorkout}>Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form onSubmit={this.newFromClone}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">{this.state.className} Id </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(event) => {
                  this.setState({
                    workoutId: event.target.value
                  })
                }}
              />
              <small id="emailHelp" className="form-text text-muted">
                please type your workout object id .
              </small>
            </div>
            <button type="submit" className="btn btn-primary">
              Verify & Clone
            </button>
          </form>
        </Modal>
      </tr>
    )
  }
}
