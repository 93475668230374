import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class SkillLevelList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      SkillLevel: []
    }

    this.loadSkillLevel = this.loadSkillLevel.bind(this)

    this.loadSkillLevel()
  }

  loadSkillLevel = () => {
    const SkillLevelType = new Parse.Object('SkillLevel')
    const query = new Parse.Query(SkillLevelType)
    query.limit(1000)
    query.find().then((results) => {
      const SkillLevel = results
      const totalCount = SkillLevel.count
      if (!SkillLevel || SkillLevel.length === 0) {
        alert('No SkillLevel found.')
        return
      }

      this.setState({
        SkillLevel: SkillLevel,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting SkillLevel: ' + error.message)
    })
  }

  filteredSkillLevel = () => {
    const filter = this.state.filter
    if (filter && filter !== '') {
      const lowercaseFilter = filter.toLowerCase()
      return this.state.SkillLevel.filter((SkillLevel) => {
        const tagMatch = SkillLevel.get('key').toLowerCase().includes(lowercaseFilter)
        const nameMatch = SkillLevel.get('name').toLowerCase().includes(lowercaseFilter)
        return nameMatch || tagMatch
      })
    }
    return this.state.SkillLevel
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value
    })
  }

  handleNew = async (event) => {
    const newKey = prompt('Please enter a key name for this SkillLevel. Careful- you can\'t change it once it\'s set! Must be a number.\n\nEx: 1, 2, 43')
    if (!newKey) return
    const newName = prompt('Please enter the name of the SkillLevel. Members will see this name.')
    if (!newName) return
    const newObj = new Parse.Object('SkillLevel')
    newObj.set('key', parseInt(newKey))
    newObj.set('name', newName)
    await newObj.save()
    window.location.href = '/#/skilllevels/' + newObj.id
    window.location.reload()
  }

  renderTable = () => {
    return this.filteredSkillLevel().map((SkillLevel, index) => {
      return (
        <tr key={index} className="data">
            <th scope="row">
              <code>{SkillLevel.get('key')}</code>
            </th>
            <td>
              <b>
                <a href={'/#/skilllevels/' + SkillLevel.id}>
                  {SkillLevel.get('name')}
                </a>
                <br/>
                <span className="text-muted">{SkillLevel.get('description')}</span>
              </b>
            </td>
        </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
      <tr key={'0-header'} className="data">
        <th className="bg-primary" key={'0h'} scope="col">Key</th>
        <th className="bg-primary" key={'1h'} scope="col">Details</th>
      </tr>
    )
  }

  render = () => {
    return (
        <div>
            <Route path="/skilllevels">
              <div className="container">
                <br/>
                <h1>Skill Level</h1>

                <div className="row">
                    <form className="col-sm-6">
                        <div className="form-group">
                            <input type="text" className="form-control" id="labelFilter" placeholder="Filter" onChange={this.handleFilterChange}/>
                        </div>
                    </form>
                    <div className="col-sm-1 offset-md-5">
                      <button className="btn btn-primary" onClick={this.handleNew}>Add</button>
                    </div>
                </div>

                <small className="text-muted"><b>Note:</b> A SkillLevel can not be deleted after being created and it&apos;s meaning should not be significantly modified after being deployed.</small>
                <table id="featuredcontent" className="table table-striped table-hover">
                    <thead>
                      {this.renderTableHeader()}
                    </thead>
                    <tbody>
                      {this.renderTable()}
                    </tbody>
                </table>
              </div>
            </Route>
        </div>)
  }
}
