import React from 'react'
import Parse from 'parse'
import 'bootstrap/dist/css/bootstrap.min.css'

export default class SwimReport extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      completedWorkoutId: ''
    }
  }

  sendReport = (event) => {
    const params = {
      email: this.state.email,
      completedWorkoutId: this.state.completedWorkoutId
    }
    Parse.Cloud.run('sendSwimReport', params).then((results) => {
      window.alert('Email Sent')
    }, (error) => {
      console.log(error.message)
      window.alert('Error sending email')
    })
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  handleCompletedWorkoutIdChange = (event) => {
    this.setState({
      completedWorkoutId: event.target.value
    })
  }

  render = () => {
    return (
            <div className="container">
              <br/>
              <div className="row justify-content-center">
                <div className="col-sm-6">
                  <div className="card">
                      <h5 className="card-header">Swim Report</h5>
                      <br/>
                      <div className="card-body">
                        <input type="text" className="form-control" id="email" placeholder="email" value={this.state.email} onChange={this.handleEmailChange}/>
                        <br/>
                        <input type="text" className="form-control" id="completed_workout_id" placeholder="completed workokut id" value={this.state.completedWorkoutId} onChange={this.handleCompletedWorkoutIdChange}/>
                        <br/>
                        <button type="button" className="btn btn-primary" onClick={this.sendReport}>Send Swim Report</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
    )
  }
}
