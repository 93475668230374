import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'

export default class VideoComponent extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        isLoading: true,
        id: this.props.match.params.id,
        editing: false,
        saving: false,
        existingTags: [],
        existingPlatformFilters: []
      }
      this.loadVideo()
    } else {
      // When `id` is "new", we are intending to make a new video object.
      // This makes a new one and sets the mode to editing right away
      const video = new Parse.Object('Video_v3')
      this.state = {
        isLoading: false,
        video: video,
        editing: true,
        saving: false,
        existingTags: [],
        existingPlatformFilters: []
      }
    }

    this.loadVideo = this.loadVideo.bind(this)
    this.fileInput = React.createRef()
    this.videoInput = React.createRef()
    this.saveImageIfNecessary = this.saveImageIfNecessary.bind(this)
    this.saveVideo = this.saveVideo.bind(this)
    this.loadAllVideos()
  }

  loadVideo () {
    const videoType = new Parse.Object('Video_v3')
    const query = new Parse.Query(videoType)
    query.equalTo('objectId', this.state.id)
    console.log('load video with id: ' + this.state.id)
    query.first().then((result) => {
      this.setState({
        video: result,
        isLoading: false
      })
    }, (error) => {
      console.log(error)
      window.alert('Error fetching video')
    })
  }

  loadAllVideos = () => {
    const videoType = new Parse.Object('Video_v3')
    const query = new Parse.Query(videoType)
    query.limit(1000)
    query.find().then((results) => {
      console.log(results.length + ' existing videos.')
      const existingTags = results.map(x => x.get('tags')).flat()
      const existingPlatformFilters = results.map(x => x.get('platformFilters')).flat()
      this.setState({
        existingTags: Array.from(new Set(existingTags)),
        existingPlatformFilters: Array.from(new Set(existingPlatformFilters))
      })
    }, (error) => {
      alert(error)
    })
  }

  handleEdit = () => {
    const newEditing = !this.state.editing
    this.setState({
      editing: newEditing
    })
  }

  handleSave = (event) => {
    event.preventDefault()
    this.setState({
      saving: true
    })
    let imageFile
    this.saveImageIfNecessary().then(async (image, error) => {
      imageFile = image
      return await this.saveVideoIfNecessary()
    }).then(videoUrl => {
      return this.saveVideo(imageFile, null, videoUrl)
    }).then((success, error) => {
      if (error) {
        alert(error)
      } else if (this.state.id) {
        window.location.reload()
      } else {
        window.location.href = '/#/videos/' + this.state.video.id
        window.location.reload()
      }
    })
  }

  saveVideoIfNecessary = async () => {
    if (!this.videoInput.current.files[0]) {
      return Promise.resolve()
    }

    const data = await this.uploadFileToS3(this.videoInput.current.files[0])
    if (data) {
      console.log('s3 upload ok:')
      console.log(data)
      return data.url.split('?')[0]
    }
  }

  uploadFileToS3 = async (file) => {
    const uploadUrl = await Parse.Cloud.run('getS3VideoUploadLink', {
      fileName: file.name,
      fileType: file.type
    })
    console.log('uploading file...')
    try {
      return fetch(uploadUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file
      }).then((res) => {
        console.log(res)
        return res
      }, (err) => {
        console.error(err)
        window.alert(err.message)
      })
    } catch (err) {
      console.error(err)
      window.alert(err.message)
    }
  }

  saveImageIfNecessary = () => {
    if (!this.fileInput.current.files[0]) {
      return Promise.resolve()
    }

    const file = this.fileInput.current.files[0]
    console.log('file is: ' + file.name)
    const img = new Image()
    img.src = window.URL.createObjectURL(file)
    return new Promise((resolve, reject) => {
      img.onload = () => {
        // target size is 500 x 250
        // max size is 1000 x 500
        // alert when aspect ratio is height > width
        if (img.width > 1000 || img.height > 500) {
          alert('This image is too big! Max allowed size is 1000x500')
          return resolve()
        } else if (img.height > img.width) {
          alert('Image width should be greater than image height')
          return resolve()
        }
        // checks passed, upload image
        const parseImage = new Parse.File(file.name, file)
        console.log('Saving image...')
        parseImage.save().then((fileSuccess, fileError) => {
          if (!fileSuccess) {
            console.log('Error saving image file.')
            resolve()
          }
          console.log('Saved image file.')
          resolve(parseImage)
        })
      }
    })
  }

  saveVideo = (parseImage, videoFile, videoUrl) => {
    if (parseImage) {
      this.state.video.set('image', parseImage)
    }
    if (videoFile) {
      this.state.video.set('videoFile', videoFile)
    }
    if (videoUrl) {
      this.state.video.set('videoUrl', videoUrl)
    }
    return this.state.video.save()
  }

  handleCancel = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  handleTitleChange = (event) => {
    this.state.video.set('title', event.target.value)
  }

  handleDescriptionChange = (event) => {
    this.state.video.set('description', event.target.value)
  }

  handleDescriptionLongChange = (event) => {
    this.state.video.set('descriptionLong', event.target.value)
  }

  handleYoutubeUpdate = async (event) => {
    event.preventDefault()
    const youtubeLink = prompt('Enter the URL of a Youtube video')
    if (!youtubeLink || !youtubeLink.length) return

    try {
      const result = await Parse.Cloud.run('updateVideoFromYoutube', { youtubeUrl: youtubeLink, videoId: this.state.id })
      this.loadAllVideos()
    } catch (error) {
      window.alert(error.message)
    }
  }

  handleURLChange = (event) => {
    this.state.video.set('url', event.target.value)
  }

  handleDurationChange = (event) => {
    this.state.video.set('durationSeconds', parseInt(event.target.value))
  }

  handlePremiumChange = (event) => {
    this.state.video.set('premium', event.target.checked)
    this.setState({
      video: this.state.video
    })
  }

  handleDelete = async (event) => {
    event.preventDefault()
    const ok = confirm('Are you sure you want to delete this video?')
    if (!ok) return
    await this.state.video.destroy()
    window.location.href = '/#/videos'
    window.location.reload()
  }

  handleFocusChange = (event) => {
    this.state.video.set('activityFocus', event.target.value)
    this.setState({
      video: this.state.video
    })
  }

  tagsDescription = () => {
    if (this.state.video.get('tags')) {
      return this.state.video.get('tags').join(', ')
    }
    return 'n/a'
  }

  platformFiltersDescription = () => {
    if (this.state.video.get('platformFilters')) {
      return this.state.video.get('platformFilters').join(', ')
    }
    return 'n/a'
  }

  handleTagsChange = (event) => {
    const newTags = event.target.value.split(',').map(value => value.trim())
    this.state.video.set('tags', newTags)
  }

  handlePlatformFiltersChange = (event) => {
    const newTags = event.target.value.split(',').map(value => value.trim())
    this.state.video.set('platformFilters', newTags)
  }

  focusDescription = () => {
    if (this.state.video.get('activityFocus') === 'swim') {
      return 'Swim'
    } else if (this.state.video.get('activityFocus') === 'dryland') {
      return 'Dryland'
    } else {
      return 'None'
    }
  }

  renderNotEditing = (video) => {
    return (
            <div className="container">
                <br/>
                <div className="row justify-content-between">
                    <div className="col-sm-8">
                        <h1>{video.get('title')}</h1>
                    </div>
                    <div className="col-sm-4 text-right">
                      <button className="btn btn-outline-secondary" onClick={this.handleYoutubeUpdate}>Update From Youtube</button>
                      &nbsp;
                      { this.state.editing ? <button className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving}>Save</button> : <button className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>}
                    </div>
                </div>
                <div className="row">
                  <div className="col">
                    <b>Cover Image:</b><br/>
                    <p hidden={video.get('image')}>⚠️ No cover image</p>
                    <img className="img-fluid" src={video.get('image') ? video.get('image').url() : ''}/>
                  </div>
                  <div className="col">
                    <b>Video:</b><br/>
                    { video.get('videoUrl') ? (<video src={video.get('videoUrl') } controls className="embed-responsive"></video>) : (<span className="text-muted">No video</span>) }
                  </div>
                </div>
                <div className="row">
                    <div className="col">
                        <b>Description: </b>
                        <p className="text-muted">{video.get('description') || 'n/a' }</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <b>Description Long: </b>
                        <p className="text-muted">{video.get('descriptionLong') || 'n/a' }</p>
                    </div>
                </div>
                <div className="jumbotron">
                    <div className="row">
                      <div className="col-sm-3">
                          <p><b>Duration:</b><br/>
                          <span className="text-muted">{video.get('durationSeconds')} seconds</span></p>
                      </div>
                      <div className="col-sm-3">
                          <p><b>Tags:</b><br/>
                          <span className="text-muted">{this.tagsDescription()}</span></p>
                      </div>
                      <div className="col-sm-3">
                          <p><b>Focus:</b><br/>
                          <span className="text-muted">{this.focusDescription()}</span></p>
                      </div>
                      <div className="col-sm-3">
                          <b>Premium Lock:</b><br/>
                          <input className="form-check-input" type="checkbox" checked={video.get('premium')} disabled={!this.state.isEditing} />
                          <label className="form-check-label">
                              Is Premium
                          </label>
                      </div>
                      <div className="col-sm-3">
                          <p><b>Platform Filters:</b><br/>
                          <span className="text-muted">{this.platformFiltersDescription()}</span></p>
                      </div>
                      <div className="col-sm-3">
                        <p><b>Original Youtube URL:</b><br/>
                        <a href={video.get('url') || '#'}>{video.get('url') || 'n/a'}</a></p>
                      </div>
                      <div className="col-sm-3">
                        <p><b>Current video URL:</b><br/>
                        <a href={video.get('videoUrl') || '#'}>{video.get('videoUrl') || 'n/a'}</a></p>
                      </div>
                  </div>
                </div>
                <br/>
                <br/>
                <br/>
                <button className="btn btn-danger" onClick={this.handleDelete}>Delete</button>
                <br/>
                <br/>
                <br/>
            </div>
    )
  }

  renderEditing = (video) => {
    return (
            <div className="container">
                <br/>
                <form>
                    <div className="form-group">
                        <label><b>Title</b></label>
                        <input type="text" className="form-control" placeholder={video.get('title')} defaultValue={video.get('title')} onChange={this.handleTitleChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Description</b></label>
                        <input type="text" className="form-control" placeholder={video.get('description')} defaultValue={video.get('description')} onChange={this.handleDescriptionChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Description Long</b></label>
                        <input type="text" className="form-control" placeholder={video.get('descriptionLong')} defaultValue={video.get('descriptionLong')} onChange={this.handleDescriptionLongChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Youtube URL</b></label>
                        <input type="text" className="form-control" placeholder={video.get('url')} defaultValue={video.get('url')} onChange={this.handleURLChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Video length (seconds)</b></label>
                        <input type="number" className="form-control" placeholder={video.get('durationSeconds')} defaultValue={video.get('durationSeconds')} onChange={this.handleDurationChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Tags</b></label>
                        <input type="text" className="form-control" placeholder={this.tagsDescription()} defaultValue={this.tagsDescription()} onChange={this.handleTagsChange}/>
                        <small className="form-text text-muted">Comma-separated. Existing tags: <b>{this.state.existingTags.join(', ')}</b></small>
                    </div>
                    <div className="form-group">
                        <label><b>Focus</b></label>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="" checked={this.state.video.get('activityFocus') !== 'swim' && this.state.video.get('activityFocus') !== 'dryland'} onChange={this.handleFocusChange}/>
                            <label className="form-check-label" htmlFor="exampleRadios1">
                                None
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="swim" checked={this.state.video.get('activityFocus') === 'swim'} onChange={this.handleFocusChange}/>
                            <label className="form-check-label" htmlFor="exampleRadios2">
                                Swim
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="dryland" checked={this.state.video.get('activityFocus') === 'dryland'} onChange={this.handleFocusChange}/>
                            <label className="form-check-label" htmlFor="exampleRadios3">
                                Dryland
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label><b>Platform filters</b></label>
                        <input type="text" className="form-control" placeholder={this.platformFiltersDescription()} defaultValue={this.platformFiltersDescription()} onChange={this.handlePlatformFiltersChange}/>
                        <small className="form-text text-muted">Comma-separated. Existing filters: <b>{this.state.existingPlatformFilters.join(', ')}</b></small>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" checked={video.get('premium')} onChange={this.handlePremiumChange}/>
                        <label className="form-check-label"><b>Is Premium</b></label>
                        <small className="form-text text-muted">Technique pack required to view this video.</small>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label><b>Cover image</b></label>
                        <br/>
                        <input type="file" ref={this.fileInput} />
                        <small className="form-text text-muted">Only if you want to change it.</small>
                    </div>
                    <div className="form-group">
                        <label><b>Video</b></label>
                        <br/>
                        <input type="file" ref={this.videoInput} />
                        <small className="form-text text-muted">Only if you want to change it.</small>
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving} >Save</button>
                    <br/><br/>
                    <button type="submit" className="btn btn-secondary" onClick={this.handleCancel} disabled={this.state.saving} >Cancel</button>
                </form>
            </div>
    )
  }

  render = () => {
    if (this.state.video === undefined) {
      return (<div>Loading...</div>)
    }
    const video = this.state.video

    return this.state.editing ? this.renderEditing(video) : this.renderNotEditing(video)
  }
}
