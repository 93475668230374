import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'

export default class DrylandExerciseComponent extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        isLoading: true,
        id: this.props.match.params.id,
        editing: false,
        saving: false,
        numResults: -1,
        existingTags: [],
        existingEquipment: []
      }
      this.loadExercise()
    } else {
      // When `id` is "new", we are intending to make a new DrylandExercise object.
      // This makes a new one and sets the mode to editing right away
      const exercise = new Parse.Object('DrylandExercise')
      this.state = {
        isLoading: false,
        exercise: exercise,
        editing: true,
        saving: false,
        numResults: -1,
        existingTags: [],
        existingEquipment: []
      }
    }

    this.loadExercise = this.loadExercise.bind(this)
    this.saveExercise = this.saveExercise.bind(this)
    this.loadAllExercises = this.loadAllExercises.bind(this)

    this.loadAllExercises()
  }

  loadExercise = () => {
    const exerciseType = new Parse.Object('DrylandExercise')
    const query = new Parse.Query(exerciseType)
    query.equalTo('objectId', this.state.id)
    console.log('load exercise with id: ' + this.state.id)
    query.first().then((result) => {
      this.setState({
        exercise: result,
        isLoading: false
      })
    }, (error) => {
      console.log(error)
      window.alert('Error fetching exercise')
    })
  }

  loadAllExercises = () => {
    const exerciseType = new Parse.Object('DrylandExercise')
    const query = new Parse.Query(exerciseType)
    query.limit(1000)
    query.find().then((results) => {
      const numResults = results.length
      console.log(results.length + ' existing exercises.')
      const existingTags = results.map(x => x.get('tags')).flat()
      const existingEquipment = results.map(x => x.get('equipment')).flat()
      this.setState({
        numResults: numResults,
        existingTags: Array.from(new Set(existingTags)),
        existingEquipment: Array.from(new Set(existingEquipment))
      })
    }, (error) => {
      alert(error)
    })
  }

  handleEdit = () => {
    const newEditing = !this.state.editing
    this.setState({
      editing: newEditing
    })
  }

  handleSave = (event) => {
    event.preventDefault()
    this.setState({
      saving: true
    })
    this.saveExercise().then((success, error) => {
      if (error) {
        alert(error)
      } else if (this.state.id) {
        window.location.reload()
      } else {
        window.location.href = '/#/exercises/' + this.state.exercise.id
        window.location.reload()
      }
    })
  }

  saveExercise = () => {
    if (this.state.exercise.get('enumValue') === undefined) {
      this.state.exercise.set('enumValue', this.state.numResults)
    }
    return this.state.exercise.save()
  }

  handleCancel = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  handleNameChange = (event) => {
    this.state.exercise.set('name', event.target.value)
  }

  handleDescriptionShortChange = (event) => {
    this.state.exercise.set('descriptionShort', event.target.value)
  }

  handleDescriptionLongChange = (event) => {
    this.state.exercise.set('descriptionLong', event.target.value)
  }

  handleURLChange = (event) => {
    this.state.exercise.set('youtubeURL', event.target.value)
  }

  handleSwitchChange = (event) => {
    this.state.exercise.set('isAsymmetric', event.target.checked)
  }

  tagsDescription = () => {
    if (this.state.exercise.get('tags')) {
      return this.state.exercise.get('tags').join(', ')
    }
    return ''
  }

  equipmentDescription = () => {
    if (this.state.exercise.get('equipment')) {
      return this.state.exercise.get('equipment').join(', ')
    }
    return ''
  }

  handleTagsChange = (event) => {
    const newTags = event.target.value.split(',').map(value => value.trim())
    this.state.exercise.set('tags', newTags)
  }

  handleEquipmentChange = (event) => {
    const newTags = event.target.value.split(',').map(value => value.trim())
    this.state.exercise.set('equipment', newTags)
  }

  handleDifficultyChange = (event) => {
    const value = parseInt(event.target.value)
    this.state.exercise.set('difficulty', value)
  }

  handleNew = (event) => {
    window.location.href = '/#/exercises/new'
    window.location.reload()
  }

  getEnumValue = () => {
    if (this.state.exercise.get('enumValue') !== undefined) {
      return this.state.exercise.get('enumValue')
    }
    return this.state.numResults
  }

  getEditButtons = () => {
    if (this.state.editing) {
      return (
                <div>
                    <button className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving}>Save</button>
                </div>
      )
    }

    return (
            <div>
                <button type="button" className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-primary" onClick={this.handleNew}>New</button>
            </div>
    )
  }

  renderNotEditing = (exercise) => {
    return (
            <div className="container">
                <br/>
                <div className="row">
                    <div className="col">
                        <h1>{exercise.get('name')}</h1>
                        <small className="text-muted">{'#' + this.getEnumValue()}</small>
                    </div>
                    <div className="col">
                        {this.getEditButtons()}
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col">
                        <p><b>Asymmetric:</b><br/><span className="text-muted">{exercise.get('isAsymmetric') ? 'true' : 'false'}</span></p>
                        <p><b>Short Description:</b><br/><span className="text-muted">{exercise.get('descriptionShort')}</span></p>
                        <p><b>Long Description:</b><br/><span className="text-muted">{exercise.get('descriptionLong')}</span></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p><b>Difficulty:</b><br/><span className="text-muted">{exercise.get('difficulty')}</span></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p><b>Tags:</b><br/><span className="text-muted">{this.tagsDescription()}</span></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p><b>Equipment:</b><br/><span className="text-muted">{this.equipmentDescription()}</span></p>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col">
                        <p><b>Video:</b><br/><a href={exercise.get('youtubeURL')}>{exercise.get('youtubeURL')}</a></p>
                    </div>
                </div>

            </div>
    )
  }

  renderEditing = (exercise) => {
    return (
            <div className="container">
                <br/>
                <small className="text-muted">{'#' + this.getEnumValue()}</small>
                <form>
                    <div className="form-group">
                        <label><b>Name</b></label>
                        <input type="text" className="form-control" placeholder={exercise.get('name')} defaultValue={exercise.get('name')} onChange={this.handleNameChange}/>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" checked={exercise.get('isAsymmetric')} onChange={this.handleSwitchChange}/>
                        <label className="form-check-label"><b>Asymmetric</b> <small className="form-text text-muted">i.e. Requires a switch halfway through the exercise. Ex: Sprinter Skip.</small></label>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label><b>Short Description</b></label>
                        <input type="text" className="form-control" placeholder={exercise.get('descriptionShort')} defaultValue={exercise.get('descriptionShort')} onChange={this.handleDescriptionShortChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Long Description</b></label>
                        <textarea rows="3" type="text" className="form-control" placeholder={exercise.get('descriptionLong')} defaultValue={exercise.get('descriptionLong')} onChange={this.handleDescriptionLongChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Difficulty</b></label>
                        <input type="number" className="form-control" placeholder={exercise.get('difficulty')} defaultValue={exercise.get('difficulty')} onChange={this.handleDifficultyChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Youtube URL</b></label>
                        <input type="text" className="form-control" placeholder={exercise.get('youtubeURL')} defaultValue={exercise.get('youtubeURL')} onChange={this.handleURLChange}/>
                    </div>
                    <div className="form-group">
                        <label><b>Tags</b></label>
                        <input type="text" className="form-control" placeholder={this.tagsDescription()} defaultValue={this.tagsDescription()} onChange={this.handleTagsChange}/>
                        <small className="form-text text-muted">Comma-separated. Existing tags: <b>{this.state.existingTags.join(', ')}</b></small>
                    </div>
                    <div className="form-group">
                        <label><b>Equipment</b></label>
                        <input type="text" className="form-control" placeholder={this.equipmentDescription()} defaultValue={this.equipmentDescription()} onChange={this.handleEquipmentChange}/>
                        <small className="form-text text-muted">Comma-separated. Existing equipment: <b>{this.state.existingEquipment.join(', ')}</b></small>
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving} >Save</button>
                    <br/><br/>
                    <button type="submit" className="btn btn-secondary" onClick={this.handleCancel} disabled={this.state.saving} >Cancel</button>
                </form>
            </div>
    )
  }

  render = () => {
    if (this.state.exercise === undefined) {
      return (<div>Loading...</div>)
    }
    const video = this.state.exercise

    return this.state.editing ? this.renderEditing(video) : this.renderNotEditing(video)
  }
}
