import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import debounce from 'lodash.debounce'
import {
  formatSeconds,
  getSkillLevelString,
  getPoolCourseString,
  cloneWorkout
} from '../../Utils'
import { Link, Route } from 'react-router-dom'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

export default class WODTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      wods: props.wods,
      dateWodMap: {},
      offset: 0,
      totalCount: 0,
      isLoading: true,
      className: 'AdminWorkout',
      isModalOpen: false,
      workoutId: null
    }

    this.loadWODs = this.loadWODs.bind(this)

    // Binds scroll event handler
    window.onscroll = debounce(() => {
      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (
        this.state.error !== undefined ||
        this.state.isLoading ||
        !this.state.hasMore
      ) { return }

      console.log('Loading more...')
      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + Math.floor(document.documentElement.scrollTop) ===
        document.documentElement.offsetHeight
      ) {
        this.loadWODs()
      }
    }, 100)

    this.loadWODs()
  }

  componentWillUnmount = () => {
    window.onscroll = null
  }

  loadWODs = () => {
    console.log('loadWods')
    const skill = this.state.skill
    const course = this.state.course

    const wod = new Parse.Object('WorkoutOfTheDay')
    const query = new Parse.Query(wod)
    query.include(['workout', 'adminWorkout', 'image'])
    query.descending('workoutDate')
    query.withCount(true)
    query.limit(100)
    const currentOffset = this.state.offset
    query.skip(currentOffset)

    query.find().then(
      (results) => {
        const wods = results.results
        const totalCount = results.count
        if (!wods) {
          alert('No wods found.')
          return
        }

        let sanitizedWODs = wods
          .map((wod) => {
            const title = wod.get('title')

            return {
              objectId: wod.id,
              workoutDate: wod.get('workoutDate').toLocaleDateString('en-US'),
              title: title || wod.id, // back up to ID if no title
              workout: wod.get('adminWorkout') || wod.get('workout')
            }
          })
          .filter((wod) => {
            let skillBool = false
            let courseBool = false

            if (skill && skill >= 0) {
              console.log('with skill level:' + parseInt(skill))
              skillBool =
                wod && wod.workout
                  ? wod.workout.get('skillLevel') === parseInt(skill)
                  : false
            } else {
              skillBool = true
            }
            if (course && course >= 0) {
              console.log('with pool course:' + parseInt(course))
              courseBool =
                wod && wod.workout
                  ? wod.workout.get('poolCourse') === parseInt(course)
                  : false
            } else {
              courseBool = true
            }
            return skillBool && courseBool
          })

        for (const wodI in sanitizedWODs) {
          const wod = sanitizedWODs[wodI]
          if (this.state.dateWodMap[wod.workoutDate]) {
            this.state.dateWodMap[wod.workoutDate] =
              this.state.dateWodMap[wod.workoutDate].concat(wod)
          } else {
            this.state.dateWodMap[wod.workoutDate] = [wod]
          }
        }

        if (currentOffset !== 0) {
          sanitizedWODs = this.state.wods.concat(sanitizedWODs)
        }

        let hasMore = false
        if (wods.length === 100) {
          hasMore = true
        }

        // make a 2nd call to loadWODs if on first page, we filtered out more than half
        // the original results. This fixes an issue where the first page is too filtered
        // & doesn't hit the scroll handler to load more, even though there is more data to load
        if (sanitizedWODs.length < 50 && currentOffset === 0) {
          this.setState(
            {
              wods: sanitizedWODs,
              offset: currentOffset + 100,
              hasMore: hasMore,
              isLoading: false,
              totalCount: totalCount
            },
            this.loadWODs
          )
        } else {
          this.setState({
            wods: sanitizedWODs,
            offset: currentOffset + 100,
            hasMore: hasMore,
            isLoading: false,
            totalCount: totalCount
          })
        }
      },
      (error) => {
        alert('Error getting user: ' + error.message)
      }
    )
  }

  handleNewWorkoutClicked = (event) => {
    event.preventDefault()
    console.log('creating new ' + this.state.className)
    const workout = new Parse.Object(this.state.className)
    workout.set('skillLevel', 1) // default to Beginner
    workout.set('workoutType', 1) // default to FREE
    workout.set('poolCourse', 0) // default to SCY
    workout.save().then((success, error) => {
      const wod = new Parse.Object('WorkoutOfTheDay')
      if (this.state.className === 'AdminWorkout') {
        wod.set('adminWorkout', workout)
      } else {
        wod.set('workout', workout)
      }
      wod.set('workoutDate', new Date()) // default to now
      wod.save().then((success, error) => {
        this.props.history.push('/wod/' + wod.id)
      })
    })
  }

  renderWODContent = (wod) => {
    return (
      <td>
        <Link className="btn-link" to={'/wod/' + wod.objectId}>
          {wod.title}
        </Link>
        <br></br>
        Distance: {wod && wod.workout ? wod.workout.get('totalDistance') : '-'}
        <br></br>
        Duration:{' '}
        {wod && wod.workout ? formatSeconds(wod.workout.get('totalTime')) : '-'}
      </td>
    )
  }

  renderExtraWods = (wods) => {
    return wods.map((wod, index) => {
      return (
        <a key={index} href={'#/wod/' + wod.objectId}>
          {index + 1}
        </a>
      )
    })
  }

  renderTable = () => {
    const keys = Object.keys(this.state.dateWodMap)
    if (keys.length === 0) return

    return keys.map((date, index) => {
      const wods = this.state.dateWodMap[date]
      console.log(wods)

      let noviceLCYWOD,
        beginnerLCYWOD,
        intermediateLCYWOD,
        advancedLCYWOD,
        proLCYWOD,
        noviceSCYWOD,
        beginnerSCYWOD,
        intermediateSCYWOD,
        advancedSCYWOD,
        proSCYWOD,
        noviceDRYWOD,
        beginnerDRYWOD,
        intermediateDRYWOD,
        advancedDRYWOD,
        proDRYWOD
      const extraWODs = []

      // Map the WODs we have into the 9 WOD buckets
      for (const i in wods) {
        const wod = wods[i]
        if (!wod || !wod.workout) {
          console.warn('WOD with no workout! ' + (wod ? wod.id : ''))
          extraWODs.push(wod)
          continue
        }
        const skillLevel = wod.workout.get('skillLevel')
        const isAllStrength =
          wod.workout.get('isAllStrength') &&
          wod.workout.get('isAllStrength') === true
        const isLongCourse =
          wod.workout.get('poolCourse') && wod.workout.get('poolCourse') === 2
        const poolLength = isAllStrength ? 0 : isLongCourse ? 50 : 25

        if (skillLevel === 0 && poolLength === 50 && !noviceLCYWOD) {
          noviceLCYWOD = wod
        } else if (skillLevel === 1 && poolLength === 50 && !beginnerLCYWOD) {
          beginnerLCYWOD = wod
        } else if (
          skillLevel === 2 &&
          poolLength === 50 &&
          !intermediateLCYWOD
        ) {
          intermediateLCYWOD = wod
        } else if (skillLevel === 3 && poolLength === 50 && !advancedLCYWOD) {
          advancedLCYWOD = wod
        } else if (skillLevel === 4 && poolLength === 50 && !proLCYWOD) {
          proLCYWOD = wod
        } else if (skillLevel === 0 && poolLength === 25 && !noviceSCYWOD) {
          noviceSCYWOD = wod
        } else if (skillLevel === 1 && poolLength === 25 && !beginnerSCYWOD) {
          beginnerSCYWOD = wod
        } else if (
          skillLevel === 2 &&
          poolLength === 25 &&
          !intermediateSCYWOD
        ) {
          intermediateSCYWOD = wod
        } else if (skillLevel === 3 && poolLength === 25 && !advancedSCYWOD) {
          advancedSCYWOD = wod
        } else if (skillLevel === 4 && poolLength === 25 && !proSCYWOD) {
          proSCYWOD = wod
        } else if (skillLevel === 0 && poolLength === 0 && !noviceDRYWOD) {
          noviceDRYWOD = wod
        } else if (skillLevel === 1 && poolLength === 0 && !beginnerDRYWOD) {
          beginnerDRYWOD = wod
        } else if (
          skillLevel === 2 &&
          poolLength === 0 &&
          !intermediateDRYWOD
        ) {
          intermediateDRYWOD = wod
        } else if (skillLevel === 3 && poolLength === 0 && !advancedDRYWOD) {
          advancedDRYWOD = wod
        } else if (skillLevel === 4 && poolLength === 0 && !proDRYWOD) {
          proDRYWOD = wod
        } else {
          console.warn('Extra wod: ' + wod)
          extraWODs.push(wod)
        }
      }

      return (
        <div className="card" key={index}>
          <div className="card-title padding">
            <b>{date}</b>
            {extraWODs.length > 0
              ? (
              <p>⚠️Extra WODs: {this.renderExtraWods(extraWODs)}</p>
                )
              : (
                  ''
                )}
          </div>
          <div className="card-body table-responsive">
            <table className="table">
              <tbody>
                <tr key={'0-header'} className="data padding">
                  <td className="bg-light" key={'0h'}>
                    Level 1
                  </td>
                  <td className="bg-light" key={'1h'}>
                    Level 2
                  </td>
                  <td className="bg-light" key={'2h'}>
                    Level 3
                  </td>
                  <td className="bg-light" key={'3h'}>
                    Level 4
                  </td>
                  <td className="bg-light" key={'4h'}>
                    Level 5
                  </td>
                </tr>
                <tr key="shortcourse" className="data padding">
                  {noviceSCYWOD
                    ? (
                        this.renderWODContent(noviceSCYWOD)
                      )
                    : (
                    <td className="text-muted">⚠️ None</td>
                      )}
                  {beginnerSCYWOD
                    ? (
                        this.renderWODContent(beginnerSCYWOD)
                      )
                    : (
                    <td className="text-muted">⚠️ None</td>
                      )}
                  {intermediateSCYWOD
                    ? (
                        this.renderWODContent(intermediateSCYWOD)
                      )
                    : (
                    <td className="text-muted">⚠️ None</td>
                      )}
                  {advancedSCYWOD
                    ? (
                        this.renderWODContent(advancedSCYWOD)
                      )
                    : (
                    <td className="text-muted">⚠️ None</td>
                      )}
                  {proSCYWOD
                    ? (
                        this.renderWODContent(proSCYWOD)
                      )
                    : (
                    <td className="text-muted">⚠️ None</td>
                      )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  newFromClone = async (e) => {
    e.preventDefault()
    const { workoutId } = this.state

    if (!workoutId) {
      alert('Workout Id is required!')
      return
    }

    const plan = new Parse.Object('AdminWorkout')

    const query = new Parse.Query(plan)

    query.equalTo('objectId', workoutId)

    const plans = await query.find()

    if (plans && plans.length > 0) {
      const cloneWorkOut = await cloneWorkout(plans[0])
      const workout = new Parse.Object(this.state.className)
      workout.set('skillLevel', 1) // default to Beginner
      workout.set('workoutType', 1) // default to FREE
      workout.set('poolCourse', 0) // default to SCY
      workout.save().then((success, error) => {
        const wod = new Parse.Object('WorkoutOfTheDay')
        if (this.state.className === 'AdminWorkout') {
          wod.set('adminWorkout', cloneWorkOut)
        } else {
          wod.set('workout', cloneWorkOut)
        }
        wod.set('workoutDate', new Date()) // default to now
        wod.save().then((success, error) => {
          this.props.history.push('/wod/' + wod.id)
        })
      })
    } else {
      alert('Workout Id is not valid!')
    }
  }

  render = () => {
    const { modalIsOpen } = this.state

    return (
      <div>
        <Route path="/wods">
          <div className="container">
            <br />
            <div>
              <h1 id="title">Workout of the Day</h1>
              <div className="padding float-right">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    this.setState({
                      modalIsOpen: true
                    })
                  }}
                >
                  New From Clone
                </button>
              </div>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <form onSubmit={this.newFromClone}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> Admin Workout Id </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(event) => {
                        this.setState({
                          workoutId: event.target.value
                        })
                      }}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      please type your workout object id .
                    </small>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Verify & Clone
                  </button>
                </form>
              </Modal>

              <div className="padding float-right">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={this.handleNewWorkoutClicked}
                >
                  New Workout
                </button>
              </div>
            </div>
            <div className="card-footer">
              <form id="userlookup-form" className="form-inline">
                <div className="form-row justify-content-end">
                  <div className="col-md-12">
                    {this.state.totalCount > 0
                      ? (
                      <p>{this.state.totalCount} total unfiltered results</p>
                        )
                      : null}
                  </div>
                </div>
              </form>
            </div>
            {this.renderTable()}
            {this.state.isLoading && <div>Loading...</div>}
          </div>
        </Route>
      </div>
    )
  }
}
