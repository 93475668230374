import React from 'react'
import Parse from 'parse'

export default class LoginForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  readFitFile = (event) => {
    const file = event.target.files[0]

    if (file) {
      this.setState({
        isLoggingFitFile: true
      })
      const reader = new FileReader()
      reader.onload = (e) => {
        const base64 = e.target.result.split(',')[1]
        const params = {
          data: base64
        }
        const fileName = file.name.split('.')[0]
        Parse.Cloud.run('fitFileDebug', params).then((results) => {
          console.log(fileName)
          this.setState({
            output: results,
            isLoggingFitFile: false,
            fileName: fileName
          })
        }, (error) => {
          this.setState({
            isLoggingFitFile: false
          })
          window.alert('Error debugging fit file: ' + error.message)
        })
      }
      reader.onerror = (e) => {
        // error occurred
        console.log('[readFitFile] Error : ' + e.type)
        window.alert('Error getting file: ' + e.message)
      }
      reader.readAsDataURL(file)
    }
  }

  handleDownload = (event) => {
    event.preventDefault()
    this.downloadObjectAsJson(this.state.output, this.state.fileName)
  }

  downloadObjectAsJson = (exportObj, exportName) => {
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', exportName + '.json')
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  render = () => {
    return (
        <div className="container">
            <br/>
            <p>{this.state.isLoggingFitFile ? 'Loading...' : 'Upload fit file:'}</p>
            <input disabled={this.state.isLoggingFitFile} type="file" onChange={(event) => { this.readFitFile(event) }} id="file_upload"/>
            <br/><br/>
            <a hidden={!this.state.output} href="#" id="download" onClick={this.handleDownload}>Download</a>
            <br/>
            <div className="bg-light">
                <pre><code>{JSON.stringify(this.state.output, null, 2)}</code></pre>
            </div>
        </div>
    )
  }
}
