import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'

export default class TipOfTheDayComponent extends React.Component {
  constructor (props) {
    super(props)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.state = {
        isLoading: true,
        id: this.props.match.params.id,
        editing: false,
        saving: false,
        existingTags: [],
        existingPlatformFilters: []
      }
      this.loadVideo()
    } else {
      // When `id` is "new", we are intending to make a new video object.
      // This makes a new one and sets the mode to editing right away
      const video = new Parse.Object('TipOfTheDay')
      this.state = {
        isLoading: false,
        video: video,
        editing: true,
        saving: false,
        existingTags: [],
        existingPlatformFilters: []
      }
    }

    this.loadVideo = this.loadVideo.bind(this)
    this.fileInput = React.createRef()
    this.videoInput = React.createRef()
    this.saveImageIfNecessary = this.saveImageIfNecessary.bind(this)
    this.saveVideo = this.saveVideo.bind(this)
    this.loadAllVideos()
  }

  loadVideo () {
    const videoType = new Parse.Object('TipOfTheDay')
    const query = new Parse.Query(videoType)
    query.equalTo('objectId', this.state.id)
    console.log('load video with id: ' + this.state.id)
    query.first().then((result) => {
      this.setState({
        video: result,
        isLoading: false
      })
    }, (error) => {
      console.log(error)
      window.alert('Error fetching video')
    })
  }

  loadAllVideos = () => {
    const videoType = new Parse.Object('TipOfTheDay')
    const query = new Parse.Query(videoType)
    query.limit(1000)
    query.find().then((results) => {
      console.log(results.length + ' existing videos.')
      const existingTags = results.map(x => x.get('tags')).flat()
      this.setState({
        existingTags: Array.from(new Set(existingTags))
      })
    }, (error) => {
      alert(error)
    })
  }

  handleEdit = () => {
    const newEditing = !this.state.editing
    this.setState({
      editing: newEditing
    })
  }

  handleSave = (event) => {
    event.preventDefault()
    this.setState({
      saving: true
    })
    let imageFile
    this.saveImageIfNecessary().then(async (image, error) => {
      imageFile = image
      return await this.saveVideoIfNecessary()
    }).then(videoUrl => {
      return this.saveVideo(imageFile, null, videoUrl)
    }).then((success, error) => {
      if (error) {
        alert(error)
      } else if (this.state.id) {
        window.location.reload()
      } else {
        window.location.href = '/#/tipofthedays/' + this.state.video.id
        window.location.reload()
      }
    })
  }

  saveVideoIfNecessary = async () => {
    if (!this.videoInput.current.files[0]) {
      return Promise.resolve()
    }

    const data = await this.uploadFileToS3(this.videoInput.current.files[0])
    if (data) {
      console.log('s3 upload ok:')
      console.log(data)
      return data.url.split('?')[0]
    }
  }

  uploadFileToS3 = async (file) => {
    const uploadUrl = await Parse.Cloud.run('getS3VideoUploadLink', {
      fileName: file.name,
      fileType: file.type
    })
    console.log('uploading file...')
    try {
      return fetch(uploadUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file
      }).then((res) => {
        console.log(res)
        return res
      }, (err) => {
        console.error(err)
        window.alert(err.message)
      })
    } catch (err) {
      console.error(err)
      window.alert(err.message)
    }
  }

  saveImageIfNecessary = () => {
    if (!this.fileInput.current.files[0]) {
      return Promise.resolve()
    }

    const file = this.fileInput.current.files[0]
    console.log('file is: ' + file.name)
    const img = new Image()
    img.src = window.URL.createObjectURL(file)
    return new Promise((resolve, reject) => {
      img.onload = () => {
        // alert when aspect ratio is height < width
        if (img.height < img.width) {
          alert('Image height should be greater than image width')
          return resolve()
        }
        // checks passed, upload image
        const parseImage = new Parse.File(file.name, file)
        console.log('Saving image...')
        parseImage.save().then((fileSuccess, fileError) => {
          if (!fileSuccess) {
            console.log('Error saving image file.')
            resolve()
          }
          console.log('Saved image file.')
          resolve(parseImage)
        })
      }
    })
  }

  saveVideo = (parseImage, videoFile, videoUrl) => {
    if (parseImage) {
      this.state.video.set('image', parseImage)
    }
    if (videoFile) {
      this.state.video.set('videoFile', videoFile)
    }
    if (videoUrl) {
      this.state.video.set('videoUrl', videoUrl)
    }
    return this.state.video.save()
  }

  handleCancel = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  handleTitleChange = (event) => {
    this.state.video.set('title', event.target.value)
  }

  handleDurationChange = (event) => {
    this.state.video.set('durationSeconds', parseInt(event.target.value))
  }

  handleDateChange = (event) => {
    this.state.video.set('date', event.target.value)
    this.setState({
      video: this.state.video
    })
  }

  handleDelete = async (event) => {
    event.preventDefault()
    const ok = confirm('Are you sure you want to delete this video?')
    if (!ok) return
    await this.state.video.destroy()
    window.location.href = '/#/tipofthedays'
    window.location.reload()
  }

  handleFocusChange = (event) => {
    this.state.video.set('activityFocus', event.target.value)
    this.setState({
      video: this.state.video
    })
  }

  tagsDescription = () => {
    if (this.state.video.get('tags')) {
      return this.state.video.get('tags').join(', ')
    }
    return 'n/a'
  }

  handleTagsChange = (event) => {
    const newTags = event.target.value.split(',').map(value => value.trim())
    this.state.video.set('tags', newTags)
  }

  renderNotEditing = (video) => {
    return (
      <div className="container">
          <br/>
          <div className="row justify-content-between">
              <div className="col-sm-8">
                  <h1>{video.get('title')} <h2 className="text-muted">{video.get('date')}</h2></h1>
              </div>
              <div className="col-sm-4 text-right">
                { this.state.editing ? <button className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving}>Save</button> : <button className="btn btn-secondary" onClick={this.handleEdit}>Edit</button>}
              </div>
          </div>
          <div className="row">
            <div className="col">
              <b>Cover Image:</b><br/>
              <p hidden={video.get('image')}>⚠️ No cover image</p>
              <img className="img-fluid" src={video.get('image') ? video.get('image').url() : ''}/>
            </div>
            <div className="col">
              <b>Video:</b><br/>
              { video.get('videoUrl') ? (<video src={video.get('videoUrl') } controls className="embed-responsive"></video>) : (<span className="text-muted">No video</span>) }
            </div>
          </div>
          <div className="jumbotron">
              <div className="row">
                <div className="col-sm-3">
                    <p><b>Duration:</b><br/>
                    <span className="text-muted">{video.get('durationSeconds')} seconds</span></p>
                </div>
                <div className="col-sm-3">
                    <p><b>Tags:</b><br/>
                    <span className="text-muted">{this.tagsDescription()}</span></p>
                </div>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <button className="btn btn-danger" onClick={this.handleDelete}>Delete</button>
          <br/>
          <br/>
          <br/>
      </div>
    )
  }

  renderEditing = (video) => {
    return (
      <div className="container">
          <br/>
          <form>
              <div className="form-group">
                  <label><b>Title</b></label>
                  <input type="text" className="form-control" placeholder={video.get('title')} defaultValue={video.get('title')} onChange={this.handleTitleChange}/>
              </div>
              <div className="form-group">
                  <label><b>Date</b></label>
                  <input type="text" className="form-control" placeholder={video.get('date') || 'YYYY-MM-DD'} defaultValue={video.get('date')} onChange={this.handleDateChange}/>
              </div>
              <div className="form-group">
                  <label><b>Video length (seconds)</b></label>
                  <input type="number" className="form-control" placeholder={video.get('durationSeconds')} defaultValue={video.get('durationSeconds')} onChange={this.handleDurationChange}/>
              </div>
              <div className="form-group">
                  <label><b>Tags</b></label>
                  <input type="text" className="form-control" placeholder={this.tagsDescription()} defaultValue={this.tagsDescription()} onChange={this.handleTagsChange}/>
                  <small className="form-text text-muted">Comma-separated. Existing tags: <b>{this.state.existingTags.join(', ')}</b></small>
              </div>
              <br/>
              <div className="form-group">
                  <label><b>Cover image</b></label>
                  <br/>
                  <input type="file" ref={this.fileInput} />
                  <small className="form-text text-muted">Only if you want to change it.</small>
              </div>
              <div className="form-group">
                  <label><b>Video</b></label>
                  <br/>
                  <input type="file" ref={this.videoInput} />
                  <small className="form-text text-muted">Only if you want to change it.</small>
              </div>
              <br/>
              <button type="submit" className="btn btn-success" onClick={this.handleSave} disabled={this.state.saving} >Save</button>
              <br/><br/>
              <button type="submit" className="btn btn-secondary" onClick={this.handleCancel} disabled={this.state.saving} >Cancel</button>
          </form>
      </div>
    )
  }

  render = () => {
    if (this.state.video === undefined) {
      return (<div>Loading...</div>)
    }
    const video = this.state.video

    return this.state.editing ? this.renderEditing(video) : this.renderNotEditing(video)
  }
}
